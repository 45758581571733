<template>
  <section >
    <div class="search">
      <div class="inputs">
          <p class="each">
              <span>场地名称：</span>
              <el-input class="wxName" v-model="searchInfo.data.SiteName" placeholder="请输入场地名称"></el-input>
          </p>
          <p class="each">
              <span>所属代理：</span>
              <el-input class="wxName" v-model="searchInfo.data.AgentName" placeholder="请输入所属代理"></el-input>
          </p>
          <p class="each">
              <span>所属商户：</span>
              <el-input class="wxName" v-model="searchInfo.data.ManagerName" placeholder="请输入所属商户"></el-input>
          </p>
          <p class="each">
              <span>设备投资商：</span>
              <el-input class="wxName" v-model="searchInfo.data.deviceSupplierName" placeholder="请输入设备投资商"></el-input>
          </p>
         <el-button type="primary" @click="toSearch">搜索</el-button>
         <el-button type="primary" @click="backAll">清空搜索条件</el-button>
         <el-button type="primary" @click="handleAdd" v-if="userInfo.roleId == 1 || userInfo.roleId == 2">新增</el-button>
      </div>
    </div>
    <div class="container">
      <el-table
        :data="placeList"
        style="width: 100%"
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="场地名称"
          prop="siteName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="场地地址"
          prop="address"
          align='center'>
          <template slot-scope="scope">
            <p>{{ scope.row.address }}</p>
            <el-button size="mini" @click="checkGps(scope.row)">查看定位</el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="设备投资商"
          prop="deviceSupplierName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="所属行业"
          prop="industry"
          align='center'>
        </el-table-column>
        <el-table-column
          label="品牌"
          prop="brand"
          align='center'>
        </el-table-column>
        <el-table-column
          label="预估日客流"
          prop="passengerFlow"
          align='center'>
        </el-table-column>
        <el-table-column
          label="门头照"
          prop="doorHeaderImg"
          width="150"
          align='center'>
          <template slot-scope="scope">
            <img style="width: 100px;height: 100px;" :src="scope.row.doorHeaderImg" alt="暂无图片">
          </template>
        </el-table-column>
        <el-table-column
          label="店内照片"
          prop="siteImg"
          width="150"
          align='center'>
          <template slot-scope="scope">
            <img style="width: 100px;height: 100px;" :src="scope.row.siteImg" alt="暂无图片">
          </template>
        </el-table-column>
        <el-table-column
          label="绑定模板"
          prop="templateName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="所属代理"
          prop="agentName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="所属商户"
          prop="managerName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="分润（%）"
          prop=""
          align='center'>
          <template slot-scope="scope">
            <p>系统：{{scope.row.headquarters}}</p>
            <p v-if="scope.row.agentName">{{scope.row.agentName}}：{{scope.row.agentProportion}}</p>
            <p v-if="scope.row.managerName">{{scope.row.managerName}}：{{scope.row.manageProportion}}</p>
            <p v-if="scope.row.deviceSupplierName">{{scope.row.deviceSupplierName}}：{{scope.row.supplierProportion}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="createTime"
          align='center'>
        </el-table-column>
        <el-table-column
         label="操作"
         fixed="right"
         width="200"
         align='center'>
          <template slot-scope="scope">
              <p style="margin-bottom:5px">
                <el-button size="mini" @click="handleDel(scope.$index, scope.row)" v-if="userInfo.roleId == 1">删除</el-button>
                <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"  v-if="userInfo.roleId == 1 || userInfo.roleId == 2">修改</el-button>
                <el-button size="mini" @click="checkOrder(scope.row)">查看订单</el-button>
              </p>
              <p>
                <el-button size="mini" @click="handleBenefit(scope.row)">分润</el-button>
                <el-button size="mini" @click="handleRule(scope.$index, scope.row)">计费模板</el-button>
                <el-button size="mini" @click="handleBindSupplier(scope.row)"  v-if="userInfo.roleId == 1">绑定设备供应商</el-button>
              </p>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page="searchInfo.pageIndex"
          :page-size="10"
          :total="total"
          background
          style="float:right;"
        ></el-pagination>
      </el-col>
      <!-- 新增 -->
      <el-drawer
       :title="row ? '编辑' : '新增'"
       :visible.sync="addDrawer"
       :direction="direction"
       size="50%"
       :before-close="handleClose">
        <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :row="row"></add>
      </el-drawer>
      <!-- 修改菜单 -->
      <el-drawer
       title="修改"
       :visible.sync="editDrawer"
       :direction="direction"
       :before-close="handleClose">
        <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
      </el-drawer>
      <!-- 设置分润 -->
      <el-drawer
       title="分润设置"
       size="50%"
       :visible.sync="benefitDrawer"
       :direction="direction"
       :before-close="handleClose">
        <benefit v-if="benefitDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :row="row"></benefit>
      </el-drawer>
      <!-- 绑定计费模板 -->
      <el-drawer
       title="绑定计费模板"
       :visible.sync="bindDrawer"
       :direction="direction"
       :before-close="handleClose">
        <bind v-if="bindDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :row="row"></bind>
      </el-drawer>
      <!-- 绑定设备供应商 -->
      <el-drawer
       title="绑定设备供应商"
       :visible.sync="bindSupplierDrawer"
       :direction="direction"
       :before-close="handleClose">
        <bindSupplier v-if="bindSupplierDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :row="row"></bindSupplier>
      </el-drawer>
    </div>
    <!-- 地图弹框 -->
    <el-dialog title="门店位置" :visible.sync="open" width="900px" append-to-body>
        <!-- 百度地图 -->
        <div id="Map-Container" style="width: 100%; height: 400px;"></div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="open = false">确定</el-button>
        </div>
    </el-dialog>
  </section>
</template>

<script>
import {getSiteInfo,delSiteInfo} from '@/api/operateCenter.js';
import add from './add.vue';
import edit from './edit.vue';
import benefit from './benefit.vue';
import bind from './bindTemplate.vue';
import bindSupplier from './bindSupplier.vue'
export default {
  data() {
    return {
      userInfo:JSON.parse(sessionStorage.getItem('userInfo')) || undefined, // 登录账号信息
      // 订单列表信息
      placeList:[],
      // 分页样式
      total:0,
      searchInfo:{ // 查询条件
        pageIndex:1,
        pageSize:10,
        data:{},
      },
      addDrawer:false,
      editDrawer:false,
      benefitDrawer:false,
      bindDrawer:false,
      bindSupplierDrawer:false,
      id:'',
      row:null,
      direction:'rtl',
      open:false,
      map:null,
    }
  },

  components: {add,edit,benefit,bind,bindSupplier},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    selectStatus(e){},
    // 初始化
    async init(){
      await getSiteInfo(this.searchInfo).then(res=>{
        if(res.success){
          this.placeList = res.data.data;
          this.placeList.forEach(element => {
            if(element.ledgerRatio){
              element.ledgerRatio = JSON.parse(element.ledgerRatio);
              element.ledgerRatio.forEach(ele => {
                if(ele.Profitid == 0){
                  element.headquarters = ele.ProfitRatio;
                }else if( element.agentId && ele.Profitid == element.agentId){
                  element.agentProportion = ele.ProfitRatio;
                }else if( element.managerId && ele.Profitid == element.managerId){
                  element.manageProportion = ele.ProfitRatio;
                }else if(element.deviceSupplierId && ele.Profitid == element.deviceSupplierId){
                  element.supplierProportion = ele.ProfitRatio;
                }
              })
            }
            for(let key in element){
              element[key] = element[key] || element[key] == 0 ? element[key] : '--';
            }
          });
          this.total = res.data.totalCount;
        }
      })
    },
    checkGps(row){
      if(row.gps && row.gps != '--'){
        this.open = true;
        this.initBaiduMap(row.gps)
      }else{
        this.$message({
          message:'暂无定位',
          type:'fail'
        })
      }
    },
    // 初始化百度地图
    initBaiduMap(gps) {
      let Gps = gps.split(',');
      this.$nextTick(() => {
        /* 初始化地图 start */
        this.map = new BMapGL.Map("Map-Container");// 创建地图实例
        let point = new BMapGL.Point(Gps[0], Gps[1]); // 设置中心点坐标
        this.map.centerAndZoom(point, 18); // 地图初始化，同时设置地图展示级别
        this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
        this.map.clearOverlays();
        let Marker = new BMapGL.Marker(point); // 创建标注
        this.map.addOverlay(Marker); // 将标注提交到地图中
      })
    },
     // 删除用户
    handleDel(index,row){
      this.$confirm('确认删除该场地吗')
       .then(async ()=>{
          await delSiteInfo({Id:row.id}).then(res=>{
            if(res.success){
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              });
              this.init();
            }
          })
       })
       .catch(()=>{})
    },
     // 打开新增
    handleAdd(index,row){
      this.addDrawer = true;
      this.row = null;
    },
    // 打开修改
    handleEdit(index,row){
      this.addDrawer = true;
      this.id = index;
      this.row = row;
    },
    // 打开设置分润
    handleBenefit(row){
      this.benefitDrawer = true;
      this.row = row;
    },
    // 打开绑定供应商弹框
    handleBindSupplier(row){
      this.row = row;
      this.bindSupplierDrawer = true;
    },
    // 关闭
    handleClose(){
      this.addDrawer = false;
      this.editDrawer = false;
      this.benefitDrawer = false;
      this.bindDrawer = false;
      this.bindSupplierDrawer = false;
      this.init();
    },
    // 新增、绑定、编辑成功
    handleDrawerSuccess(){
      this.addDrawer = false;
      this.editDrawer = false;
      this.benefitDrawer = false;
      this.bindDrawer = false;
      this.bindSupplierDrawer = false;
    },
    // 打开设置商品售卖规则
    handleRule(index,row){
      this.bindDrawer = true;
      this.row = row;
    },
    // 前往查看订单
    checkOrder(row){
      this.$router.push({
        path:'/trading/orderList',
        query:{siteId:row.id,siteName:row.siteName,type:'site'}
      })
    },
    // 条件查询
    toSearch(){
      this.searchInfo.pageIndex = 1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex = 1;
      this.searchInfo.data = {},
      this.RefundState = '';
      this.OrderState = '';
      this.init();
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex = val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>

 .search{
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  background: #fff;
  padding: 20px;
    .inputs{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .each{
          width:300px;
          height: 50px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          .el-select{
           width: 100% !important;
          }
          span{
              width: 120px;
          }
      }
      .el-button{
          margin-left: 15px;
      }
    }
  }

  .container{
      padding: 20px;
  }
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }

.el-table >>> .cell {
  white-space: pre-line;
}
 // 弹框
  /deep/ .el-drawer__body{
    overflow: auto !important;
  }
.el-col-24{
    height: 52px;
   margin-top: 20px;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
