<template>
  <section>
    <div class="equipInfo">
        <i class="el-icon-back" @click="back"></i>
        <p>设备编号：{{equipInfo.equipmentNum}}</p>
        <!-- <button @click="handleAdd" v-if="equipInfo.equipmentType===2 || goodsList.length==0">新增</button> -->
    </div>
    <div class="container">
      <el-table
        :data="goodsList"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="商品图片"
          prop="goodsImg"
          align='center'>
          <template slot-scope="scope">
            <img :src="scope.row.goodsImg" alt="">
          </template>
        </el-table-column>
        <el-table-column
          label="商品名称"
          prop="goodsName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="商品价格（元）"
          prop="amount"
          align='center'>
        </el-table-column>
        <el-table-column
          label="库存数量"
          prop="goodsNum"
          align='center'>
        </el-table-column>
        <!-- <el-table-column
         label="操作"
         fixed="right"
         align='center'>
          <template slot-scope="scope">
              <el-button size="mini" @click="handleDel(scope.$index, scope.row)" v-if="equipInfo.equipmentType===2">删除</el-button>
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- <div class="sub">
        <button @click="submit" type="default">
            提交
        </button>
      </div> -->
    </div>
    <!-- 编辑弹框 -->
    <div class="editCover" v-if="showEdit">
        <div class="inside">
            <el-form>
                <el-select v-model="eachGoodsInfo.goodsId" filterable clearable placeholder="请选择" @input="searchGoods" v-if="equipInfo.equipmentType===1">
                  <el-option
                    v-for="item in goodsOptions"
                    :key="item.id"
                    :label="item.goodsName"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-form-item label="商品名称" v-else>
                  <el-input v-model="eachGoodsInfo.goodsName" disabled></el-input>
                </el-form-item>
                <el-form-item label="库存数量">
                  <el-input v-model="eachGoodsInfo.goodsNum"></el-input>
                </el-form-item>
                <el-form-item label="商品价格">
                  <el-input v-model="eachGoodsInfo.amount"></el-input>
                </el-form-item>
            </el-form>
            <div class="btns">
                <button @click="cancel">取消</button>
                <button @click="editConfirm">确认</button>
            </div>
        </div>
    </div>
    <!-- 新增弹框 -->
    <div class="addCover" v-if="showAdd">
        <div class="inside">
            <el-form>
                <el-form-item label="商品名称">
                  <!-- <el-select
                    v-model="searchInfo.data.goodsName"
                    multiple
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入商品名称搜索"
                    :remote-method="getGoods"
                    :loading="loading">
                    <el-option
                      v-for="item in goodsOptions"
                      :key="item.id"
                      :label="item.goodsName"
                      :value="item.id">
                    </el-option>
                  </el-select> -->
                    <el-select v-model="eachGoodsInfo.goodsId" filterable clearable placeholder="请选择" @input="searchGoods">
                      <el-option
                        v-for="item in goodsOptions"
                        :key="item.id"
                        :label="item.goodsName"
                        :value="item.id">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="库存数量">
                  <el-input v-model="eachGoodsInfo.goodsNum"></el-input>
                </el-form-item>
                <el-form-item label="商品价格">
                  <el-input v-model="eachGoodsInfo.amount"></el-input>
                </el-form-item>
            </el-form>
            <div class="btns">
                <button @click="cancel">取消</button>
                <button @click="addConfirm">确认</button>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import {getEquipmentPayGoodsInfo,addPayGoods,updatePayGoods,getGoodsInfo} from '@/api/operateCenter.js';
export default {
  data() {
    return {
        goodsList:[], // 售卖商品列表
        equipInfo:{}, // 该设备信息
        type:'', // 设备类型
        eachGoodsInfo:{ // 当前操作的商品信息
            goodsName:'',
            goodsNum:'',
            amount:'',
            goodsId:'',
            id:0,
        }, 
        showEdit:false, // 是否显示编辑弹窗
        showAdd:false,// 是否显示新增弹窗
        goodsOptions:[], // 商品选项
        searchInfo:{ // 查询条件
          pageIndex:1,
          pageSize:100000,
          data:{
            GoodsName:'',
          }
        },
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.equipInfo=this.$route.query.equipInfo;
    this.type=this.$route.query.type;
    this.init();
    this.getGoods();
  },

  methods: {
    async getGoods(){
        getGoodsInfo(this.searchInfo).then(res=>{
            this.goodsOptions=res.data.data;
        })
    },
    // 获取商品列表数据
    async init(){
        await getEquipmentPayGoodsInfo({EquipmentNum:this.equipInfo.equipmentNum}).then(res=>{
            if(res.success){
                this.goodsList=res.data;
                // if(this.goodsList.goodsImg.indexOf('http') == -1){
                //   this.goodsList.goodsImg='https://www.quantoukeji.com/'+this.goodsList.goodsImg;
                // }
            }
        })
    },
    // 返回设备列表
    back(){
      if(this.type == 'abnormal'){
        this.$router.push('/equipManage/abnormalEquipList');
      }else{
        this.$router.push('/equipManage/equipList');
      }
    },
    // 删除
    handleDel(index,row){
        this.goodsList=this.goodsList.filter(item=>{
            return item.goodsId !== row.goodsId;
        })
    },
    // 打开编辑
    handleEdit(index,row){
        this.showEdit=true;
        let arr=this.goodsList.filter(item=>{
            return item.goodsId==row.goodsId;
        })
        this.eachGoodsInfo=arr[0];
        console.log(this.eachGoodsInfo,98)
    },
    // 取消编辑新增
    cancel(){
        this.showEdit=false;
        this.showAdd=false;
    },
    // 确认编辑
    editConfirm(){
        if(this.equipInfo.equipType===2){ // 视觉售卖机
          for(let i=0;i<this.goodsList,length;i++){
              if(this.goodsList[i].goodsId==this.eachGoodsInfo.goodsId){
                  this.goodsList[i]=this.eachGoodsInfo;
              }
          }
        }else{ // 称重售卖机
          let arr=this.goodsOptions.filter(item=>{
            return this.eachGoodsInfo.goodsId==item.id;
          })
          this.eachGoodsInfo.goodsImg=arr[0].goodsImg;
          this.eachGoodsInfo.goodsName=arr[0].goodsName;
          console.log(this.eachGoodsInfo,99)
        }
        this.showEdit=false;
    },
    // 打开新增
    handleAdd(){
        this.showAdd=true;
    },
    // 新增确认
    addConfirm(){
        let arr=this.goodsOptions.filter(item=>{
          return this.eachGoodsInfo.goodsId==item.id;
        })
        console.log(this.eachGoodsInfo.goodsId,arr)
        this.eachGoodsInfo.goodsName=arr[0].goodsName;
        this.eachGoodsInfo.goodsImg=arr[0].goodsImg;
        this.goodsList.push(this.eachGoodsInfo);
        this.eachGoodsInfo={
          goodsName:'',
          goodsId:'',
          goodsNum:'',
          amount:'',
          id:0,
        }
        this.showAdd=false;
        console.log(this.eachGoodsInfo,this.goodsOptions,998);
    },
    // 提交
    submit(){
        this.$confirm('确认提交吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async ()=>{
            if(this.equipInfo.equipmentType===1){ // 称重售卖机
               console.log()
               let data={
                Id:this.goodsList[0].id,
                EquipmentNum:this.equipInfo.equipmentNum,
                GoodsId:this.goodsList[0].goodsId,
                GoodsNum:this.goodsList[0].goodsNum,
                Amount:this.goodsList[0].amount,
               }
                await addPayGoods(data).then(res=>{
                    if(res.success){
                        this.$message({
                            message:'提交成功',
                            type:'success'
                        })
                        this.init();
                    }else{
                        this.$message({
                            message:'提交失败，请重试',
                            type:'fail'
                        })
                    }
                })
            }else{ // 视觉售卖机
                let arr=[];
                this.goodsList.forEach(element=>{
                    arr.push({
                        EquipmentNum:this.equipInfo.equipmentNum,
                        GoodsId:element.goodsId,
                        GoodsNum:element.goodsNum,
                        Amount:element.amount,
                        IdentificationId:element.identificationId
                    })
                })
                await updatePayGoods(arr).then(res=>{
                    if(res.success){
                        this.$message({
                            message:'提交成功',
                            type:'success'
                        })
                        this.init();
                    }
                })
            }
        })
    },
  },
};
</script>
<style scoped lang="scss">
  .equipInfo{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 2px solid rgba(240, 242, 245, 1);
      .el-icon-back{
        font-size: 25px;
        cursor: pointer;
      }
  }
  .container{
      padding: 20px;
  }
  img{
    height: 100px;
    width: 120px;
  }
  .sub{
    height: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    button{
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
    }
  }
  .editCover,.addCover{
    height: 100%;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top:0;
    left: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
    .inside{
        padding: 20px;
        border-radius: 5px;
        background: #fff;
        width: 50%;
        .btns{
            display: flex;
            justify-content: flex-end;
            button{
                margin-left: 20px;
                padding: 5px 10px;
            }
        }
    }
  }
</style>
