<template>
  <section class="home">
    <div class="fresh">
      <p>所有数据每1小时更新一次，请点击刷新按钮进行更新</p>
      <el-button size="small" @click="fresh">刷新</el-button>
    </div>
    <!-- 概况统计 -->
    <div class="countData">
      <!-- 设备状况 -->
      <div class="equip">
        <div class="top">
          <p><b class="leftBar"></b><em>设备情况</em></p>
          <span>已注册设备</span>
        </div>
        <div class="mid">
          <b>{{equipInfo.registeredCount}}</b><span>台</span>
        </div>
        <div class="bot">
          <p>
            <span style="color:#2ebb00;"><b>{{equipInfo.onlineCount}}</b>台</span>
            <span>在线设备</span>
          </p>
          <em></em>
          <p>
            <span style="color:#f64029;"><b >{{equipInfo.notOnlineCount}}</b>台</span>
            <span>异常设备</span>
          </p>
        </div>
      </div>
      <!-- 经营收入 -->
      <div class="income">
        <div class="top">
          <p><b class="leftBar"></b><em>经营概况</em></p>
          <span>今日总收入</span>
        </div>
        <div class="mid">
          <b>{{incomeInfo.todayOrderAmount}}</b><span>元</span>
        </div>
        <div class="bot">
          <p>
            <span style="color:#000;"><b>{{incomeInfo.yesterdayOrderAmount}}</b>元</span>
            <span>昨日总收入</span>
          </p>
          <em></em>
          <p>
            <span style="color:#000;"><b>{{incomeInfo.weekOrderAmount}}</b>元</span>
            <span>本周总收入</span>
          </p>
          <em></em>
          <p>
            <span style="color:#000;"><b>{{incomeInfo.monthOrderAmount}}</b>元</span>
            <span>本月总收入</span>
          </p>
        </div>
      </div>
      <!-- 订单 -->
      <div class="order">
        <div class="top">
          <p><b class="leftBar"></b><em>订单概况</em></p>
          <span>今日总订单</span>
        </div>
        <div class="mid">
          <b>{{orderInfo.countOrderNum}}</b><span>单</span>
        </div>
        <div class="bot">
          <p>
            <span style="color:#000;"><b>{{orderInfo.countTrueOrderNum}}</b>单/<b>{{orderInfo.countTrueOrderAmount}}</b>元</span>
            <span>成功订单/交易金额</span>
          </p>
          <em></em>
          <p>
            <span style="color:#000;"><b>{{orderInfo.countRefundOrderNum}}</b>单/<b>{{orderInfo.countRefundOrderAmount}}</b>元</span>
            <span>退款订单/退款金额</span>
          </p>
        </div>
      </div>
    </div>
    <!-- 销售统计 -->
    <div class="salesStatistics">
      <div class="title">
        <p><b class="leftBar"></b><em>销售统计</em></p>
        <el-radio-group v-model="time" @change="changeTime" size="small">
          <el-radio-button label="1">今日</el-radio-button>
          <el-radio-button label="2">昨日</el-radio-button>
          <el-radio-button label="3">本月</el-radio-button>
        </el-radio-group>
      </div>
      <div id="sales" style="width:100%;height:300px"></div>
    </div>
    <!-- 销售排行榜 -->
    <!-- <div class="topSales">
      <div class="title">
        <p><b class="leftBar"></b><em>销售排行榜</em></p>
        <el-radio-group v-model="type" @change="changeType" size="small">
          <el-radio-button label="1">按出货数</el-radio-button>
          <el-radio-button label="2">按成交笔数</el-radio-button>
          <el-radio-button label="3">按成交金额</el-radio-button>
        </el-radio-group>
      </div>
      <div id="salesTop" style="width:100%;height:300px"></div>
    </div> -->
  </section>
</template>

<script>
import * as echarts from 'echarts';
var salesChart,topSalesChart;
import {getEquipmentCount,getBusinessCount,getOrderCount,getSalesChart,getGoodsSalesChart} from '@/api/operateCenter.js';
export default {
  data() {
    return {
      equipInfo:{}, // 设备概况
      incomeInfo:{}, // 经营收入概况
      orderInfo:{}, // 订单概况
      time:'1',
      type:'1', 
      salesChartInfo:[], // 销售统计图表信息
      topSalesChartInfo:[], // 销售排行榜图表信息
      isUpdate:0,
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
    this.getsalesChartInfo();
    this.getTopSalesChartInfo();
    this.sales();
    this.topSales();
  },

  methods: {
    async init(){
        await getEquipmentCount({data:this.isUpdate}).then(res=>{
          if(res.success){
            this.equipInfo=res.data;
            console.log(this.equipInfo,887)
          }
        })
        await getBusinessCount({data:this.isUpdate}).then(res=>{
          if(res.success){
            this.incomeInfo=res.data;
          }
        })
        await getOrderCount({data:this.isUpdate}).then(res=>{
          if(res.success){
            this.orderInfo=res.data;
          }
        })
    },
    async getsalesChartInfo(){
      getSalesChart({Data1:this.time,Data2:this.isUpdate}).then(res=>{
        if(res.success){
          this.salesChartInfo=res.data;
        }
      })
    },
    async getTopSalesChartInfo(){
      await getGoodsSalesChart({Data1:this.type,Data2:this.isUpdate}).then(res=>{
        if(res.success){
          this.topSalesChartInfo=res.data;
        }
      })
    },
    changeTime(e){
      this.getsalesChartInfo();
      this.sales();
    },
    changeType(e){
      this.getTopSalesChartInfo();
      this.topSales();
    },
    sales(){
      var salesChartDom = document.getElementById('sales');
      salesChart = echarts.init(salesChartDom);
      setTimeout(()=>{
        var salesOption = {
          title: {
            text: '',
          },
          tooltip: { // 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          legend: {
            data: ['销售金额（元）','销售单量（单）']
          },
          xAxis: {
            type: 'category',
            name:this.time==3?'（日）':'（时）',
            data: this.salesChartInfo.CreateTime,
          },
          yAxis: [
            {
              name: '销售金额（元）',
              type: 'value',
            },
            {
              name: '销售单量',
              type: 'value',
              minInterval : 1, // 最小的数值为1，就不会在数据都为零时y轴存在小数
            },
          ],
          series: [
            {
              name:'销售金额（元）',
              data: this.salesChartInfo.sumOrderAmount,
              type: 'line',
              yAxisIndex: 0,
              smooth: true,
            },
            {
              name:'销售单量（单）',
              data: this.salesChartInfo.countOrderNum,
              type: 'line',
              yAxisIndex: 1,
              smooth: true,
            }
          ]
        };
        salesOption && salesChart.setOption(salesOption);
      },500)
    },
    topSales(){
      var topSaleschartDom = document.getElementById('salesTop');
      topSalesChart = echarts.init(topSaleschartDom);
      
      setTimeout(()=>{
        var topSalesOption = {
          xAxis: {
            type: 'category',
            data: this.topSalesChartInfo.goodsName,
            name:'产品名称'
          },
          yAxis: {
            type: 'value',
            name:this.type==1?'出货数（件）':(this.type==2?'成交数（笔）':'成交金额（元）')
          },
          series: [
            {
              data: this.topSalesChartInfo.sumData,
              type: 'bar',
              barMaxWidth : 50, //柱图宽度
            }
          ]
        };
        topSalesOption && topSalesChart.setOption(topSalesOption);
        window.onresize = function() { // 一个页面只能有一个监听页面的 window.onresize 事件获取浏览器大小改变的事件，因此将三个图表放在一个事件内监听
          salesChart.resize();
          topSalesChart.resize();
        };
      },500)
    },
    // 刷新
    fresh(){
      this.isUpdate=1;
      this.init();
      this.getsalesChartInfo();
      this.getTopSalesChartInfo();
      this.sales();
      this.topSales();
      this.$message({
        message: '刷新成功',
        type: 'success'
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.home{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #f0f2f5;
  
}
.fresh{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  .el-button{
    width: 70px;
    margin-left: 20px;
  }
}
.countData{
  display: flex;
  justify-content: space-between;
  // background: #f0f2f5;
  margin-bottom: 20px;
  // box-sizing: border-box;
  .equip,.income,.order{
    width: 32%;
    padding: 10px;
    // box-sizing: border-box;
    background: #fff;
    border-radius: 5px;
    span{
          color: #ababab;
    }
    em{
      font-style: normal;
    }
    .top{
      display: flex;
      justify-content: space-between;
      .leftBar{
        border-left: solid #2d71ec 3px;
        height: 80%;
        margin-right: 10px;
        display: inline;
      }
      span{
        font-size: 14px;
      }
      em{
        font-size: 16px;
      }
    }
    .mid{
      text-align: center;
      b{
        font-size: 20px;
        font-weight: 400;
      }
      span{
        color: #000;
      }
    }
    .bot{
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 12px;
      em{
        width: 1px;
        background: #c0c0c0;
        height: 20px;
      }
      b{
        font-size: 16px;
      }
      p{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
.salesStatistics{
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  background: #fff;
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    .leftBar{
      border-left: solid #2d71ec 3px;
      height: 80%;
      margin-right: 10px;
      display: inline;
    }
    em{
        font-style: normal;
        font-size: 16px;
      }
  }
}
.topSales{
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  background: #fff;
  margin-top: 20px;
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    .leftBar{
      border-left: solid #2d71ec 3px;
      height: 80%;
      margin-right: 10px;
      display: inline;
    }
    em{
        font-style: normal;
        font-size: 16px;
      }
  }
}
</style>

