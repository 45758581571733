<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="设备编号" prop="equipmentNum">
          <el-input placeholder="请输入设备编号" v-model="form.equipmentNum" :disabled="row"></el-input>
        </el-form-item>
        <el-form-item label="安装场地" prop="siteId">
          <el-select class='select' v-model="form.siteId" filterable placeholder="请选择">
            <el-option
              v-for="item in siteOptions "
              :key="item.id"
              :label="item.siteName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安装地址" prop="address">
          <el-input placeholder="请输入安装地址" v-model="form.address"></el-input>
          <button class="map" type="button" @click="chooseAddress">地图选址</button>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
      <!-- 地图弹框 -->
      <el-dialog title="地址选择" :visible.sync="open" width="900px" append-to-body>
        <el-form label-width="80px">
          <el-row>
            <el-col :span="10">
              <el-form-item label="搜索地址">
                <el-input type="text" id="searchAddressInput" v-model="searchAddress" placeholder="请输入地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="当前地址">
                <el-input v-model="addressInfo.address" placeholder="请输入内容">
                  <template slot="prepend">
                    {{addressInfo.province}}{{addressInfo.city}}{{addressInfo.district}}
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 百度地图 -->
        <div id="Map-Container" style="width: 100%; height: 400px;"></div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirm">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </div>
      </el-dialog>
    </section>
  </template>
  
  <script>
  import {EquipmentInstall,GetSubSite} from '@/api/operateCenter.js';
  
  export default {
    props:{row:{type:Object}},
    data() {
      return {
        form:{
            equipmentNum:"",
            siteId:"",
            address:"",
            gps:"",
        },
        imageUrl:'',
        value:[],
        options:[],
        show:true,
        rules: {
          equipmentNum: [
            { required: true, message: "设备编号不得为空", trigger: "blur" },
          ],
          address: [
            { required: true, message: "安装地址不得为空", trigger: "blur" },
          ],
          siteId: [
            { required: true, message: "场地不得为空", trigger: "blur" },
          ],
        },
        siteOptions:[],
        open:false,
        // 搜索地址
        searchAddress: "",
        userGps:["120.17906278816268","30.32004063996321"], // 用户当前位置
        // 地址信息
        addressInfo: {
            longitude: "",// 经度
            latitude: "",// 纬度
            province: "",// 省
            city: "",// 市
            district: "",// 区
            address: "",// 详细地址
        },
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.form = this.row ? {...this.row} : this.form;
      this.getSites();
    },
  
    methods: {
       close(){
          this.$emit('close');
       },
       // 获取设备安装场地下拉数据
        async getSites(){
            await GetSubSite().then(res=>{
                if(res.success){
                    this.siteOptions=res.data;
                }
            })
        },
        // 打开地图选址
        chooseAddress(){
          this.open=true;
          this.initBaiduMap();
        },
        // 初始化百度地图
        initBaiduMap() {
          let Gps = this.form.gps ? this.form.gps.split(',') : this.userGps;
          this.$nextTick(() => {
            /* 初始化地图 start */
            var map = new BMapGL.Map("Map-Container") // 创建地图实例
            var point = new BMapGL.Point(Gps[0], Gps[1]); // 设置中心点坐标
            map.centerAndZoom(point, 18); // 地图初始化，同时设置地图展示级别
            map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
            /* 初始化地图 end */
    
            /** 点击地图创建坐标事件Start */
            // 提交地图点击事件
            map.addEventListener("click", (e) => {
              map.clearOverlays(); // 移除地图上的标注
              this.$nextTick(()=>{
                var click = e.latlng; // 点击的坐标
                var Point = new BMapGL.Point(click.lng,click.lat);
                var Marker = new BMapGL.Marker(Point); // 创建标注
                map.addOverlay(Marker); // 将标注提交到地图中
                this.geocAddress(Point);
              })
            })
            /** 点击地图创建坐标事件End */
    
            /** 搜索地址Start */
            // 建立一个自动完成的对象
            var ac = new BMapGL.Autocomplete({
              input: "searchAddressInput",
              location: map,
              onSearchComplete:(e)=>{}
            });
            // 鼠标点击下拉列表后的事件
            ac.addEventListener("onconfirm", (e) => {
              map.clearOverlays();
              var local = new BMapGL.LocalSearch(map, {
                // 智能搜索
                onSearchComplete: (res) => {
                  let poi = res.getPoi(0); // 获取第一个智能搜索的结果
                  var searchpt = poi.point; // 获取坐标
                  map.centerAndZoom(searchpt, 16);
                  map.addOverlay(new BMapGL.Marker(searchpt));
                  this.geocAddress(searchpt);
                }
              });
              // 搜索词
              var searchValue = e.item.value;
              local.search(
                searchValue.province +
                searchValue.city +
                searchValue.district +
                searchValue.street +
                searchValue.business
              )
            });
            /** 搜索地址End */
          })
        },
        // 逆向解析地址
        geocAddress(point) {
            var geoc = new BMapGL.Geocoder();
            geoc.getLocation(point,  (geocInfo) => {
              // 设置基本信息
              var addressInfo = geocInfo.addressComponents;
              this.addressInfo.longitude = point.lng;
              this.addressInfo.latitude = point.lat;
              this.addressInfo.province = addressInfo.province;
              this.addressInfo.city = addressInfo.city;
              this.addressInfo.district = addressInfo.district;
              let address = addressInfo.street + addressInfo.streetNumber;
              if (geocInfo.surroundingPois.length > 0) {
                address = address + geocInfo.surroundingPois[0].title;
              }
              this.addressInfo.address = address;
            });
        },
        /** 确认选择 */
        confirm() {
          this.$emit("confirmMapAddress", this.addressInfo);
          this.open = false;
          this.form.address = this.addressInfo.province + this.addressInfo.city + this.addressInfo.district + this.addressInfo.address;
          this.form.gps = this.addressInfo.longitude + ',' + this.addressInfo.latitude;
        },
        // 取消选择
        cancel() {
          this.open = false;
        },
       //  添加
       submit(form) {
        console.log(this.form.address,987)
          this.$refs["form"].validate(valid => {
            if (valid) {
                EquipmentInstall(this.form).then((res) => {
                  if (res.success) {
                    this.$message({
                      message: "提交成功",
                      type: "success",
                    });
                    setTimeout(() => {
                      this.$emit('close');
                    }, 1000);
                  }else{
                    this.$message({
                      message: "提交失败,请重试",
                      type: "fail",
                    });
                  }
                })
                .catch(error => {});
            } else {
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
  .map{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 80px;
   }
   /deep/ .el-form-item__content{
    display: flex;
   }
   .el-drawer{
     overflow:auto !important;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  