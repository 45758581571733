<template>
  <div>
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
      <el-button size="mini" type="primary" @click="cancelAuthorization" v-if="order.orderState!==2">取消授权</el-button>
    </div>
    <div class="pay">
      <p><b>交易单号：</b><span>{{orderInfo.orderNum}}</span></p>
      <p v-if="order.payPlatform==2"><b>微信支付单号：</b><span>{{payInfo.wxOrderNum?payInfo.wxOrderNum:'--'}}</span></p>
      <p v-if="order.payPlatform==1"><b>支付宝支付单号：</b><span>{{payInfo.aliOrderNum}}</span></p>
      <p v-if="order.payPlatform==1"><b>支付宝授权单号：</b><span>{{payInfo.agreementNo}}</span></p>
      <p><b>客户昵称：</b><span>{{order.payPlatform == 1 ? order.aliUserName : order.weChatName}}</span></p>
      <p v-if="order.payPlatform==1"><b>订单状态：</b><span>{{order.orderState <2 ?'待支付':(order.orderState==2?'已结束':(order.orderState==3?'退款中':(order.orderState==4?'已退款':'异常订单')))}}</span></p>
      <p v-if="order.payPlatform==2"><b>订单状态：</b><span>{{order.orderState <2 ?'待支付':(order.orderState==2?'已结束':(order.orderState==3?'退款中':(order.orderState==4?'已退款':'异常订单')))}}</span></p>
      <p><b>出货状态: </b><span>{{order.shipmentState==0?'未开始':(order.shipmentState==1?'出货中':'已完成')}}</span></p>
      <p><b>支付金额：</b><span>{{payInfo.orderAmount}}元</span></p>
      <p><b>支付类型：</b><span>{{order.payPlatform == 1 ? '支付宝' : '微信'}}</span></p>
      <p><b>订单开始时间：</b><span>{{order.createTime}}</span></p>
      <p><b>支付时间：</b><span>{{order.payTime}}</span></p>
    </div>
    <div class="order">
      <p><b>设备编号：</b><span>{{orderInfo.equipmentNum}}</span></p>
      <p><b>购买场所：</b><span>{{orderInfo.siteName?orderInfo.siteName:'--'}}</span></p>
      <p><b>详细地址：</b><span>{{orderInfo.siteAddress+orderInfo.address?orderInfo.siteAddress+orderInfo.address:'--'}}</span></p>
      <p><b>所属代理：</b><span>{{order.agentName}}</span></p>
      <p><b>所属商户：</b><span>{{order.managerName}}</span></p>
    </div>
    <div class="goods">
      <div class="add">
        <el-select class='select' v-model="addGoodsInfo.id" clearable placeholder="请选择商品">
          <el-option
            v-for="item in goodsOptions"
            :key="item.id"
            :label="item.goodsName"
            :value="item.id">
          </el-option>
        </el-select>
        <el-input class="wxName" v-model="addGoodsInfo.payNum"  placeholder="请输入购买数量"></el-input>
        <el-button size="mini" type="primary" @click="handleAdd">补单</el-button>
      </div>
      <el-table
        :data="goodsInfo"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="商品图片"
          prop="goodsImg"
          align='center'>
          <template slot-scope="scope" v-if="scope.row.goodsImg">
            <img :src="scope.row.goodsImg" alt="" v-if="scope.row.goodsImg.indexOf('http') !==-1">
            <img :src="'https://www.quantoukeji.com'+scope.row.goodsImg" alt="" v-else>
          </template>
        </el-table-column>
        <el-table-column
          label="商品名称"
          prop="goodsName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="商品价格（元）"
          prop="amount"
          align='center'>
        </el-table-column>
        <el-table-column
          label="购买数量（件）"
          prop="payNum"
          align='center'>
        </el-table-column>
        <el-table-column
         label="操作"
         fixed="right"
         align='center'
         width="200">
          <template slot-scope="scope">
              <el-button size="mini" @click="handelDel(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="sub">
        <el-button size="mini" type="primary" @click="submit">提交</el-button>
      </div>
    </div>
    <div class="video" v-if="orderInfo.videoFileUrl">
      <video width="350" height="240" controls>
          <source :src="orderInfo.videoFileUrl[0]" type="video/mp4">
          <!-- <source src="movie.ogg" type="video/ogg"> -->
          您的浏览器不支持播放该视频。
      </video>
      <video width="350" height="240" controls>
          <source :src="orderInfo.videoFileUrl[1]" type="video/mp4">
          您的浏览器不支持播放该视频。
      </video>
    </div>
  </div>
</template>

<script>
import {getOrderInfoDetail,getOrderPayInfo,getOrderGoodsInfo,getEquipmentPayGoodsInfo,OrderSettlement,cancelOrder} from '@/api/operateCenter.js';
export default {
  props:{row:{type:Object}},
  data() {
    return {
      order:{},
      orderInfo:{},// 订单详情
      payInfo:{}, // 订单支付信息
      goodsInfo:[], // 订单购买商品信息
      type:'',
      videoUrl:'',
      hls:null,
      goodsOptions:[],
      addGoodsInfo:{},
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.order=this.$route.query.order;
    this.type=this.$route.query.type;
    this.init();
  },

  methods: {
    // 获取具体信息
    async init(){
        // 订单信息
        await getOrderInfoDetail({OrderNum:this.order.orderNum}).then(res=>{
            if(res.success){
                this.orderInfo=res.data[0];
                this.orderInfo.videoFileUrl = this.orderInfo.videoFileUrl?this.orderInfo.videoFileUrl.split(','):null;
                console.log(this.orderInfo.videoFileUrl,987)
            }
        })
        // 支付信息
        await getOrderPayInfo({OrderNum:this.order.orderNum,PayPlatform:this.order.payPlatform}).then(res=>{
          if(res.success){
            this.payInfo=res.data[0];
          }
        })
        // 商品信息 
        await getOrderGoodsInfo({Data:this.order.orderNum}).then(res=>{
          if(res.success){
            this.goodsInfo=res.data;
            console.log(this.goodsInfo,999)
          }
        })
        // 获取该笔订单所用设备的所有售卖商品
        await getEquipmentPayGoodsInfo({EquipmentNum:this.orderInfo.equipmentNum}).then(res=>{
          if(res.success){
            this.goodsOptions=res.data;
          }
        })
    },
    // 删除商品
    handelDel(index,row){
      this.goodsInfo.splice(index,1)
    },
    // 补单
    handleAdd(){
      let obj;
      this.goodsOptions.forEach(element=>{
        if(this.addGoodsInfo.id==element.id){
          obj={
            goodsId:element.goodsId,
            goodsImg:element.goodsImg,
            goodsName:element.goodsName,
            orderNum:this.orderInfo.orderNum,
            payNum:this.addGoodsInfo.payNum,
            amount:element.amount*this.addGoodsInfo.payNum,
          }
        }
      })
      this.goodsInfo.push(obj);
    },
    // 取消授权
    cancelAuthorization(){
      this.$confirm('确认取消授权吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          cancelOrder({Data:this.orderInfo.orderNum}).then(res=>{
            if(res.success){
              this.$message({
                message:'取消成功',
                type:'success'
              })
            }else{
              this.$message({
                message:'取消失败，请重试',
                type:'fail'
              })
            }
          })
        })
    },
    // 提交补单
    async submit(){
      await OrderSettlement({data1:this.orderInfo.orderNum,data2:this.goodsInfo}).then(res=>{
        if(res.success){
          this.$message({
            message:'提交成功',
            type:'success'
          })
          this.init();
        }else{
          this.$message({
            message:'提交失败,请重试',
            type:'fail'
          })
        }
      })
    },
    // 返回上一页
    back(){
      if(this.type=='normal'){
        this.$router.push('/trading/orderList');
      }else{
        this.$router.push('/trading/abnormalOrder');
      }
    },
  },
};
</script>
<style scoped lang="scss">
.video{
  display: flex;
  justify-content: space-around;
}
.add{
  padding-bottom: 20px;
  display: flex;
  .el-input,.select{
    margin-right: 10px;
    // width: 25%;
  }
}
.sub{
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
}
.goods{
  padding: 20px;
  img{
    height: 50px;
    width: 50px;
  }
}
.pay{
  margin-top: 20px;
  p{
    // width: 50% !important;
  }
}
.order,.pay{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 15px;
  p{
    width: calc(100% / 3);
    text-align: left;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
  }
}
.back{
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding:0 30px;
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  .el-icon-back{
    font-size: 25px;
    cursor: pointer;
  }
}
</style>
