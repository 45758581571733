<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="提现用户" prop="takingName">
        <el-input v-model="row.takingName" disabled></el-input>
      </el-form-item>
      <el-form-item label="提现金额" prop="takingAmount">
        <el-input v-model="row.takingAmount" disabled></el-input>
      </el-form-item>
      <el-form-item label="打款截图" prop="paymentImg">
        <el-upload
          action="https://www.quantoukeji.com/api/UploadImage/FileUp"
          :headers='headers'
          :show-file-list="false"
          :on-success="handleSuccess"
          >
          <img v-if="form.paymentImg" :src="form.paymentImg" >
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { checkTakingAmountInfo,getImgUrl } from '@/api/operateCenter.js';
let token=sessionStorage.getItem('token');
export default {
  props:['row'],
  data() {
    return {
        headers:{authorization:token},
        form:{
            paymentImg:"",
        },
        rules:{
            paymentImg: [
              { required: true, message: "打款截图不得为空", trigger: "change" },
            ],
        }
    }
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    // 关闭弹框
    close(){
      this.$emit('close');
    },
    // 上传图片成功
    handleSuccess(e){
        this.form.paymentImg = getImgUrl() + e.data;
    },
    // 提交
    submit(){
        this.$refs["form"].validate(valid => {
            if (valid) {
                this.$confirm('确认提交吗？').then( () => {
                    let data = {
                        id:this.row.id,
                        paymentImg:this.form.paymentImg,
                    }
                    checkTakingAmountInfo(data).then(res=>{
                        if(res.success){
                            this.$message({
                                message:'操作成功',
                                type:'success',
                            })
                            this.$emit('close')
                        }
                    })
                })
            } else {
                return false;
            }
        });
    },
  },
};
</script>
<style scoped lang="scss">
 .el-drawer{
   overflow:auto !important;
 }
.avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  img{
    width: 178px;
    height: 178px;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  /deep/ .el-form-item {
    display: flex;
  }
  /deep/ .el-form-item__label{
    width: 150px !important;
  }
  /deep/ .el-form-item__content{
    margin-left: 0 !important;
    flex: 1 !important;
  }
</style>
