<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="用户名" prop="UserName" >
        <el-input placeholder="请输入用户名" v-model="form.UserName">
        </el-input>
      </el-form-item>
      <el-form-item label="登录名" prop="LoginName" >
        <el-input placeholder="请输入登录名" v-model="form.LoginName">
        </el-input>
      </el-form-item>
      <el-form-item label="角色" prop="roleId" >
        <el-select v-model="form.roleId" placeholder="请选择" @change='chooseRole'>
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.roleName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属代理" prop="pId" v-if="form.roleId == 4">
        <el-select v-model="form.pId" placeholder="请选择" @change='choose'>
          <el-option
            v-for="item in agentOptions"
            :key="item.id"
            :label="item.userName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否冻结" prop="IsLocked" >
         <el-radio-group v-model="form.IsLocked" @change='select_name'>
           <el-radio-button label="否" @change='select_name'></el-radio-button>
           <el-radio-button label="是" @change='select_name'></el-radio-button>
         </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit('form')" :plain='true'>立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {editUser,getRolesList} from '@/api/public.js';
import {getQueryRolesList,getUserList} from '@/api/operateCenter.js';

export default {
  props:{
    row:{
      type:Object,
    }
  },
  data() {
    return {
      form:{
        Id:'',
        UserName:'',
        LoginName:'',
        Password:'',
        roleId:'',
        IsLocked:'',
        RoleName:'',
      },
      roleId:'',
      imageUrl:'',
      value:[],
      options:[],
      agentOptions:[],
      show:true,
      rules: {
        UserName: [
          { required: true, message: "用户名不得为空", trigger: "blur" },
        ],
        LoginName: [
          { required: true, message: "登录名不得为空", trigger: "blur" },
        ],
        Password: [
          { required: true, message: "密码不得为空", trigger: "blur" },
        ],
        roleId: [
          { required: true, message: "请选择角色", trigger: "change" },
        ],
        IsLocked: [
          { required: true, message: "请选择是否冻结", trigger: "change" },
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.form.IsLocked=this.row.isLocked==1?'是':'否';
    this.form.Id=this.row.id;
    this.form.UserName=this.row.userName;
    this.form.LoginName=this.row.loginName;
    this.form.RoleName=this.row.roleName;
    this.form.roleId=this.row.roleId;
    _this=this;
    _this.init();
  },

  methods: {
    init(){
      getQueryRolesList().then(res=>{
        if(res.success){
          this.options=res.data;
        }
      })
      getUserList({roleId:3}).then(res=>{
        if(res.success){
          this.agentOptions = res.data;
        }else{
          this.agentOptions = [];
        }
      })
    },
     close(){
        _this.$emit('close');
     },
    //  选择角色
     chooseRole(e){
       this.options.forEach(element=>{
         if(element.roleName==e){
           this.form.roleId=element.id
         }
       })
     },
     //  是否冻结
     select_name(){},
     //  修改
     edit(form) {
        _this.$refs["form"].validate(valid => {
          if (valid) {
            if(_this.form.IsLocked==='否'){
              _this.form.IsLocked=0;
            }else if(_this.form.IsLocked==='是'){
              _this.form.IsLocked=1;
            }
            editUser(_this.form)
              .then((res) => {
                if (res.success) {
                  _this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.$emit('close');
                  }, 1000);
                }else{
                  _this.$message({
                    message: "修改失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
