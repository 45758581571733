<template>
  <section >
    <div class="search">
      <div class="inputs">
          <p class="each">
              <span>模板名称：</span>
              <el-input class="wxName" v-model="searchInfo.data.templateName" placeholder="请输入模板名称"></el-input>
          </p>
         <el-button type="primary" @click="toSearch">搜索</el-button>
         <el-button type="primary" @click="backAll">清空搜索条件</el-button>
         <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>
    </div>
    <div class="container">
      <el-table
        :data="placeList"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="模板名称"
          prop="templateName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="免费时间（min）"
          prop="freeTime"
          align='center'>
        </el-table-column>
        <el-table-column
          label="初始计费时间(min)"
          prop="frontTime"
          width="150"
          align='center'>
        </el-table-column>
        <el-table-column
          label="初始计费金额（元）"
          prop="frontAmount"
          align='center'>
        </el-table-column>
        <el-table-column
          label="单位计费时间（min）"
          prop="everyTime"
          align='center'>
        </el-table-column>
        <el-table-column
          label="单位计费金额（元）"
          prop="everyAmount"
          align='center'>
        </el-table-column>
        <el-table-column
          label="单日封顶金额（元）"
          prop="dayMaxAmount"
          align='center'>
        </el-table-column>
        <el-table-column
          label="最大封顶金额（元）"
          prop="maxAmount"
          align='center'>
        </el-table-column>
        <el-table-column
          align="center"
          v-if="userInfo.roleId == 1">
          <template slot="header" slot-scope="scope">
            <span>系统抽单</span>
            <el-tooltip class="item" effect="dark" content="设置的数量为10时代表每当统计订单量为10的倍数时抽一单,例：有10笔订单抽1单，有20笔订单抽2单，如需5单抽一单可设置为5，这样用户只算4单的分润" placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <p>{{ scope.row.samplingRatio }}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="createTime"
          align='center'>
        </el-table-column>
        <el-table-column
         label="操作"
         fixed="right"
         width="200"
         align='center'>
          <template slot-scope="scope">
              <el-button size="mini" @click="handleDel(scope.$index, scope.row)">删除</el-button>
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page="searchInfo.pageIndex"
          :page-size="10"
          :total="total"
          background
          style="float:right;"
        ></el-pagination>
      </el-col>
      <!-- 新增 -->
      <el-drawer
       title="新增"
       size="50%"
       :visible.sync="addDrawer"
       :direction="direction"
       :before-close="handleClose">
        <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :row="row"></add>
      </el-drawer>
      <!-- 修改菜单 -->
      <el-drawer
       title="修改"
       :visible.sync="editDrawer"
       :direction="direction"
       :before-close="handleClose">
        <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {getChargingTemplateInfo,delChargingTemplateInfo} from '@/api/operateCenter.js';
import add from './add.vue';
import edit from './edit.vue';
export default {
  data() {
    return {
      // 订单列表信息
      placeList:[],
      // 分页样式
      total:0,
      searchInfo:{ // 查询条件
        pageIndex:1,
        pageSize:10,
        data:{
          templateName:'',
        },
      },
      addDrawer:false,
      editDrawer:false,
      id:'',
      row:'',
      direction:'rtl',
      userInfo:JSON.parse(sessionStorage.getItem('userInfo'))
    }
  },

  components: {add,edit},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    selectStatus(e){},
    // 初始化
    async init(){
      await getChargingTemplateInfo(this.searchInfo).then(res=>{
        if(res.success){
          this.placeList=res.data.data;
          this.placeList.forEach(element => {
            for(let key in element){
              element[key]=element[key] || element[key]==0?element[key]:'--';
            }
          });
          this.total=res.data.totalCount;
        }
      })
    },
     // 删除用户
    handleDel(index,row){
      this.$confirm('确认删除吗')
       .then(async ()=>{
          await delChargingTemplateInfo({Id:row.id}).then(res=>{
            if(res.success){
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              });
              this.init();
            }else{
              this.$message({
                showClose: true,
                message: '删除失败',
                type: 'fail'
              });
            }
          })
       })
       .catch(()=>{})
    },
     // 打开新增
    handleAdd(){
      this.addDrawer=true;
      this.row = null;
    },
    // 打开修改
    handleEdit(index,row){
      this.addDrawer = true;
      this.id = index;
      this.row = row;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 新增、绑定、编辑成功
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.editDrawer=false;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 条件查询
    toSearch(){
      this.searchInfo.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex=1;
      this.searchInfo.data={
        templateName:'',
      }
      this.init();
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    prevpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
    nextpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>

 .search{
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  background: #fff;
  padding: 20px;
    .inputs{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .each{
          width:calc((100% - 40px ) / 5) ;
          height: 50px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          .el-select{
           width: 100% !important;
          }
          span{
              width: 100px;
          }
      }
      .el-button{
          margin-left: 15px;
      }
    }
  }

  .container{
      padding: 20px;
  }
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }

.el-table >>> .cell {
  white-space: pre-line;
}
  /deep/ .el-drawer__body{
    overflow: auto !important;
  }
.el-col-24{
    height: 52px;
   margin-top: 20px;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
