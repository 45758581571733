<template>
<section>
   <div class="menu">
    <el-button type="primary" @click="handleAdd">添加</el-button>
   </div>
   <div class="container">
       <el-table
       :data="tableData"
       style="width: 100%" 
       class="table"
       row-key="Id"
       border
       stripe
       :header-cell-style="{background:'#fafafa'}"
       fit
       :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
        align='center'
        prop="MenuName"
        label="菜单名称"
        width='200'>
      </el-table-column>
      <el-table-column
        prop="id"
        align='center'
        label="ID"
        width='100'>
      </el-table-column>
      <el-table-column
        align='center'
        prop="PageUrl"
        label="链接地址">
      </el-table-column>
      <el-table-column
        align='center'
        prop="IsLocked"
        label="是否冻结"
        width='100'>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align='center' width="300">
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="handleLock(scope.$index, scope.row)">冻结</el-button>
        <el-button
          size="mini"
          @click="handleEdit(scope.$index, scope.row)">修改</el-button>
          <el-button
          size="mini"
          @click="handleDel(scope.$index, scope.row)">删除</el-button>
        <el-button
          size="mini"
          @click="handleAdd(scope.$index, scope.row)" v-if="scope.row.Level==1">添加</el-button>
      </template>
    </el-table-column>
    </el-table>

    <!-- 新增菜单 -->
      <el-drawer
        title="新增菜单"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose">
        <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess()  "  ref='newForm' :id="id" :row="row"></add>
      </el-drawer>
      <!-- 修改菜单 -->
      <el-drawer
        title="修改菜单"
        :visible.sync="editDrawer"
        :direction="direction"
        :before-close="handleClose">
        <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess()  "  ref='newForm' :id="id" :row="row"></edit>
      </el-drawer>
  </div>
</section>
 
</template>

<script>
  import {getMenuList,deleteMenu,lockMenu} from '@/api/public.js';
  import add from './add.vue'
  import edit from './edit.vue'

export default {
  data() {
    return {
      tableData: [],
      addDrawer:false,
      editDrawer:false,
      id:'',
      row:'',
      direction:'rtl',
    }
  },

  components: {add,edit},

  computed: {},

  mounted() {
    this.init();
  },
  methods: {
    // 页面初始化
    async init(){
      await getMenuList().then(res=>{
        if(res.success){
          this.tableData=JSON.parse(res.data)
          this.tableData.forEach((element) => {
            if(element.IsLocked){
              element.IsLocked='是'
            }else{
              element.IsLocked='否'
            }
            if(element.PageUrl===''){
              element.PageUrl='--'
            }
            console.log(element.children)
            if(element.children.length>0){
              element.children.forEach(ele=>{
                if(ele.IsLocked){
                  ele.IsLocked='是'
                }else{
                  ele.IsLocked='否'
                }
              })
            }
          });
        }
      })
    },
    // 打开新增菜单
    handleAdd(index,row){
      this.addDrawer=true;
      this.row=row;
    },
    // 打开修改菜单
    handleEdit(index,row){
      this.row=row;
      this.editDrawer=true;
      this.id=index;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init()
    },
    // 删除菜单
    async handleDel(index,row){
      await this.$confirm('确认删除该菜单项吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteMenu({Id:row.Id}).then(res=>{
            if(res.success){
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.init();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    // 冻结菜单
    async handleLock(index,row){
      await this.$confirm('确认冻结该菜单项吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          lockMenu({Id:row.Id}).then(res=>{
            if(res.success){
              this.$message({
                message: "冻结成功",
                type: "success",
              });
              this.init();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消冻结'
          });          
        });
    },
    // 修改、新增成功
    handleDrawerSuccess(){
      this.init();
      this.addDrawer=false;
      this.editDrawer=false;
    },
    
  },
};
</script>
<style scoped lang='scss'>
.menu{
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  background: #fff;
  padding: 20px;
}
.container{
      padding: 20px;
  }
.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}
.el-col-24{
    height: 52px;
   margin-top: 20px;
}
 .menu{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .menu button{
    position: absolute;
    right:20px;
    top:10px;
  }
</style>
