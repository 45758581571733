<template>
  <section >
    <div class="search">
      <div class="inputs">
          <p class="each">
              <span style="width: 180px;">设备编号：</span>
              <el-input class="wxName" v-model="searchInfo.data.deviceName" placeholder="请输入设备编号"></el-input>
          </p>
          <p class="each">
              <span style="width: 150px;">通讯指令：</span>
              <el-input class="wxName" v-model="searchInfo.data.Command" placeholder="请输入通讯指令"></el-input>
          </p>
         <el-button type="primary" @click="toSearch">搜索</el-button>
         <el-button type="primary" @click="backAll">清空搜索条件</el-button>
      </div>
    </div>
    <div class="container">
      <el-table
        :data="replenishmentList"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="设备编号"
          prop="deviceName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="通讯指令"
          prop="command"
          align='center'>
        </el-table-column>
        <el-table-column
          label="通讯数据"
          prop="data"
          align='center'>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="createTime"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page="searchInfo.pageIndex"
          :page-size="10"
          :total="total"
          background
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
import {getMessageInfo} from '@/api/operateCenter.js';
export default {
  data() {
    return {
      replenishmentList:[], // 订单列表信息
      statusStyle:'', // 状态样式
      detailDrawer:false,
      id:'',
      row:'',
      direction:'rtl',
      total:0, // 数据总条数
      searchInfo:{ // 查询条件
        pageIndex:1,
        pageSize:10,
        data:{
          deviceName:'',
          Command:'',
          MessageId:'',
        },
      },
      IsTrue:'',
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    selectStatus(e){},
    // 初始化
    async init(){
      await getMessageInfo(this.searchInfo).then(res=>{
        if(res.success){
          this.replenishmentList=res.data.data;
          this.replenishmentList.forEach(element => {
            for(let key in element){
              element[key] = element[key] || element[key]==0 ? element[key] : '--';
            }
          });
          this.total=res.data.totalCount;
        }
      })
    },
    // 条件查询
    toSearch(){
      this.searchInfo.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex=1;
      this.searchInfo.data={
        deviceName:'',
        Command:'',
        MessageId:'',
      };
      this.init();
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    prevpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
    nextpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>

 .search{
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  background: #fff;
  padding: 20px;
    .inputs{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .each{
          width:calc((100% - 40px ) / 4) ;
          height: 50px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          .el-select{
           width: 100% !important;
          }
          span{
              width: 100px;
          }
      }
      .el-button{
          margin-left: 15px;
      }
    }
  }

  .container{
      padding: 20px;
  }
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }

.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}
.el-col-24{
    height: 52px;
   margin-top: 20px;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
