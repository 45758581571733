<template>
  <section>
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
    </div>
    <div class="container">
      <el-table
        :data="goodsList"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
        <!-- 数据展示区 -->
        <el-table-column
          label="设备编号"
          prop="equipmentNum"
          align='center'>
        </el-table-column>
        <el-table-column
          label="安装地址"
          prop="address"
          align='center'>
        </el-table-column>
        <el-table-column
          label="缺货商品"
          prop="goodsName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="商品图片"
          prop="goodsImg"
          align='center'>
          <template slot-scope="scope">
            <img :src="scope.row.goodsImg" alt="">
          </template> 
        </el-table-column>
        <el-table-column
          label="缺货数量"
          prop="repGoodsNum"
          align='center'>
        </el-table-column>
        <el-table-column
          label="库存数量"
          prop="goodsNum"
          align='center'>
          <template slot-scope="scope">
            <p style="color:red" v-if="scope.row.ifbugou == 1">{{  scope.row.goodsNum  }} <span style="font-size: 12px;">(库存不足)</span> </p>
            <p v-else>{{  scope.row.goodsNum  }}</p>
          </template> 
        </el-table-column>
        <el-table-column
          label="操作"
          align='center'
          fixed="right">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.$index,scope.row)">修改</el-button>
          </template> 
        </el-table-column>
      </el-table>
      <el-dialog title="修改" :visible.sync="open" width="500px" append-to-body>
          <el-form ref="form" label-width="80px" :rules="rules" :model="editInfo">
            <el-form-item label="缺货数量" prop="RepGoodsNum">
              <el-input type="number" id="searchAddress" v-model="editInfo.RepGoodsNum" placeholder="请输入缺货数量"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirm">确定</el-button>
            <el-button @click="open=false">取消</el-button>
          </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import {getRepGoodsInfo,UpdateGoodsNum} from '@/api/operateCenter.js';
export default {
  data() {
    var checkRepGoodsNum = (rule, value, callback) => { // 自定义表单验证
      if(value<=0){
        return callback(new Error('缺货数量须大于零'));
      }else{
        callback();
      }
    };
    return {
      repId:null,
      goodsList:[],
      editInfo:{
        Id:null,
        RepGoodsNum:0,
      },
      open:false,
      rules:{
        RepGoodsNum:[
          { required: true, message: "缺货数量不得为空", trigger: "blur" },
          { validator: checkRepGoodsNum, trigger: 'blur' },
        ]
      }
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.repId=this.$route.query.id;
    this.$nextTick(()=>{
      this.init();
    })
  },

  methods: {
    init(){
      getRepGoodsInfo({RepId:Number(this.repId) }).then(res=>{
        if(res.success){
          this.goodsList=res.data;
          this.goodsList.forEach(element => {
            if(element.goodsImg.indexOf('http') == -1){
              element.goodsImg= `https://www.quantoukeji.com${element.goodsImg}`
            }
          });
        }else{
          this.goodsList=[];
        }
      })
    },
    handleEdit(index,row){
      let data={
        Id:row.id,
        RepGoodsNum:Number(row.repGoodsNum) 
      }
      this.editInfo={...data};
      this.open=true;
    },
    confirm(form){
      this.$refs["form"].validate(async valid=>{
        if(valid){
          this.editInfo.RepGoodsNum = Number(this.editInfo.RepGoodsNum)
          await UpdateGoodsNum(this.editInfo).then(res=>{
            if(res.success){
              this.$message({
                message:'操作成功',
                type:'success'
              })
              this.init();
              this.open=false;
            }else{
              this.$message({
                message:'操作失败，请重试',
                type:'fail'
              })
            }
          })
        }
      })
    },
    back(){
      this.$router.push('/equipManage/replenishmentRecord')
    },
  },
};
</script>
<style scoped lang="scss">
.back{
  padding: 20px;
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .el-icon-back{
    font-size: 25px;
    cursor: pointer;
  }
}
.container{
  padding: 20px;
}
img{
  height: 60px;
  width: 60px;
}
</style>
