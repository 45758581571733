<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="场地名称" prop="siteName" >
        <el-input placeholder="请输入场地名称" v-model="form.siteName">
        </el-input>
      </el-form-item>
      <el-form-item label="详细地址" prop="address" >
        <el-input placeholder="请输入详细地址" v-model="form.address">
        </el-input>
      </el-form-item>
      <el-form-item label="场地图片" prop="siteImg" >
        <el-upload
          action="https://www.quantoukeji.com/api/UploadImage/FileUp"
          :headers='headers'
          :show-file-list="false"
          :on-success="handleSuccess"
          >
          <img v-if="form.siteImg" :src="form.siteImg" >
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="所属代理" prop="agentId">
        <el-select v-model="form.agentId" placeholder="请选择" :disabled="userInfo.roleId === 2 || userInfo.roleId === 3">
        <el-option
          v-for="item in agentOptions"
          :key="item.id"
          :label="item.userName"
          :value="item.id">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="所属商户" prop="managerId">
        <el-select v-model="form.managerId" placeholder="请选择" :disabled="userInfo.roleId === 3">
        <el-option
          v-for="item in managerOptions"
          :key="item.id"
          :label="item.userName"
          :value="item.id">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit('form')" :plain='true'>立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {editSiteInfo,getUserList,getImgUrl} from '@/api/operateCenter.js';
let token=sessionStorage.getItem('token');
export default {
  props:{
    row:{
      type:Object,
    }
  },
  data() {
    return {
      headers:{authorization:token},
      userInfo:JSON.parse(sessionStorage.getItem('userInfo')) || undefined,
      form:{},
      agentId:'',
      managerId:'',
      value:[],
      agentOptions:[],
      managerOptions:[],
      show:true,
      rules: {
        siteName: [
          { required: true, message: "场地名称不得为空", trigger: "blur" },
        ],
        address: [
          { required: true, message: "详细地址不得为空", trigger: "blur" },
        ],
        agentId: [
          { required: true, message: "所属代理不得为空", trigger: "blur" },
        ],
        managerId:[
          { required: true, message: "所属店主不得为空", trigger: "blur" },
        ]
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.form=this.row;
    _this=this;
    _this.init();
    this.getManagers();
  },

  methods: {
    async init(){
      await getUserList({RoleId:3}).then(res=>{
        if(res.success){
          this.agentOptions=res.data;
        }
      })
    },
    async getManagers(){
      await getUserList({RoleId:4}).then(res=>{
        if(res.success){
          this.managerOptions=res.data;
        }
      })
    },
    close(){
       _this.$emit('close');
    },
    handleSuccess(e){
      this.form.siteImg = getImgUrl()+e.data;
    },
     //  修改
     edit() {
       _this.$refs["form"].validate(valid => {
          if (valid) {
            editSiteInfo(_this.form)
              .then((res) => {
                if (res.success) {
                  _this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    this.form={};
                    _this.$emit("success");
                    _this.$emit('close');
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: "修改失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .el-select{
   width: 100%;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
