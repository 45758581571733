<template>
    <section style="height:100%;display:flex;flex-direction: column;">
      <div class="back">
        <i class="el-icon-back" @click="back"></i>
      </div>
      <div class="container">
        <el-table
          :data="operationRecordList"
          style="width: 100%"
          :span-method="objectSpanMethod"
          height="100%"
          class="table"
          border
          stripe
          :header-cell-style="{background:'#fafafa'}"
          fit
        >
          <!-- 数据展示区 -->
          <el-table-column
            label="设备编号"
            prop="equipmentNum"
            align='center'>
          </el-table-column>
          <el-table-column
            label="是否补货成功"
            prop="isTrue"
            align='center'>
            <template slot-scope="scope">
              <p>{{scope.row.isOpen==0?'未操作':(scope.row.isOpen==1?(scope.row.isTrue==0?'已开门':(scope.row.isTrue==1?'成功':'异常')):'异常')}}</p>
            </template> 
          </el-table-column>
          <el-table-column
            label="回复时间"
            prop="replyTime"
            show-overflow-tooltip
            align='center'>
          </el-table-column>
          <el-table-column
            label="异常描述"
            prop="rmk"
            show-overflow-tooltip
            align='center'>
          </el-table-column>
        </el-table>
      </div>
    </section>
  </template>

<script>
import {getRepEquipmentInfo} from '@/api/operateCenter.js';
export default {
  data() {
    return {
        repId:null,
        operationRecordList:[],
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.repId=this.$route.query.id;
    this.$nextTick(()=>{
      this.init();
    })
  },

  methods: {
    async init(){
        await getRepEquipmentInfo({Data:this.repId}).then(res=>{
            if(res.success){
                this.operationRecordList=res.data;
                this.operationRecordList.forEach(element => {
                  for(let key in element){
                    element[key] = element[key] || element[key]==0 ? element[key] : '--';
                  }
                });
            }
        })
    },
    back(){
      this.$router.push('/equipManage/replenishmentRecord')
    },
    flitterData(arr) {
      const spanOneArr = [];
      let concatOne = 0;
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          if (item.equipmentNum === arr[index - 1].equipmentNum) {
            // 第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }
        }
      });
      return {
        one: spanOneArr,
      };
    },
    // 合并同类项
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.flitterData(this.operationRecordList).one[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
  },
};
</script>
<style scoped lang="scss">
.back{
  padding: 20px;
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .el-icon-back{
    font-size: 25px;
    cursor: pointer;
  }
}
.container{
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
}
</style>
