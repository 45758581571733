<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="订单编号" prop="orderNum" >
          <el-input v-model="form.orderNum" disabled>
          </el-input>
        </el-form-item>
        <el-form-item label="订单金额（元）" prop="orderAmount">
          <el-input v-model="form.orderAmount" disabled>
          </el-input>
        </el-form-item>
        <el-form-item label="退款金额（元）" prop="refundAmount" >
          <el-input placeholder="请输入退款金额" v-model="form.refundAmount" type="number">
          </el-input>
        </el-form-item>
        <el-form-item label="退款原因" prop="rmk" >
          <el-input placeholder="请输入退款原因" v-model="form.rmk" type="textarea">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="add('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {AddRefund} from '@/api/operateCenter.js';
  export default {
    props:{
      row:{type:Object},
    },
    data() {
      var checkRefund = (rule, value, callback) => { // 自定义表单验证
        if (value>this.row.orderAmount) {
          return callback(new Error('退款金额不得大于订单金额'));
        }else if(value<=0){
          return callback(new Error('退款金额须大于零'));
        }else{
          callback();
        }
      };
      return {
        userInfo:JSON.parse(sessionStorage.getItem('userInfo')) || undefined,
        form:{
          orderNum:'',
          orderAmount:'',
          refundAmount:0,
          rmk:'',
        },
        rules: {
          refundAmount: [
            { required: true, message: "退款金额不得为空", trigger: "blur" },
            { validator: checkRefund, trigger: 'blur' }
          ],
        },
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.form = this.row ? this.row : this.form;
      _this=this;
    },
  
    methods: {
      close(){
        _this.$emit('close');
      },
       //  添加
      add(form) {
         _this.$refs["form"].validate(valid => {
            if (valid) {
                AddRefund(this.form).then(res=>{
                  if(res.success){
                    this.$message({
                      message:'提交成功',
                      type:'success'
                    })
                    this.$nextTick(()=>{
                      _this.$emit('success');
                    })
                  }else{
                    this.$message({
                      message:'提交失败，请重试',
                      type:'fail'
                    })
                  }
                })
            } else {
              return false;
            }
        });
      },
      
    }
  };
  </script>
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .el-select{
     width: 100%;
   }
  //  el-select-dropdown__wrap el-scrollbar__wrap
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    /deep/ .el-form-item {
      display: flex;
    }
    /deep/ .el-form-item__label{
      width: 150px !important;
    }
    /deep/ .el-form-item__content{
      margin-left: 0 !important;
      flex: 1 !important;
    }
  </style>
  