<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="角色名称" prop="RoleName" required>
        <el-input placeholder="请输入角色名称" v-model="form.RoleName" >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit('form')" :plain='true'>立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {editRole} from '@/api/public.js';

export default {
  props:{
    row:{type:Object}
  },
  data() {
    return {
      form:{
        RoleName:'',
        Id:'',
      },
      imageUrl:'',
      value:[],
      options:[],
      show:true,
      rules: {
        RoleName: [
          { required: true, message: "角色名不得为空", trigger: "blur" },
        ],
        IsLocked:[
          { required: true, message: "请选择是否冻结", trigger: "change" },
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.form.Id=this.row.id;
    this.form.RoleName=this.row.roleName;
    _this=this;
  },

  methods: {
    select_name(e){
      console.log(e,99)
    },
     close(){
        _this.$emit('close');
     },
     //  添加
     edit(form) {
        _this.$refs["form"].validate(valid => {
          if (valid) {
            editRole(_this.form)
              .then((res) => {
                if (res.success) {
                  _this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                    _this.$emit('close');
                  }, 1000);
                }else{
                  _this.$message({
                    message: "修改失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
