<template>
  <section>
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
    </div>
    <div class="container">
      <el-table
        :data="goodsList"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
        <!-- 数据展示区 -->
        <el-table-column
          label="商品图片"
          prop="goodsImg"
          align='center'>
          <template slot-scope="scope">
            <img :src="scope.row.goodsImg" alt="">
          </template> 
        </el-table-column>
        <el-table-column
          label="商品名称"
          prop="goodsName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="盘存数量"
          prop="stockGoodsNum"
          align='center'>
        </el-table-column>
      </el-table>
    </div>
  </section>
  
</template>

<script>
import {getStockGoodsInfo} from '@/api/operateCenter.js';
export default {
  data() {
    return {
      StockId:null,
      goodsList:[],
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.StockId=this.$route.query.id;
    this.$nextTick(()=>{
      this.init();
    })
    
  },

  methods: {
    init(){
      getStockGoodsInfo({StockId:Number(this.StockId)}).then(res=>{
        if(res.success){
          this.goodsList=res.data;
        }else{
          this.goodsList=[];
        }
      })
    },
    back(){
      this.$router.push('/equipManage/inventoryRecord')
    },
  },
};
</script>
<style scoped lang="scss">
.back{
  padding: 20px;
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .el-icon-back{
    font-size: 25px;
    cursor: pointer;
  }
}
.container{
  padding: 20px;
}
img{
  height: 60px;
  width: 60px;
}
</style>
