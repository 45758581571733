<template>
  <div>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="计费模板" prop="chargingTemplateId" >
        <el-select v-model="form.chargingTemplateId" placeholder="请选择">
        <el-option
          v-for="item in templatesOptions"
          :key="item.id"
          :label="item.templateName"
          :value="item.id"
          >
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="add('form')" :plain='true'>立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getListChargingTemplateInfo,UpdateChargingTemplateId } from "@/api/operateCenter.js";
export default {
  props:{row:{
    type:Object
  }},
  data() {
    return {
        templatesOptions:[],
        form:{
            id:null,
            chargingTemplateId:null,
        },
        row:null,
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.form.id = this.row.id;
    this.form.chargingTemplateId = this.row.chargingTemplateId && this.row.chargingTemplateId != '--' ? Number(this.row.chargingTemplateId)  :null ;
    this.init();
  },

  methods: {
    async init(){
        await getListChargingTemplateInfo().then(res=>{
            if(res.success){
                this.templatesOptions = res.data;
            }
        })
    },
    add(){
        UpdateChargingTemplateId(this.form).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功'
            })
            setTimeout(() => {
              this.form={};
              _this.$emit("success");
              _this.$emit('close');
              _this.init();
            }, 1000);
          }else{
            this.$message({
              message:'提交失败',
              type:"error"
            })
          }
        })
    }
  },
};
</script>
<style scoped></style>
