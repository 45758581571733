<template>
  <section >
    <div class="equipInfo" v-if="equipType">
        <i class="el-icon-back" @click="back"></i>
        <p>设备编号：{{searchInfo.data.EquipmentNum}}</p>
    </div>
    <div class="search">
      <div class="inputs">
          <p class="each">
              <span>订单编号：</span>
              <el-input class="wxName" v-model="searchInfo.data.OrderNum" placeholder="请输入订单编号"></el-input>
          </p>
          <p class="each">
              <span>设备编号：</span>
              <el-input class="wxName" v-model="searchInfo.data.equipmentNum" placeholder="请输入设备编号"></el-input>
          </p>
          <p class="each">
              <span>支付类型：</span>
              <el-select class='select' v-model="PayPlatform" clearable placeholder="请选择" @change="selectStatus">
                <el-option
                  v-for="item in PayPlatformOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </p>
         <el-button type="primary" @click="toSearch">搜索</el-button>
         <el-button type="primary" @click="backAll">清空搜索条件</el-button>
         <el-button type="primary" @click="educe">订单导出</el-button>
      </div>
    </div>
    <div class="container">
      <el-table
        :data="orderList"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="订单编号"
          prop="orderNum"
          :show-overflow-tooltip='showoverflow'
          align='center'>
        </el-table-column>
        <el-table-column
          label="设备编号"
          prop="equipmentNum"
          :show-overflow-tooltip='showoverflow'
          align='center'>
        </el-table-column>
        <el-table-column
          label="充电宝编号"
          prop="goodsId"
          :show-overflow-tooltip='showoverflow'
          align='center'>
        </el-table-column>
        <el-table-column
          label="所属场地"
          prop="siteName"
          :show-overflow-tooltip='showoverflow'
          align='center'>
        </el-table-column>
        <el-table-column
          label="交易方式"
          prop="payPlatform "
          width='100px'
          align='center'>
          <template slot-scope="scope">
            <p>{{scope.row.payPlatform ===1 ? '支付宝' : '微信'}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="购买用户"
          prop="weChatName"
          width='150px'
          align='center'>
          <template slot-scope="scope">
            <p>{{scope.row.payPlatform == 2 ? scope.row.weChatName : scope.row.aliUserName}}</p>
            <p v-if="scope.row.payPlatform == 2">{{scope.row.wxPhone}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="出货状态"
          prop="shipmentState"
          :show-overflow-tooltip='showoverflow'
          align='center'>
          <template slot-scope="scope">
            <p>{{scope.row.shipmentState==0?'未出货':(scope.row.shipmentState==1?'准备出货':(scope.row.shipmentState == 2 ? '出货成功' : '异常'))}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="订单状态"
          prop="orderState"
          :show-overflow-tooltip='showoverflow'
          align='center'>
          <template slot-scope="scope">
            <p>{{scope.row.orderState==0?'待支付':(scope.row.orderState==1?'已支付':(scope.row.orderState==2?'已结束':(scope.row.orderState==3?'退款中':(scope.row.orderState==4?'已退款':'支付异常'))))}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="createTime"
          :show-overflow-tooltip='showoverflow'
          align='center'>
        </el-table-column>
        <el-table-column
          label="支付时间"
          prop="payTime"
          :show-overflow-tooltip='showoverflow'
          align='center'>
        </el-table-column>
        <el-table-column
          label="订单开始时间"
          prop="createTime"
          :show-overflow-tooltip='showoverflow'
          align='center'>
        </el-table-column>
        <el-table-column
          label="备注"
          prop="rmk"
          :show-overflow-tooltip='showoverflow'
          align='center'>
        </el-table-column>
        <el-table-column
         label="操作"
         fixed="right"
         align='center'
         width="200">
          <template slot-scope="scope">
              <!-- <el-button size="mini" @click="handelDetail(scope.$index, scope.row)">详情</el-button> -->
              <el-button size="mini" @click="handelRefund(scope.$index, scope.row)" v-if="scope.row.orderState==2">退款</el-button>
              <el-button size="mini" @click="cancelAuthorization(scope.$index, scope.row)" v-if="scope.row.shipmentState!=2 || scope.row.orderState!=2">取消授权</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page="searchInfo.pageIndex"
          :page-size="10"
          :total="total"
          background
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
import {getOrderInfo,cancelOrder,ExcelExportOrder} from '@/api/operateCenter.js';
export default {
  data() {
    return {
      // 订单列表信息
      orderList:[],
      // 分页样式
      total:0,
      searchInfo:{ // 查询条件
        pageIndex:1,
        pageSize:10,
        data:{
          ShipmentState:-1,
          OrderState:5,
          PayPlatform:0,
        },
      },
      showoverflow:true,
      ShipmentState:'',
      OrderState:'',
      PayPlatform:'',
      PayPlatformOptions:[ // 支付类型
        {value:1,label:'支付宝'},
        {value:2,label:'微信'},
      ],
      detailDrawer:false,
      refundDrawer:false,
      row:'',
      direction:'rtl',
      equipType:null,
    }
  },

  components: {},

  computed: {},

  mounted() {
    if(this.$route.query.type){
      sessionStorage.setItem('equipType',this.$route.query.type);
      sessionStorage.setItem('EquipmentNum',this.$route.query.equipmentNum);
      this.equipType=this.$route.query.type
      this.searchInfo.data.EquipmentNum=this.$route.query.equipmentNum
    }else{
      this.equipType=null;
      this.searchInfo.data.EquipmentNum='';
    }
    if(sessionStorage.getItem('equipType')){
      this.equipType=sessionStorage.getItem('equipType');
      this.searchInfo.data.EquipmentNum=sessionStorage.getItem('EquipmentNum');
    }else{
      this.equipType=null;
      this.searchInfo.data.EquipmentNum='';
    }
    this.init();
  },

  methods: {
    selectStatus(e){},
    // 初始化
    async init(){
      await getOrderInfo(this.searchInfo).then(res=>{
        if(res.success){
          this.orderList=res.data.data;
          this.orderList.forEach(element => {
            for(let key in element){
              element[key]=element[key] || element[key]==0?element[key]:'--';
            }
          });
          this.total=res.data.totalCount;
        }
      })
    },
    // 条件查询
    toSearch(){
      this.searchInfo.data.ShipmentState=this.ShipmentState !=='' ? this.ShipmentState : -1;
      this.searchInfo.data.PayPlatform=this.PayPlatform !==''  ? this.PayPlatform : 0;
      this.searchInfo.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex=1;
      this.searchInfo.data={
        ShipmentState:-1,
        OrderState:5,
        PayPlatform:0,
      },
      this.ShipmentState='';
      this.PayPlatform='';
      this.init();
    },
    // 导出
    async educe(){
      await ExcelExportOrder(this.searchInfo.data).then(res=>{
        if(res.success){
          window.open('https://www.quantoukeji.com/'+res.data);
        }
      })
    },
    // 取消授权 
    cancelAuthorization(index,row){
      this.$confirm('确认取消授权吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          cancelOrder({Data:row.orderNum}).then(res=>{
            if(res.success){
              this.$message({
                message:'取消成功',
                type:'success'
              })
            }else{
              this.$message({
                message:'取消失败，请重试',
                type:'fail'
              })
            }
          })
        })
    },
    // 发起退款
    handelRefund(index,row){
      this.row=row;
      this.refundDrawer=true;
    },
    // 打开详情
    handelDetail(index,row){
      this.$router.push({path:'/trading/orderDetail',query:{order:row,type:'normal'}})
      // this.row=row;
      // this.detailDrawer=true;
    },
    // 关闭
    handleClose(){
      this.detailDrawer=false;
      this.refundDrawer=false;
      this.init();
    },
    // 成功
    handleDrawerSuccess(){
      this.detailDrawer=false;
      this.refundDrawer=false;
      this.init();
    },
    // 返回设备列表
    back(){
      sessionStorage.removeItem('equipType');
      sessionStorage.removeItem('EquipmentNum');
      if(this.equipType == 'abnormal'){
        this.$router.push('/equipManage/abnormalEquipList');
      }else{
        this.$router.push('/equipManage/equipList');
      }
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    prevpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
    nextpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
.equipInfo{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 2px solid rgba(240, 242, 245, 1);
      .el-icon-back{
        font-size: 25px;
        cursor: pointer;
      }
  }
 .search{
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  background: #fff;
  padding: 20px;
    .inputs{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .each{
          width:calc((100% - 40px ) / 4) ;
          height: 50px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          .el-select{
           width: 100%;
          }
          span{
              width: 100px;
          }
      }
      .el-button{
          margin-left: 15px;
      }
    }
  }

  .container{
      padding: 20px;
  }
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }

.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}
.el-col-24{
    height: 52px;
   margin-top: 20px;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
