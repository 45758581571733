<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="场地名称" prop="siteName" >
          <el-input readonly  v-model="siteInfo.siteName" disabled>
          </el-input>
        </el-form-item>
        <el-form-item label="系统" prop="headquarters">
          <el-input  placeholder="百分比" type='number' v-model="form.headquarters"   class="input-with-select" :disabled="userInfo.roleId == 1 ? false : true">
            <span slot="prepend">系统</span>
            <span slot="append" >%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="代理" prop="agentProportion"  v-if="siteInfo.agentId">
          <el-input placeholder="百分比" type='number' v-model="form.agentProportion" class="input-with-select">
            <span slot="prepend">{{row.agentId ? row.agentName : '未绑定'}}</span>
            <span slot="append">%</span>
          </el-input>
        </el-form-item>
        <!-- 分润 -->
        <el-form-item label="商户" prop="manageProportion" v-if="siteInfo.managerId">
          <el-input placeholder="百分比" type='number' v-model="form.manageProportion" class="input-with-select">
            <span slot="prepend">{{row.managerId ? row.managerName : '未绑定'}}</span>
            <span slot="append" >%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="供应商" prop="supplierProportion" v-if="siteInfo.deviceSupplierId">
          <el-input placeholder="百分比" type='number' v-model="form.supplierProportion" class="input-with-select"  :disabled="userInfo.roleId == 1 ? false : true">
            <span slot="prepend">{{row.deviceSupplierId ? row.deviceSupplierName : '未绑定'}}</span>
            <span slot="append" >%</span>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="add('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {setProfitInfo} from '@/api/operateCenter.js';
  export default {
    props:{
      row:{
        type:Object,
      }
    },
    data() {
      return {
        userInfo:JSON.parse(sessionStorage.getItem('userInfo')) || undefined, // 登录账号信息
        form:{ // 表单数据
            id:null,
            headquarters:0,
            agentProportion:0,
            manageProportion:0,
            supplierProportion:0,
        },
        siteInfo:{
          siteName:'',
        }, // 场所信息
        rules: { // 表单验证
          headquarters: [
            { required: true, message: "系统分润比例不得为空", trigger: "blur" },
          ],
          manageProportion: [
            { required: true, message: "商户分润比例不得为空", trigger: "blur" },
          ],
          agentProportion: [
            { required: true, message: "代理分润比例不得为空", trigger: "blur" },
          ],
          supplierProportion: [
            { required: true, message: "供应商分润比例不得为空", trigger: "blur" },
          ],
        },
        userInfo:JSON.parse(sessionStorage.getItem('userInfo'))
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
        this.siteInfo={...this.row};
        this.form.id=this.row.id;
        if(this.siteInfo.ledgerRatio && this.siteInfo.ledgerRatio != '--'){
          this.siteInfo.ledgerRatio = JSON.parse(this.siteInfo.ledgerRatio);
          this.siteInfo.ledgerRatio.forEach(element=>{
            if(element.Profitid == 0){
              this.form.headquarters = element.ProfitRatio;
            }else if( this.siteInfo.agentId && element.Profitid == this.siteInfo.agentId){
              this.form.agentProportion = element.ProfitRatio;
            }else if( this.siteInfo.managerId && element.Profitid == this.siteInfo.managerId){
              this.form.manageProportion = element.ProfitRatio;
            }else if(this.siteInfo.deviceSupplierId && element.Profitid == this.siteInfo.deviceSupplierId){
              this.form.supplierProportion = element.ProfitRatio;
            }
          })
        }
        _this=this;
    },
  
    methods: {
      close(){
         _this.$emit('close');
      },
      // 添加
      add(form) {
        console.log(this.form,this.siteInfo,998)
         _this.$refs["form"].validate(valid => {
            if (valid) {
                let rule=[
                  {"ProfitRatio":Number(this.form.headquarters),"Profitid":0}
                ];
                let total = Number(this.form.headquarters);
                if(this.siteInfo.agentId){
                  total += Number(this.form.agentProportion);
                  rule.push({"ProfitRatio":Number(this.form.agentProportion),"Profitid":this.siteInfo.agentId})
                }
                if(this.siteInfo.managerId){
                  total += Number(this.form.manageProportion);
                  rule.push({"ProfitRatio":Number(this.form.manageProportion),"Profitid":this.siteInfo.managerId})
                }
                if(this.siteInfo.deviceSupplierId){
                  total += Number(this.form.supplierProportion);
                  rule.push({"ProfitRatio":Number(this.form.supplierProportion),"Profitid":this.siteInfo.deviceSupplierId})
                }
                if( total == 100 ){
                   let data = {
                    id:this.row.id,
                    LedgerRatio:JSON.stringify(rule)
                   }
                    setProfitInfo(data).then((res) => {
                      if (res.success) {
                        _this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        setTimeout(() => {
                          _this.$emit('close');
                        }, 1000);
                      }else{
                        _this.$message({
                          message: "提交失败，请重试",
                          type: "fail",
                        });
                      }
                    })
                    .catch(error => {});
                }else{
                    _this.$message({
                      message: "比例总和必须为100%",
                      type: "fail",
                    });
                }
            } else {
              return false;
            }
        });
      },
      
    }
  };
  </script>
  <style lang="scss" scoped>
  /deep/.el-form-item__label{
    width: 120px !important;
  }
  .el-form-item{
    display: flex !important;
  }
  /deep/ .el-form-item__content{
    margin-left: 0 !important;
    flex: 1;
  }
   .el-drawer{
     overflow:auto !important;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  