<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="可提现金额">
        <b>{{ row.outTakingAmount }}元</b>
      </el-form-item>
      <el-form-item label="提现类型" prop="tskingMold">
        <el-radio-group v-model="form.tskingMold" @change="chooseType">
          <el-radio-button :label="1">无票提现</el-radio-button>
          <el-radio-button :label="2">发票提现</el-radio-button>
        </el-radio-group>
        <p class="remind">（无票不限制使用银行卡，发票提现只允许使用银行卡，无票提现需扣除6%的手续费，发票提现只允许提交电子发票）</p>
      </el-form-item>
      <el-form-item label="提现账号" prop="takingNumId" >
        <el-select v-model="form.takingNumId" placeholder="请选择" >
          <el-option
            v-for="item in takingNumOptions"
            :key="item.id"
            :label="item.takingNum"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提现金额" prop="takingAmount">
        <el-input placeholder="请输入提现金额" type="Number" v-model="form.takingAmount"></el-input>
      </el-form-item>
      <el-form-item label="开票抬头" prop="lookUp" v-if="form.tskingMold == 2">
        <span>{{ form.lookUp }}</span>
      </el-form-item>
      <el-form-item label="税号" prop="dutyParagraph"  v-if="form.tskingMold == 2">
        <span>{{ form.dutyParagraph }}</span>
      </el-form-item>
      <el-form-item label="发票" prop="invoiceUrl" v-if="form.tskingMold == 2">
        <el-upload
          action="https://www.quantoukeji.com/api/UploadImage/FileUp"
          :headers='headers'
          :show-file-list="true"
          :on-success="handleSuccess"
          >
          <img v-if="form.invoiceUrl" :src="form.invoiceUrl">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { addTakingAmountInfo,editTakingNumInfo,getUserTakingNum,getImgUrl,getEnableInvoice } from '@/api/operateCenter.js';
let token=sessionStorage.getItem('token');
export default {
  props:{
    row:{type:Object},
  },
  data() {
    let checkAmount = (rule,value,callback) => {
      if(value > this.row.outTakingAmount){
        return callback(new Error('提现金额不可超过可提现金额'));
      }else{
        callback();
      }
    }
    return {
      headers:{authorization:token},
      userInfo:JSON.parse(sessionStorage.getItem('userInfo')) || undefined,
      form:{ // 表单
        takingNumId:"",
        takingAmount:0,
        invoiceUrl:"",
        tskingMold:1,
      },
      takingNumOptions:[], // 提现账号选项列表
      rules: { // 表单校验
        takingNumId: [
          { required: true, message: "提现账号不得为空", trigger: "change" },
        ],
        takingAmount: [
          { required: true, message: "金额金额不得为空", trigger: "blur" },
          { validator: checkAmount, trigger: 'blur' }
        ],
        invoiceUrl:[
          { required: true, message: "发票不得为空", trigger: "change" },
        ]
      },
      fileType:null, // 上传的文件类型
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getDictionary(0);
  },

  methods: {
    // 获取本用户的体现账号列表
    async getDictionary(type){
      await getUserTakingNum({data:type}).then(res=>{
        if(res.success){
          this.takingNumOptions = res.data;
        }else{
          this.takingNumOptions = [];
        }
      })
      await getEnableInvoice().then(res=>{
        if(res.success){
          this.form.lookUp = res.data[0].lookUp;
          this.form.dutyParagraph = res.data[0].dutyParagraph;
        }
      })
    },
    chooseType(e){
      console.log(e,99878);
      let type = e == 1 ? 0 : 1;
      this.getDictionary(type);
    },
    // 关闭弹框
    close(){
      this.$emit('close');
    },
    // 选取图片成功
    handleSuccess(e){
      this.form.invoiceUrl = getImgUrl() + e.data;
      let ind = e.data.indexOf('.');
      this.fileType = e.data.slice(ind+1);
    },
    // 新增
    async add(){
      await addTakingAmountInfo(this.form).then(res=>{
        if(res.success){
          this.$message({
            message:'提交成功',
            type:'success'
          })
          this.$nextTick(()=>{
            this.$emit('close');
          })
        }else{
          this.$message({
            message:'提交失败，请重试',
            type:'fail'
          })
        }
      })
    },
    //  提交
    submit(form) {
       this.$refs["form"].validate(valid => {
          if (valid) {
            this.$confirm('确认提交吗？').then( () => {
              this.add();
            })
          } else {
            return false;
          }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.remind{
  color: red;
  font-size: 14px;
  line-height: 25px;
  margin-top: 5px;
}
 .el-drawer{
   overflow:auto !important;
 }
 .el-select{
   width: 100%;
 }

  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  img{
    width: 178px;
    height: 178px;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  /deep/ .el-form-item {
    display: flex;
  }
  /deep/ .el-form-item__label{
    width: 150px !important;
  }
  /deep/ .el-form-item__content{
    margin-left: 0 !important;
    flex: 1 !important;
  }
</style>
