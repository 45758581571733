<template>
  <section >
    <div class="search">
      <div class="inputs">
          <p class="each">
              <span style="width:160px">支付宝昵称：</span>
              <el-input class="wxName" v-model="searchInfo.data.AliUserName" placeholder="请输入支付宝昵称"></el-input>
          </p>
          <p class="each">
              <span style="width:160px">后台关联用户：</span>
              <el-input class="wxName" v-model="searchInfo.data.UserName" placeholder="请输入后台关联用户"></el-input>
          </p>
          <p class="each">
              <span>角色权限：</span>
              <el-select class='select' v-model="Role" clearable placeholder="请选择" @change="selectStatus" >
                <el-option
                  v-for="item in RoleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </p>
         <el-button type="primary" @click="toSearch">搜索</el-button>
         <el-button type="primary" @click="backAll">清空搜索条件</el-button>
      </div>
    </div>
    <div class="container">
      <el-table
        :data="placeList"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="支付宝头像"
          prop="aliUserImg"
          align='center'>
          <template slot-scope="scope">
            <el-avatar shape="circle" :size="50" fit="cover" :src="scope.row.aliUserImg"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
          label="支付宝昵称"
          prop="aliUserName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="手机号码"
          prop="phone"
          align='center'>
        </el-table-column>
         <el-table-column
          label="角色"
          prop="role"
          align='center'>
          <template slot-scope="scope">
             <p>{{scope.row.role===0?'用户':(scope.row.role==1?'管理员':(scope.row.role==2?'代理':(scope.row.role==3?'店主':'补货员')))}}</p>
          </template>
        </el-table-column>
         <el-table-column
          label="关联后台用户名称"
          prop="userName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="注册时间"
          prop="createTime"
          align='center'>
        </el-table-column>
        <el-table-column
         label="操作"
         fixed="right"
         align='center'>
          <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">设置角色</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page="searchInfo.pageIndex"
          :page-size="10"
          :total="total"
          background
          style="float:right;"
        ></el-pagination>
      </el-col>
      <el-drawer
       title="设置角色"
       :visible.sync="editDrawer"
       :direction="direction"
       :before-close="handleClose">
        <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {getAliUserInfo} from '@/api/operateCenter.js';
import edit from './edit.vue';
export default {
  data() {
    return {
      // 订单列表信息
      placeList:[],
      // 分页样式
      total:0,
      searchInfo:{ // 查询条件
        pageIndex:1,
        pageSize:10,
        data:{
          Role:-1,
          UserName:'',
          AliUserName:'',
        },
      },
      RoleOptions:[
        {value:0,label:'用户'},
        {value:1,label:'管理员'},
        {value:2,label:'代理'},
        {value:3,label:'店主'},
        {value:4,label:'补货员'},
      ],
      Role:'',
      editDrawer:false,
      id:'',
      row:'',
      direction:'rtl',
      userInfo:JSON.parse(sessionStorage.getItem('userInfo')),
    }
  },

  components: {edit},

  computed: {},

  mounted() {
    this.init();
    if(this.userInfo.roleId == 2){
      this.RoleOptions=[
        {value:0,label:'用户'},
        {value:3,label:'商户'},
        {value:4,label:'补货员'},
      ]
    }else if(this.userInfo.roleId==3){
      this.RoleOptions=[
        {value:0,label:'用户'},
        {value:4,label:'补货员'},
      ]
    }
  },

  methods: {
    selectStatus(e){},
    // 初始化
    async init(){
      await getAliUserInfo(this.searchInfo).then(res=>{
        if(res.success){
          this.placeList=res.data.data;
          this.placeList.forEach(element => {
            console.log(element.role===0)
            for(let key in element){
              element[key]=element[key] || element[key]==0?element[key]:'--'
            }
          });
          this.total=res.data.totalCount;
        }
      })
    },
     // 删除用户
    handleDel(index,row){
      this.$confirm('确认删除该场地吗')
       .then(async ()=>{
          await delSiteInfo({Id:row.id}).then(res=>{
            if(res.success){
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              });
              this.init();
            }
          })
       })
       .catch(()=>{})
    },
     // 打开新增
    handleAdd(index,row){
      this.addDrawer=true;
    },
    // 打开修改
    handleEdit(index,row){
      this.editDrawer=true;
      this.id=index;
      this.row=row;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 新增、绑定、编辑成功
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.editDrawer=false;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 条件查询
    toSearch(){
      this.searchInfo.data.Role=this.Role !==''  ? this.Role : -1; 
      this.searchInfo.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex=1;
      this.searchInfo.data={
        Role:-1,
        AliUserName:'',
        UserName:'',
      },
      this.Role='';
      this.init();
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    prevpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
    nextpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>

 .search{
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  background: #fff;
  padding: 20px;
    .inputs{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .each{
          width:calc((100% - 40px ) / 4) ;
          height: 50px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          .el-select{
           width: 100% !important;
          }
          span{
              width: 100px;
          }
      }
      .el-button{
          margin-left: 15px;
      }
    }
  }

  .container{
      padding: 20px;
  }
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }

.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}
.el-col-24{
    height: 52px;
   margin-top: 20px;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
