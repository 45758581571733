<template>
  <section >
    <!-- 搜索模块 -->
    <div class="search">
      <div class="inputs">
          <p class="each">
              <span>设备编号：</span>
              <el-input class="wxName" v-model="searchInfo.data.equipmentNum" placeholder="请输入设备编号"></el-input>
          </p>
          <p class="each">
              <span>所属场地：</span>
              <el-select class='select' v-model="siteName" clearable filterable placeholder="请选择">
                <el-option
                  v-for="item in siteOptions "
                  :key="item.id"
                  :label="item.siteName"
                  :value="item.id">
                </el-option>
              </el-select>
          </p>
          <p class="each">
              <span>是否在线：</span>
              <el-select class='select' v-model="IfOnline" clearable placeholder="请选择">
                <el-option
                  v-for="item in onlineOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </p>
          <div class="searchBtns">
            <el-button type="primary" @click="toSearch">搜索</el-button>
            <el-button type="primary" @click="backAll">清空搜索条件</el-button>
            <el-button @click="equipInstall(null)" v-if="userInfo.roleId == 1 || userInfo.roleId == 2">设备注册</el-button>
          </div>
      </div>
    </div>
    <!-- 表格模块 -->
    <div class="container">
      <el-table
        :data="equipList"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="ID"
          prop="id"
          show-overflow-tooltip
          width="80"
          align='center'>
        </el-table-column>
        <el-table-column
          label="设备编号"
          prop="equipmentNum"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
        <el-table-column
          label="插口数量"
          prop="hatchNum"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
        <el-table-column
          label="充电宝库存"
          prop="goodsNum"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
        <el-table-column
          label="可用数量"
          prop="availableNum"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
        <el-table-column
          label="安装场地"
          prop="siteName"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
        <el-table-column
          label="场地地址"
          prop="address"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
        <el-table-column
          label="所属代理"
          prop="agentNmae"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
        <el-table-column
          label="所属商户"
          prop="managerName"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
        <el-table-column
          label="是否在线"
          prop="ifOnline"
          show-overflow-tooltip
          align='center'>
          <template slot-scope="scope">
            <p>{{scope.row.ifOnline == 1 ? '在线' : (scope.row.ifOnline == 2 ? '掉线' : '未联网')}}</p>
          </template>
        </el-table-column>
        <el-table-column
         label="操作"
         fixed="right"
         align='center'
         width="250">
          <template slot-scope="scope">
              <el-button size="mini" @click="handleCheckGoods(scope.$index, scope.row)">库存充电宝</el-button>
              <el-button size="mini" @click="checkOrder(scope.$index, scope.row)" v-if="userInfo.roleId == 1 || userInfo.roleId == 2">查看订单</el-button>
              <el-button size="mini" @click="checkQrcode(scope.row)">查看二维码</el-button>
              <el-button size="mini" @click="equipInstall(scope.row)"  v-if="userInfo.roleId == 1 || userInfo.roleId == 2">设备安装</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar">
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          background
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
    <el-drawer
      title="设备安装"
      :visible.sync="installDrawer"
      :before-close="handleClose">
      <install v-if="installDrawer" @close='handleClose'  ref='newForm' :row="row"></install>
    </el-drawer>
    <!-- 二维码弹出框 -->
    <div class="cover" v-if="showCover">
      <div class="inside">
        <i class="el-icon-close" @click="closeCover"></i>
        <div id="qrCode">
          <vue-qr  :text="qrCode" :size="200"></vue-qr>
        </div>
        <p>{{qrCode}}</p>
        <el-button @click="downLoadQrcode(qrCode)">下载二维码</el-button>
      </div>
    </div>
  </section>
</template>

<script>
import {getEquipmentInfo,GetSubSite,GetCodeUrl} from '@/api/operateCenter.js';
import vueQr from 'vue-qr';
import install from './install.vue';
export default { 
  data() {
    return {
      imageUrl: require("@/static/img/qrCode.png"),
      equipList:[], // 设备列表信息
      total:0, // 数据总条数
      currentPage:1, // 当前所在页码
      statusStyle:'', // 状态样式
      searchInfo:{ // 查询条件
        pageIndex:1,
        pageSize:10,
        data:{
          IfOnline:-1, // 设备是否在线
          siteId:-1, // 所属场地id
          equipmentNum:'', // 设备编号
        },
      },
      IfOnline:'', // 是否在线
      siteName:'', // 场地名称
      onlineOptions:[ // 设备是否在线选项
        {value:0,label:'未联网'},
        {value:1,label:'在线'},
        {value:2,label:'掉线'},
      ],
      siteOptions:[], // 安装场所选项
      qrCode:'', // 当前选中显示的二维码文本信息
      showCover:false, // 是否显示二维码弹出框
      row:null,
      installDrawer:false,
      userInfo:JSON.parse(sessionStorage.getItem('userInfo')) || undefined,
    }
  },

  components: {vueQr,install},

  computed: {},

  mounted() {
    this.init();
    this.getSites();
  },

  methods: {
    // 获取设备列表
    async init(){
      await getEquipmentInfo(this.searchInfo).then(res=>{
        if(res.success){
          this.equipList=res.data.data;
          this.equipList.forEach(element => {
            for(let key in element){
              element[key]=element[key] || element[key]==0?element[key]:'--';
            }
          });
          this.total=res.data.totalCount;
        }
      })
    },
    // 获取设备安装场地下拉数据
    async getSites(){
      await GetSubSite().then(res=>{
        if(res.success){
          this.siteOptions=res.data;
        }
      })
    },
    // 查看二维码
    async checkQrcode(row){
      await GetCodeUrl({}).then(res=>{
        if(res.success){
          this.qrCode = `${res.data}${row.equipmentNum}`;
          this.showCover = true;
        }else{
          this.$message({
            message:'操作失败',
            type:'fail'
          })
        }
      })
    },
    // 关闭二维码
    closeCover(){
      this.showCover=false;
    },
    // 下载二维码
    downLoadQrcode(qrCode) {
        var canvasData = document.getElementById('qrCode').getElementsByTagName('img');
        var a = document.createElement('a');
        a.href = canvasData[0].src;
        a.download = qrCode+'.png';
        a.click();
    },
    // 条件查询
    toSearch(){
      this.searchInfo.data.IfOnline = this.IfOnline !==''  ? this.IfOnline : -1;
      this.searchInfo.data.siteId = this.siteName !== '' ? this.siteName : -1;
      this.searchInfo.pageIndex=1;
      this.currentPage = 1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex=1;
      this.currentPage = 1;
      this.searchInfo.data={
        IfOnline:-1, // 设备是否在线
        siteId:-1, // 所属场地id
        equipmentNum:'', // 设备编号
      };
      this.IfOnline='';
      this.siteName = '';
      this.init();
    },
    // 前往查看售卖商品
    handleCheckGoods(index,row){
      this.$router.push({
        path:'/equipManage/cdbList',
        query:{equipmentNum:row.equipmentNum,type:'normal'}
      })
    },
    // 前往查看订单
    checkOrder(index,row){
      this.$router.push({
        path:'/trading/orderList',
        query:{equipmentNum:row.equipmentNum,type:'normal'}
      })
    },
    // 设备安装
    equipInstall(row){
      this.row = row;
      this.installDrawer = true;
    },
    handleClose(){
      this.installDrawer = false;
      this.init();
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.currentPage = val;
      this.init();
    },
    prevpage(e){
      this.searchInfo.pageIndex=e;
      this.currentPage = val;
      this.init();
    },
    nextpage(e){
      this.currentPage = val;
      this.searchInfo.pageIndex=e;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
.cover{
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  .inside{
    border-radius: 10px;
    background: #fff;
    position: relative;
    width: 300px;
    height: 450px;
    p{
      margin-bottom: 30px;
      line-height: 25px;
      height: auto;
    }
    .el-icon-close{
      display: block;
      text-align: right;
      font-size: 25px;
      padding: 20px;
      cursor: pointer;
    }
  }
}
 .search{
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  background: #fff;
  padding: 20px;
    .inputs{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .each{
          width:calc((100% - 40px ) / 4) ;
          height: 50px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          .el-select{
           width: 100%;
          }
          span{
              width: 100px;
          }
      }
      .el-button{
          margin-left: 15px;
          float: right;
      }
      .searchBtns{
        padding: 20px 0 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .container{
      padding: 20px;
  }
  // 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }

.el-table >>> .cell {
  white-space: pre-line;
}
/deep/ .el-drawer__body{
    overflow: auto !important;
  }
.el-col-24{
  height: 52px;
  margin-top: 20px;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
