<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="模板名称" prop="templateName" >
          <el-input placeholder="请输入模板名称" v-model="form.templateName">
          </el-input>
        </el-form-item>
        <el-form-item label="免费时间（分钟）" prop="freeTime" >
          <el-input placeholder="请输入免费时间" v-model="form.freeTime">
          </el-input>
        </el-form-item>
        <el-form-item label="初始时间（分钟）" prop="frontTime" >
          <el-input placeholder="请输入初始时间" v-model="form.frontTime">
          </el-input>
        </el-form-item>
        <el-form-item label="初始时间单价（元）" prop="frontAmount" >
          <el-input placeholder="请输入初始时间单价" v-model="form.frontAmount">
          </el-input>
        </el-form-item>
        <el-form-item label="单位时间（分钟）" prop="everyTime" >
          <el-input placeholder="请输入单位时间" v-model="form.everyTime">
          </el-input>
        </el-form-item>
        <el-form-item label="单位时间单价（元）" prop="everyAmount" >
          <el-input placeholder="请输入单位时间单价" v-model="form.everyAmount">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="add('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {editChargingTemplateInfo} from '@/api/operateCenter.js';
  export default {
    props:{
        row:{type:Object},
    },
    data() {
      return {
        form:{
            templateName:'',
            freeTime:'',
            frontTime:'',
            frontAmount:'',
            everyTime:'',
            everyAmount:'',
        },
        rules: {
          templateName: [
            { required: true, message: "模板名称不得为空", trigger: "blur" },
          ],
          freeTime: [
            { required: true, message: "免费时间不得为空", trigger: "blur" },
          ],
          frontTime:[
            { required: true, message: "初始时间不得为空", trigger: "change" },
          ],
          frontAmount: [
            { required: true, message: "初始时间单价不得为空", trigger: "blur" },
          ],
          everyTime:[
            { required: true, message: "单位时间不得为空", trigger: "blur" },
          ],
          everyAmount:[
            { required: true, message: "单位时间单价不得为空", trigger: "blur" },
          ]
        },
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      _this=this;
      this.form = {...row};
    },
  
    methods: {
      close(){
         _this.$emit('close');
      },
       //  添加
      add(form) {
         _this.$refs["form"].validate(valid => {
            if (valid) {
              editChargingTemplateInfo(_this.form)
                .then((res) => {
                  if (res.success) {
                    _this.$message({
                      message: "提交成功",
                      type: "success",
                    });
                    setTimeout(() => {
                      this.form={};
                      _this.$emit("success");
                      _this.$emit('close');
                      _this.init();
                    }, 1000);
                  }else{
                    _this.$message({
                      message: "提交失败，请重试",
                      type: "fail",
                    });
                  }
                })
                .catch(error => {});
            } else {
              return false;
            }
        });
      },
      
    }
  };
  </script>
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .el-select{
     width: 100%;
   }
  //  el-select-dropdown__wrap el-scrollbar__wrap
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  