<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="角色权限" prop="role" >
        <el-select v-model="role" placeholder="请选择" @change="chooseRole">
        <el-option
          v-for="item in RoleOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="后台关联用户" prop="adminUserId" >
        <el-select v-model="adminUserId" placeholder="请选择" :no-data-text="empty"  @change="chooseAdmin">
        <el-option
          v-for="item in AdminUserOptions"
          :key="item.id"
          :label="item.userName"
          :value="item.id">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="add('form')" :plain='true'>立即设置</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {setWeChatUserRole,getUserList} from '@/api/operateCenter.js';
export default {
  props:{
    row:{
      type:Object,
    }
  },
  data() {
    return {
      form:{},
      RoleOptions:[
        {value:0,label:'用户'},
        {value:1,label:'管理员'},
        {value:2,label:'代理'},
        {value:3,label:'商户'},
        {value:4,label:'补货员'},
      ],
      AdminUserOptions:[],
      role:'',
      roleId:'',
      empty:'请先选择角色',
      adminUserId:'',
      show:true,
      rules: {
        role: [
          { required: true, message: "角色权限不得为空", trigger: "blur" },
        ],
        adminUserId: [
          { required: true, message: "后台关联用户不得为空", trigger: "blur" },
        ],
      },
      userInfo:JSON.parse(sessionStorage.getItem('userInfo')),
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.form=this.row;
    _this=this;
    _this.init();
    if(this.userInfo.roleId == 2){
      this.RoleOptions=[
        {value:0,label:'用户'},
        {value:3,label:'商户'},
        {value:4,label:'补货员'},
      ]
    }else if(this.userInfo.roleId==3){
      this.RoleOptions=[
        {value:0,label:'用户'},
        {value:4,label:'补货员'},
      ]
    }
  },

  methods: {
    init(){
      getUserList({RoleId:this.roleId}).then(res=>{
        if(res.success){
          this.AdminUserOptions=res.data;
        }else{
          this.AdminUserOptions=[];
        }
      })
    },
    close(){
       _this.$emit('close');
    },
    chooseRole(e){
      this.roleId=e;
      this.form.Role=e;
      this.init();
      if(this.role!='' && this.AdminUserOptions==[]){
        this.empty='该角色下暂无用户'
      }else if(this.role==''){
        this.empty='请先选择角色';
      }else{
        this.empty='该角色下暂无用户';
      }
    },
    chooseAdmin(e){
      this.form.adminUserId=e;
    },
    //  添加
    add(form) {
       _this.$refs["form"].validate(valid => {
          if (valid) {
            setWeChatUserRole(_this.form)
              .then((res) => {
                if (res.success) {
                  _this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    this.form={};
                    _this.$emit("success");
                    _this.$emit('close');
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: "修改失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.el-form-item{
    display: flex;
    /deep/.el-form-item__label{
      width: 120px !important;
    }
    /deep/ .el-form-item__content{
      margin-left: 10px !important; 
      flex: 1;
    }
  }
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
