<template>
  <section >
    <!-- 当前账户分润信息 -->
    <div class="profitContainer">
      <p>
        <span>合计分润</span>
        <b>{{ UserProfitData.sumProfitAmount }}元</b>
      </p>
      <p>
        <span>已提现金额</span>
        <b>{{ UserProfitData.sumPayTakingAmount }}元</b>
      </p>
      <p>
        <span>提现中金额（待打款）</span>
        <b>{{ UserProfitData.sumTakingAmount }}元</b>
      </p>
      <p>
        <span>可提现金额</span>
        <b>{{ UserProfitData.outTakingAmount }}元</b>
      </p>
      <el-button size="mini" type="primary" @click="openDrawer" v-if="UserProfitData.outTakingAmount > 0">提现</el-button>
    </div>
    <div class="container">
      <el-table
        :data="tableList"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="提现编号"
          prop="takingNum"
          align='center'>
        </el-table-column>
        <el-table-column
          label="提现用户"
          prop="takingName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="提现类型"
          prop="tskingMold"
          align='center'>
          <template slot-scope="scope">
            {{ scope.row.tskingMold == 1 ? '无票提现' : (scope.row.tskingMold == 2 ? '发票提现' : '--') }}
          </template>
        </el-table-column>
        <el-table-column
          label="提现金额(元)"
          prop="takingAmount"
          align='center'>
        </el-table-column>
        <el-table-column
          label="提现手续费(元)"
          prop="commission"
          align='center'>
        </el-table-column>
        <el-table-column
          label="发票"
          prop="invoiceUrl"
          align='center'>
          <template slot-scope="scope">
            <el-button v-if="scope.row.invoiceUrl && scope.row.invoiceUrl != '--' && scope.row.tskingMold == 2" size="mini" @click="downLoad(scope.row.invoiceUrl,'发票','png')">下载</el-button>
            <p v-else>--</p>
          </template>
        </el-table-column>
        <el-table-column
          label="账号类型"
          prop="takingNumTypeStr"
          align='center'>
        </el-table-column>
        <el-table-column
          label="提现账号"
          prop="takingNumType"
          align='center'>
          <template slot-scope="scope">
            <p v-if="scope.row.takingNumType == 1">银行卡号：{{scope.row.bankCardNumber ? scope.row.bankCardNumber : '--'}}</p>
            <p v-if="scope.row.takingNumType == 1">开户行：{{scope.row.bank ? scope.row.bank : '--'}}</p>
            <p v-if="scope.row.takingNumType == 1">户主：{{scope.row.householder ? scope.row.householder : '--'}}</p>
            <p v-if="scope.row.takingNumType == 2">{{scope.row.weChatAccount ? scope.row.weChatAccount : '--'}}</p>
            <p v-if="scope.row.takingNumType == 3">{{scope.row.aliAccount ? scope.row.aliAccount : '--'}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="提现进度"
          prop="takingType"
          align='center'>
          <template slot-scope="scope">
            {{ scope.row.takingType == 0 ? '待审核' : ( scope.row.takingType == 1 ? "已审核待打款" : (scope.row.takingType == 2 ? "已打款" : "异常")) }}
          </template>
        </el-table-column>
        <el-table-column
          label="发起时间"
          prop="createTime"
          align='center'>
        </el-table-column>
        <el-table-column
          label="审核时间"
          prop="examineTime"
          align='center'>
        </el-table-column>
        <el-table-column
          label="打款时间"
          prop="paymentTime"
          align='center'>
        </el-table-column>
        <el-table-column
          label="打款截图"
          prop="paymentImg"
          align='center'>
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.takingType == 2"
              style="width: 50px; height: 50px"
              :src="scope.row.paymentImg" 
              title='点击放大图片'
              :preview-src-list="[scope.row.paymentImg]">
            </el-image>
            <p v-else>--</p>
          </template>
        </el-table-column>
        <el-table-column
         label="操作"
         fixed="right"
         align='center'
         width="100">
          <template slot-scope="scope">
              <el-button size="mini" @click="examine(scope.row)" v-if="scope.row.takingType == 0">审核</el-button>
              <el-button size="mini" @click="remittance(scope.row)" v-if="scope.row.takingType == 1">打款</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page="searchInfo.pageIndex"
          :page-size="10"
          :total="total"
          background
          style="float:right;"
        ></el-pagination>
      </el-col>
      <!-- 新增 -->
      <el-drawer
       title="提现"
       :visible.sync="addDrawer"
       :direction="direction"
       :before-close="handleClose"
       size="40%">
        <add v-if="addDrawer" @close='handleClose'  ref='newForm' :row="row"></add>
      </el-drawer>
      <!-- 打款 -->
      <el-drawer
       title="打款"
       :visible.sync="remittanceDrawer"
       :before-close="handleClose"
       size="40%">
        <remittance v-if="remittanceDrawer" @close='handleClose'  ref='newForm' :row="row"></remittance>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {getTakingAmountInfo,PayTakingAmount,GetTakingSumData} from '@/api/operateCenter.js';
import { selectDictionary } from '@/api/public.js';
import add from './add.vue';
import remittance from './remittance.vue';
export default {
  data() {
    return {
      tableList:[], // 订单列表信息
      total:0, // 数据总条数
      searchInfo:{ // 查询条件
        pageIndex:1,
        pageSize:10,
      },
      row:null, // 传递给弹窗子组件的数据
      direction:'rtl', // 弹窗弹出方向
      addDrawer:false, // 控制弹框是否显示
      remittanceDrawer:false,
      takingNumTypeOptions:[], // 账户类型列表
      UserProfitData:{ // 当前账户分润统计信息
        sumProfitAmount:0,
        sumPayTakingAmount:0,
        sumTakingAmount:0,
        outTakingAmount:0,
      },
    }
  },

  components: {add,remittance},

  computed: {},

  mounted() {
    this.getUserProfitData();
    this.getDictionary();
  },

  methods: {
    // 获取订单列表信息
    async init(){
      await getTakingAmountInfo(this.searchInfo).then(res=>{
        if(res.success){
          this.tableList=res.data.data;
          this.tableList.forEach(element => {
            for(let i=0;i<this.takingNumTypeOptions.length;i++){
              if(element.takingNumType == this.takingNumTypeOptions[i].code){
                element.takingNumTypeStr = this.takingNumTypeOptions[i].name;
                break;
              }
            }
            for(let key in element){
              element[key] = (element[key] || element[key] == 0) && element[key] !== '' ? element[key] : '--';
            }
          });
          console.log("" == 0,9879)
          this.total=res.data.totalCount;
        }
      })
    },
    // 获取账户类型所有字典值
    async getDictionary(){
      await selectDictionary({sign:'TakingNumType'}).then(res=>{
        if(res.success){
          this.takingNumTypeOptions = res.data;
        }else{
          this.takingNumTypeOptions = [];
        }
      })
      this.$nextTick(()=>{
        this.init();
      })
    },
    // 获取当前登录账户分润信息
    async getUserProfitData(){
      await GetTakingSumData().then(res=>{
        if(res.success){
          this.UserProfitData = res.data;
        }else{
          this.UserProfitData = {
            sumProfitAmount:0,
            sumPayTakingAmount:0,
            sumTakingAmount:0,
            outTakingAmount:0,
          };
        }
      })
    },
    // 下载发票信息
    downLoad(imgsrc){
      let fileName = imgsrc.slice(imgsrc.lastIndexOf('/') + 1); // 这里是通过从后端获取到的 imgsrc  地址中截出来原本的文件名
      fetch(imgsrc)
        .then((res) => res.blob())
        .then((blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          // 下载文件的名称及文件类型后缀
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          //在资源下载完成后 清除 占用的缓存资源
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        });
    },
    // 审核
    examine(row){
      this.row = row;
      this.remittanceDrawer = true;
    },
    // 打款
    remittance(row){
      this.row = row;
      this.remittanceDrawer = true;
      // this.$confirm('确认打款吗？').then( async () => {
      //   await PayTakingAmount({Id:row.id}).then(res=>{
      //     if(res.success){
      //       this.$message({
      //         message:'操作成功',
      //         type:'success'
      //       })
      //       this.init();
      //     }else{
      //       this.$message({
      //         message:'操作失败，请重试',
      //         type:'fail'
      //       })
      //     }
      //   })
      // })
    },
    // 打开弹框
    openDrawer(){
      this.row = this.UserProfitData;
      this.addDrawer = true;
    },
    // 关闭
    handleClose(){
      this.addDrawer = false;
      this.remittanceDrawer = false;
      this.init();
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
// 新增
.profitContainer{
  display: flex;
  justify-content: space-around;
  padding: 20px;
  align-content: center;
  p{
    display: flex;
    flex-direction: column;
    b{
      font-size: 18px;
    }
  }
}
  // 搜索栏
 .search{
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  background: #fff;
  padding: 20px;
    .inputs{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .each{
          width:calc((100% - 40px ) / 4) ;
          height: 50px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          .el-select{
           width: 100% !important;
          }
          span{
              width: 100px;
          }
      }
      .el-button{
          margin-left: 15px;
      }
    }
  }

  .container{
      padding: 20px;
  }
  // 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }

.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}
.el-col-24{
    height: 52px;
   margin-top: 20px;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
