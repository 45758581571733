import axios, {ResponseData} from './base.ts';
import {AxiosPromise} from 'axios';


// 图片基本路径
export function getImgUrl(data = {}) {
  return 'https://www.quantoukeji.com'
}

// 查询计费模板
export function getChargingTemplateInfo(data = {}) {
  return axios.request({
    url: `ChargingTemplateInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 下拉数据查询
export function getListChargingTemplateInfo(data = {}) {
  return axios.request({
    url: `ChargingTemplateInfo/GetList`,
    method: "post",
    data: data,
  })
}
// 新增计费模板
export function addChargingTemplateInfo(data = {}) {
  return axios.request({
    url: `ChargingTemplateInfo/Insert`,
    method: "post",
    data: data,
  })
}
// 修改计费模板
export function editChargingTemplateInfo(data = {}) {
  return axios.request({
    url: `ChargingTemplateInfo/Update`,
    method: "post",
    data: data,
  })
}
// 删除计费模板
export function delChargingTemplateInfo(data = {}) {
  return axios.request({
    url: `ChargingTemplateInfo/Delete`,
    method: "post",
    data: data,
  })
}

// 用户列表查询
export function getUserInfo(data = {}) {
    return axios.request({
      url: `UserInfo/GetData`,
      method: "post",
      data: data,
    })
}
// 获取角色列表 
export function getQueryRolesList(data = {}) {
    return axios.request({
      url: `RoleInfo/QueryRolesList`,
      method: "post",
      data: data,
    })
}
// 新增用户
export function insertRole(data = {}) {
    return axios.request({
      url: `UserInfo/Insert`,
      method: "post",
      data: data,
    })
}
// 修改用户
export function updateRole(data = {}) {
    return axios.request({
      url: `UserInfo/Update`,
      method: "post",
      data: data,
    })
}
// 删除用户
export function deleteUser(data = {}) {
    return axios.request({
      url: `UserInfo/Delete`,
      method: "post",
      data: data,
    })
}
// 冻结用户
export function lockUser(data = {}) {
    return axios.request({
      url: `UserInfo/Locked`,
      method: "post",
      data: data,
    })
}


// 冻结用户
export function lockRole(data = {}) {
    return axios.request({
      url: `UserInfo/Locked`,
      method: "post",
      data: data,
    })
}


// 首页
// 设备情况统计查询
export function getEquipmentCount(data = {}) {
  return axios.request({
    url: `Main/EquipmentCount`,
    method: "post",
    data: data,
  })
}
// 经营情况统计查询
export function getBusinessCount(data = {}) {
  return axios.request({
    url: `Main/BusinessCount`,
    method: "post",
    data: data,
  })
}
// 订单概况统计查询
export function getOrderCount(data = {}) {
  return axios.request({
    url: `Main/OrderCount`,
    method: "post",
    data: data,
  })
}
// 销售统计图表
export function getSalesChart(data = {}) {
  return axios.request({
    url: `Main/SalesChart`,
    method: "post",
    data: data,
  })
}
// 商品销量排行榜
export function getGoodsSalesChart(data = {}) {
  return axios.request({
    url: `Main/GoodsSalesChart`,
    method: "post",
    data: data,
  })
}


// 设备管理
// 设备列表查询
export function getEquipmentInfo(data = {}) {
  return axios.request({
    url: `EquipmentInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 查看设备二维码
export function GetCodeUrl(data = {}) {
  return axios.request({
    url: `EquipmentInfo/GetCodeUrl`,
    method: "post",
    data: data,
  })
}
// 设备安装
export function EquipmentInstall(data = {}) {
  return axios.request({
    url: `EquipmentInfo/EquipmentInstall`,
    method: "post",
    data: data,
  })
}
// 查询设备安装场地列表
export function GetSubSite(data = {}) {
  return axios.request({
    url: `SiteInfo/GetSubSite`,
    method: "post",
    data: data,
  })
}
// 异常设备列表查询
export function getErrorEquipment(data = {}) {
  return axios.request({
    url: `EquipmentErrorInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 生成补货单
export function GenerateReplenishment(data = {}) {
  return axios.request({
    url: `EquipmentErrorInfo/GenerateReplenishment`,
    method: "post",
    data: data,
  })
}
// 补货记录列表查询
export function getReplenishmentInfo(data = {}) {
  return axios.request({
    url: `ReplenishmentInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 补货记录商品详情
export function getRepGoodsInfo(data = {}) {
  return axios.request({
    url: `RepGoodsInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 修改补货数量
export function UpdateGoodsNum(data = {}) {
  return axios.request({
    url: `RepGoodsInfo/UpdateGoodsNum`,
    method: "post",
    data: data,
  })
}
// 查看设备操作记录
export function getRepEquipmentInfo(data = {}) {
  return axios.request({
    url: `RepEquipmentInfo/GetData`,
    method: "post",
    data: data,
  })
}

// 存盘记录列表查询
export function getEquipmentStocktakingInfo(data = {}) {
  return axios.request({
    url: `EquipmentStocktakingInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 存盘记录商品详情
export function getStockGoodsInfo(data = {}) {
  return axios.request({
    url: `StockGoodsInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 查看设备售卖的商品列表
export function getEquipmentPayGoodsInfo(data = {}) {
  return axios.request({
    url: `EquipmentPayGoodsInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 新增/修改售卖的商品（称重售卖机）
export function addPayGoods(data = {}) {
  return axios.request({
    url: `EquipmentPayGoodsInfo/AddPayGoods`,
    method: "post",
    data: data,
  })
}
// 设置/重置售卖的商品（视觉售卖机）
export function updatePayGoods(data = {}) {
  return axios.request({
    url: `EquipmentPayGoodsInfo/UpdatePayGoods`,
    method: "post",
    data: data,
  })
}


// 商品管理
// 获取合豚商品列表
export function getHTGetData(data = {}) {
  return axios.request({
    url: `GoodsInfo/HTGetData`,
    method: "post",
    data: data,
  })
}
// 导入合豚的商品进系统
export function importHTGoodsFoSystem(data = {}) {
  return axios.request({
    url: `GoodsInfo/ImportHTGoodsFoSystem`,
    method: "post",
    data: data,
  })
}
// 充电宝列表查询
export function getGoodsInfo(data = {}) {
  return axios.request({
    url: `GoodsInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 新增商品
export function addGoodsInfo(data = {}) {
  return axios.request({
    url: `GoodsInfo/Insert`,
    method: "post",
    data: data,
  })
}
// 图片上传
export function UploadImage(data = {}) {
  return axios.request({
    url: `UploadImage/FileUp`,
    method: "post",
    data: data,
  })
}
// 修改商品
export function editGoodsInfo(data = {}) {
  return axios.request({
    url: `GoodsInfo/Update`,
    method: "post",
    data: data,
  })
}
// 删除商品
export function delGoodsInfo(data = {}) {
  return axios.request({
    url: `GoodsInfo/Delete`,
    method: "post",
    data: data,
  })
}
// 商品上传至识别平台
export function UpHTSkuGoods(data = {}) {
  return axios.request({
    url: `GoodsInfo/UpHTSku`,
    method: "post",
    data: data,
  })
}
// 商品导出
export function ExcelExportGoods(data = {}) {
  return axios.request({
    url: `GoodsInfo/ExcelExportGoods`,
    method: "post",
    data: data,
  })
}
// 商品类型列表查询
export function getGoodsTypeInfo(data = {}) {
  return axios.request({
    url: `GoodsTypeInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 新增商品类型
export function addGoodsTypeInfo(data = {}) {
  return axios.request({
    url: `GoodsTypeInfo/Insert`,
    method: "post",
    data: data,
  })
}
// 修改商品类型
export function editGoodsTypeInfo(data = {}) {
  return axios.request({
    url: `GoodsTypeInfo/Update`,
    method: "post",
    data: data,
  })
}
// 删除商品类型
export function delGoodsTypeInfo(data = {}) {
  return axios.request({
    url: `GoodsTypeInfo/Delete`,
    method: "post",
    data: data,
  })
}
// 商品类型导出
export function ExcelExportGoodsType(data = {}) {
  return axios.request({
    url: `GoodsTypeInfo/ExcelExportGoodsType`,
    method: "post",
    data: data,
  })
}

// 交易管理
// 订单列表查询
export function getOrderInfo(data = {}) {
  return axios.request({
    url: `OrderInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 订单详情
export function getOrderInfoDetail(data = {}) {
  return axios.request({
    url: `OrderInfo/GetOrderInfo`,
    method: "post",
    data: data,
  })
}
// 订单提交结单数据
export function OrderSettlement(data = {}) {
  return axios.request({
    url: `OrderInfo/OrderSettlement`,
    method: "post",
    data: data,
  })
}
// 查询订单支付数据
export function getOrderPayInfo(data = {}) {
  return axios.request({
    url: `OrderInfo/GetOrderPayInfo`,
    method: "post",
    data: data,
  })
}
// 查询订单售卖商品明细
export function getOrderGoodsInfo(data = {}) {
  return axios.request({
    url: `OrderGoodsInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 订单取消授权
export function cancelOrder(data = {}) {
  return axios.request({
    url: `OrderInfo/CancelOrder`,
    method: "post",
    data: data,
  })
}
// 订单导出
export function ExcelExportOrder(data = {}) {
  return axios.request({
    url: `OrderInfo/ExcelExportOrder`,
    method: "post",
    data: data,
  })
}
// 发起退款
export function AddRefund(data = {}) {
  return axios.request({
    url: `OrderRefundInfo/AddRefund`,
    method: "post",
    data: data,
  })
}
// 分账列表查询
export function getOrderLedgerInfo(data = {}) {
  return axios.request({
    url: `OrderLedgerInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 退款列表查询
export function getOrderRefundInfo(data = {}) {
  return axios.request({
    url: `OrderRefundInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 确认退款
export function ReviewRefund(data = {}) {
  return axios.request({
    url: `OrderRefundInfo/ReviewRefund`,
    method: "post",
    data: data,
  })
}
// 驳回退款
export function RejectRefund(data = {}) {
  return axios.request({
    url: `OrderRefundInfo/RejectRefund`,
    method: "post",
    data: data,
  })
}
// 分账账号列表查询
export function getUserLedgerNumInfo(data = {}) {
  return axios.request({
    url: `UserLedgerNumInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 新增分账账号
export function addUserLedgerNumInfo(data = {}) {
  return axios.request({
    url: `UserLedgerNumInfo/AddUserLedgerNum`,
    method: "post",
    data: data,
  })
}
// 修改分账账号
export function updateUserLedgerNumInfo(data = {}) {
  return axios.request({
    url: `UserLedgerNumInfo/UpdateUserLedgerNum`,
    method: "post",
    data: data,
  })
}


// 场所管理
// 场所列表查询
export function getSiteInfo(data = {}) {
  return axios.request({
    url: `SiteInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 新增场所
export function addSiteInfo(data = {}) {
  return axios.request({
    url: `SiteInfo/Insert`,
    method: "post",
    data: data,
  })
}
// 修改场所
export function editSiteInfo(data = {}) {
  return axios.request({
    url: `SiteInfo/Update`,
    method: "post",
    data: data,
  })
}
// 删除场所
export function delSiteInfo(data = {}) {
  return axios.request({
    url: `SiteInfo/Delete`,
    method: "post",
    data: data,
  })
}
// 场所设置计费模板
export function UpdateChargingTemplateId(data = {}) {
  return axios.request({
    url: `SiteInfo/UpdateChargingTemplateId`,
    method: "post",
    data: data,
  })
}
// 查询场所是否已经设置过分润
export function getProfitInfo(data = {}) {
  return axios.request({
    url: `ProfitInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 场所设置分润
export function setProfitInfo(data = {}) {
  return axios.request({
    url: `SiteInfo/UpdateLedgerRatio`,
    method: "post",
    data: data,
  })
}
// 场所绑定设备供应商 
export function UpdateDeviceSupplierId(data = {}) {
  return axios.request({
    url: `SiteInfo/UpdateDeviceSupplierId`,
    method: "post",
    data: data,
  })
}
// 场所绑定代理
export function UpdateAgentId(data = {}) {
  return axios.request({
    url: `SiteInfo/UpdateAgentId`,
    method: "post",
    data: data,
  })
}
// 场所绑定商户
export function UpdateManagerId(data = {}) {
  return axios.request({
    url: `SiteInfo/UpdateManagerId`,
    method: "post",
    data: data,
  })
}

// 获取场地名下对应设备的售卖规则
export function getSitePayGoodsInfo(data = {}) {
  return axios.request({
    url: `SitePayGoodsInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 新增售卖规格
export function AddSitePayGoods(data = {}) {
  return axios.request({
    url: `SitePayGoodsInfo/AddSitePayGoods`,
    method: "post",
    data: data,
  })
}
// 修改售卖规格
export function UpdateSitePayGoods(data = {}) {
  return axios.request({
    url: `SitePayGoodsInfo/UpdateSitePayGoods`,
    method: "post",
    data: data,
  })
}
// 删除售卖规格
export function DeltelSitePayGoods(data = {}) {
  return axios.request({
    url: `SitePayGoodsInfo/DeltelSitePayGoods`,
    method: "post",
    data: data,
  })
}


// 获取后台用户列表
export function getUserList(data = {}) {
  return axios.request({
    url: `UserInfo/GetUserList`,
    method: "post",
    data: data,
  })
}
// 获取特殊微信用户列表
export function getRoleUser(data = {}) {
  return axios.request({
    url: `WeChatUserInfo/GetRoleUser`,
    method: "post",
    data: data,
  })
}


// 小程序用户管理
// 微信用户列表查询
export function getWeChatUserInfo(data = {}) {
  return axios.request({
    url: `WeChatUserInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 设置微信用户角色
export function setWeChatUserRole(data = {}) {
  return axios.request({
    url: `WeChatUserInfo/SetRole`,
    method: "post",
    data: data,
  })
}

// 支付宝用户列表查询
export function getAliUserInfo(data = {}) {
  return axios.request({
    url: `AliUserInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 设置支付宝用户角色
export function setAliUserRole(data = {}) {
  return axios.request({
    url: `AliUserInfo/SetRole`,
    method: "post",
    data: data,
  })
}


// 通讯记录
// 通讯记录列表查询
export function getMessageInfo(data = {}) {
  return axios.request({
    url: `MessageInfo/GetData`,
    method: "post",
    data: data,
  })
}


// 供应商管理
// 列表查询
export function getSupplierInfo(data = {}) {
  return axios.request({
    url: `C_SupplierInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 下拉数据集合查询
export function getSupplierList(data = {}) {
  return axios.request({
    url: `C_SupplierInfo/GetList`,
    method: "post",
    data: data,
  })
}
// 新增
export function addSupplier(data = {}) {
  return axios.request({
    url: `C_SupplierInfo/AddSupplier`,
    method: "post",
    data: data,
  })
}
// 修改
export function UpdateSupplier(data = {}) {
  return axios.request({
    url: `C_SupplierInfo/UpdateSupplier`,
    method: "post",
    data: data,
  })
}
// 删除
export function DeleteSupplier(data = {}) {
  return axios.request({
    url: `C_SupplierInfo/DeleteSupplier`,
    method: "post",
    data: data,
  })
}
// 冻结解冻
export function LockedSupplier(data = {}) {
  return axios.request({
    url: `C_SupplierInfo/LockedSupplier`,
    method: "post",
    data: data,
  })
}


// 客户管理
// 列表查询
export function getConnectionInfo(data = {}) {
  return axios.request({
    url: `C_ConnectionInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 下拉数据集合查询
export function getConnectionList(data = {}) {
  return axios.request({
    url: `C_ConnectionInfo/GetList`,
    method: "post",
    data: data,
  })
}
// 新增
export function AddConnection(data = {}) {
  return axios.request({
    url: `C_ConnectionInfo/AddConnection`,
    method: "post",
    data: data,
  })
}
// 修改
export function UpdateConnection(data = {}) {
  return axios.request({
    url: `C_ConnectionInfo/UpdateConnection`,
    method: "post",
    data: data,
  })
}
// 删除
export function DeleteConnection(data = {}) {
  return axios.request({
    url: `C_ConnectionInfo/DeleteConnection`,
    method: "post",
    data: data,
  })
}
// 冻结解冻
export function LockedConnection(data = {}) {
  return axios.request({
    url: `C_ConnectionInfo/LockedConnection`,
    method: "post",
    data: data,
  })
}


// 仓库管理
// 列表查询
export function getWarehouseInfo(data = {}) {
  return axios.request({
    url: `C_WarehouseInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 下拉数据集合查询
export function getWarehouseInfoList(data = {}) {
  return axios.request({
    url: `C_WarehouseInfo/GetList`,
    method: "post",
    data: data,
  })
}
// 新增
export function AddWarehouse(data = {}) {
  return axios.request({
    url: `C_WarehouseInfo/AddWarehouse`,
    method: "post",
    data: data,
  })
}
// 修改
export function UpdateWarehouse(data = {}) {
  return axios.request({
    url: `C_WarehouseInfo/UpdateWarehouse`,
    method: "post",
    data: data,
  })
}
// 删除
export function DeleteWarehouse(data = {}) {
  return axios.request({
    url: `C_WarehouseInfo/DeleteWarehouse`,
    method: "post",
    data: data,
  })
}
// 冻结解冻
export function LockedWarehouse(data = {}) {
  return axios.request({
    url: `C_WarehouseInfo/LockedWarehouse`,
    method: "post",
    data: data,
  })
}


// 库位管理
// 列表查询
export function getStorehouseInfo(data = {}) {
  return axios.request({
    url: `C_StorehouseInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 下拉数据集合查询
export function getStorehouseInfoList(data = {}) {
  return axios.request({
    url: `C_StorehouseInfo/GetList`,
    method: "post",
    data: data,
  })
}
// 新增
export function AddStorehouse(data = {}) {
  return axios.request({
    url: `C_StorehouseInfo/AddStorehouse`,
    method: "post",
    data: data,
  })
}
// 修改
export function UpdateStorehouse(data = {}) {
  return axios.request({
    url: `C_StorehouseInfo/UpdateStorehouse`,
    method: "post",
    data: data,
  })
}
// 删除
export function DeleteStorehouse(data = {}) {
  return axios.request({
    url: `C_StorehouseInfo/DeleteStorehouse`,
    method: "post",
    data: data,
  })
}
// 冻结解冻
export function LockedStorehouse(data = {}) {
  return axios.request({
    url: `C_StorehouseInfo/LockedStorehouse`,
    method: "post",
    data: data,
  })
}


// 入库管理
// 列表查询
export function getReceiptNoteInfo(data = {}) {
  return axios.request({
    url: `C_ReceiptNoteInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 查询详情
export function getReceiptNoteInfoDetail(data = {}) {
  return axios.request({
    url: `C_ReceiptNoteInfo/GetDetailed`,
    method: "post",
    data: data,
  })
}
// 新增
export function AddReceiptNote(data = {}) {
  return axios.request({
    url: `C_ReceiptNoteInfo/AddReceiptNote`,
    method: "post",
    data: data,
  })
}
// 删除
export function DeleteReceiptNote(data = {}) {
  return axios.request({
    url: `C_ReceiptNoteInfo/DeleteReceiptNote`,
    method: "post",
    data: data,
  })
}


// 出库管理
// 列表查询
export function getIssueNoteInfo(data = {}) {
  return axios.request({
    url: `C_IssueNoteInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 查询详情
export function getIssueNoteInfoDetail(data = {}) {
  return axios.request({
    url: `C_IssueNoteInfo/GetDetailed`,
    method: "post",
    data: data,
  })
}
// 新增
export function AddIssueNote(data = {}) {
  return axios.request({
    url: `C_IssueNoteInfo/AddIssueNote`,
    method: "post",
    data: data,
  })
}
// 删除
export function DeleteIssueNote(data = {}) {
  return axios.request({
    url: `C_IssueNoteInfo/DeleteIssueNote`,
    method: "post",
    data: data,
  })
}


// 库存记录
// 列表查询
export function getStockDataInfo(data = {}) {
  return axios.request({
    url: `C_StockDataInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 获取库存数据列表
export function getStockDataList(data = {}) {
  return axios.request({
    url: `C_StockDataInfo/GetStockDataList`,
    method: "post",
    data: data,
  })
}


// 盘存管理
// 列表查询
export function getTakeInventoryInfo(data = {}) {
  return axios.request({
    url: `C_TakeInventoryInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 查询详情
export function getInventoryDetail(data = {}) {
  return axios.request({
    url: `C_TakeInventoryInfo/GetDetailed`,
    method: "post",
    data: data,
  })
}
// 新增
export function AddTakeInventory(data = {}) {
  return axios.request({
    url: `C_TakeInventoryInfo/AddTakeInventory`,
    method: "post",
    data: data,
  })
}

// 分润列表
export function getProfitDataInfo(data = {}) {
  return axios.request({
    url: `ProfitDataInfo/GetData`,
    method: "post",
    data: data,
  })
}

// 提现账号
// 列表查询
export function getTakingNumInfo(data = {}) {
  return axios.request({
    url: `TakingNumInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 新增
export function addTakingNumInfo(data = {}) {
  return axios.request({
    url: `TakingNumInfo/Insert`,
    method: "post",
    data: data,
  })
}
// 编辑
export function editTakingNumInfo(data = {}) {
  return axios.request({
    url: `TakingNumInfo/Update`,
    method: "post",
    data: data,
  })
}
// 删除
export function delTakingNumInfo(data = {}) {
  return axios.request({
    url: `TakingNumInfo/Delete`,
    method: "post",
    data: data,
  })
}
// 获取本用户的体现账号列表
export function getUserTakingNum(data = {}) {
  return axios.request({
    url: `TakingNumInfo/GetList`,
    method: "post",
    data: data,
  })
}


// 提现记录
// 列表查询
export function getTakingAmountInfo(data = {}) {
  return axios.request({
    url: `TakingAmountInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 查询登录当前账户提现信息
export function GetTakingSumData(data = {}) {
  return axios.request({
    url: `TakingAmountInfo/GetTakingSumData`,
    method: "post",
    data: data,
  })
}
// 新增
export function addTakingAmountInfo(data = {}) {
  return axios.request({
    url: `TakingAmountInfo/Insert`,
    method: "post",
    data: data,
  })
}
// 审核
export function checkTakingAmountInfo(data = {}) {
  return axios.request({
    url: `TakingAmountInfo/Check`,
    method: "post",
    data: data,
  })
}
// 打款 
export function PayTakingAmount(data = {}) {
  return axios.request({
    url: `TakingAmountInfo/Payment`,
    method: "post",
    data: data,
  })
}

// 开票信息
// 列表查询
export function getInvoicingInfo(data = {}) {
  return axios.request({
    url: `InvoicingInfo/GetData`,
    method: "post",
    data: data,
  })
}
// 新增
export function addInvoicingInfo(data = {}) {
  return axios.request({
    url: `InvoicingInfo/Insert`,
    method: "post",
    data: data,
  })
}
// 启用
export function editInvoicingInfo(data = {}) {
  return axios.request({
    url: `InvoicingInfo/Update`,
    method: "post",
    data: data,
  })
}
// 获取启用的抬头信息
export function getEnableInvoice(data = {}) {
  return axios.request({
    url: `InvoicingInfo/GetEnableData`,
    method: "post",
    data: data,
  })
}



