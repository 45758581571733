<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="抬头" prop="lookUp">
          <el-input placeholder="请输入抬头" v-model="form.lookUp"></el-input>
        </el-form-item>
        <el-form-item label="税号" prop="dutyParagraph">
          <el-input placeholder="请输入税号" v-model="form.dutyParagraph"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import {addInvoicingInfo} from '@/api/operateCenter.js';
  export default {
    data() {
      return {
        userInfo:JSON.parse(sessionStorage.getItem('userInfo')) || undefined,
        form:{ // 表单
          lookUp:'',
          dutyParagraph:'',
        },
        rules: { // 表单校验
          lookUp: [
            { required: true, message: "开票抬头不得为空", trigger: "blur" },
          ],
          dutyParagraph: [
            { required: true, message: "税号不得为空", trigger: "blur" },
          ],
        },
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {},
  
    methods: {
      // 关闭弹框
      close(){
        this.$emit('close');
      },
      // 新增
      async add(){
        await addInvoicingInfo(this.form).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'success'
            })
            this.$nextTick(()=>{
              this.$emit('close');
            })
          }else{
            this.$message({
              message:'提交失败，请重试',
              type:'fail'
            })
          }
        })
      },
      //  提交
      submit(form) {
         this.$refs["form"].validate(valid => {
            if (valid) {
              this.$confirm('确认提交吗？').then( () => {
                this.add();
              })
            } else {
              return false;
            }
        });
      },
      
    }
  };
  </script>
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .el-select{
     width: 100%;
   }
  //  el-select-dropdown__wrap el-scrollbar__wrap
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    /deep/ .el-form-item {
      display: flex;
    }
    /deep/ .el-form-item__label{
      width: 150px !important;
    }
    /deep/ .el-form-item__content{
      margin-left: 0 !important;
      flex: 1 !important;
    }
  </style>
  