<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="场地名称" prop="siteName" >
        <el-input placeholder="请输入场地名称" v-model="form.siteName"></el-input>
      </el-form-item>
      <el-form-item label="详细地址" prop="address" >
        <el-input placeholder="点击地图选择地址" v-model="form.address"></el-input>
        <button class="map" type="button" @click="chooseAddress">地图选址</button>
      </el-form-item>
      <el-form-item label="所属代理" prop="agentId">
        <el-select v-model="form.agentId" placeholder="请选择" :disabled="userInfo.roleId != 1">
          <el-option
            v-for="item in agentOptions"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属商户" prop="managerId">
        <el-select v-model="form.managerId" placeholder="请选择">
          <el-option
            v-for="item in managerOptions"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属行业" prop="industry" >
        <el-select v-model="form.industry"  placeholder="请选择">
          <el-option
            v-for="item in industryOptions"
            :key="item.name"
            :label="item.name"
            :value="item.name"
            >
          </el-option>
        </el-select>
        <el-input placeholder="请输入行业" v-if="form.industry == '其他'" v-model="form.industryName"></el-input>
      </el-form-item>
      <el-form-item label="品牌" prop="brand" >
        <el-select v-model="form.brand"  placeholder="请选择" >
          <el-option
            v-for="item in BrandOptions"
            :key="item.name"
            :label="item.name"
            :value="item.name"
            >
          </el-option>
        </el-select>
        <el-input placeholder="请输入品牌" v-if="form.brand == '其他'" v-model="form.brandName"></el-input>
      </el-form-item>
      <el-form-item label="预估日客流" prop="passengerFlow" >
        <el-input placeholder="请输入预估日客流" v-model="form.passengerFlow" type="number"></el-input>
      </el-form-item>
      <el-form-item label="门头照" prop="doorHeaderImg" >
        <el-upload
          action="https://www.quantoukeji.com/api/UploadImage/FileUp"
          :headers='headers'
          :show-file-list="false"
          :on-success="handleSuccess"
          >
          <img v-if="form.doorHeaderImg" :src="form.doorHeaderImg" >
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="店内照片" prop="siteImg" >
        <el-upload
          action="https://www.quantoukeji.com/api/UploadImage/FileUp"
          :headers='headers'
          :show-file-list="false"
          :on-success="handleSiteSuccess"
          >
          <img v-if="form.siteImg" :src="form.siteImg" >
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain='true'>立即提交</el-button>
      </el-form-item>
    </el-form>
    <!-- 地图弹框 -->
    <el-dialog title="地址选择" :visible.sync="open" width="900px" append-to-body>
        <el-form label-width="80px">
          <el-row>
            <el-col :span="10">
              <el-form-item label="搜索地址">
                <el-input type="text" id="searchAddressInput" v-model="searchAddress" placeholder="请输入地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="当前地址">
                <el-input v-model="addressInfo.address" placeholder="请输入内容">
                  <template slot="prepend">
                    {{addressInfo.province}}{{addressInfo.city}}{{addressInfo.district}}
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 百度地图 -->
        <div id="Map-Container" style="width: 100%; height: 400px;"></div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirm">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </div>
    </el-dialog>
  </section>
</template>

<script>
let _this;
import {addSiteInfo,getUserList,getImgUrl,getListChargingTemplateInfo,editSiteInfo} from '@/api/operateCenter.js';
import {selectDictionary} from '@/api/public.js';
let token=sessionStorage.getItem('token');
export default {
  props:['row'],
  data() {
    return {
      headers:{authorization:token},
      userInfo:JSON.parse(sessionStorage.getItem('userInfo')) || undefined,
      form:{
        siteImg:null,
        doorHeaderImg:null,
        brandName:'',
        industryName:"",
      },
      agentId:'',
      managerId:'',
      value:[],
      agentOptions:[],
      managerOptions:[],
      templateOptions:[],
      industryOptions:[],
      BrandOptions:[],
      show:true,
      rules: {
        siteName: [
          { required: true, message: "场地名称不得为空", trigger: "blur" },
        ],
        address: [
          { required: true, message: "详细地址不得为空", trigger: "blur" },
        ],
        doorHeaderImg:[
          { required: true, message: "门头照不得为空", trigger: "change" },
        ],
        agentId:[
          { required: true, message: "所属代理不得为空", trigger: "blur" },
        ],
        managerId:[
          { required: true, message: "所属商户不得为空", trigger: "blur" },
        ],
        industry:[
          { required: true, message: "所属行业不得为空", trigger: "blur" },
        ]
      },
      open:false,
      // 搜索地址
      searchAddress: "",
      userGps:["120.17906278816268","30.32004063996321"], // 用户当前位置
      // 地址信息
      addressInfo: {
        longitude: "",// 经度
        latitude: "",// 纬度
        province: "",// 省
        city: "",// 市
        district: "",// 区
        address: "",// 详细地址
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    _this.init();
    this.getTemplates();
    this.getBrands();
    if(this.userInfo.roleId==2){
      this.form.agentId=this.userInfo.id;
    }else if(this.userInfo.roleId==3){
      this.form.agentId=this.userInfo.pId;
      this.form.managerId=this.userInfo.id;
    }
    if(this.row){
      this.row.industry = this.row.industry;
      this.row.brand = this.row.brand;
      this.form = this.row;
    }
  },

  methods: {
    // 获取代理列表
    async init(){
      await getUserList({roleId:3}).then(res=>{
        if(res.success){
          this.agentOptions=res.data;
        }
      })
      this.getManagers();
    },
    // 获取消费模板列表
    async getTemplates(){
      await getListChargingTemplateInfo({Data:''}).then(res=>{
        if(res.success){
          this.templateOptions = res.data;
        }else{
          this.templateOptions = [];
        }
      })
    },
    // 获取品牌、行业列表
    async getBrands(){
      let data = {
        sign:'BrandData'
      }
      let data2 = {
        sign:'industryData'
      }
      await selectDictionary(data).then(res=>{
        if(res.success){
          this.BrandOptions = res.data;
        }else{
          this.BrandOptions = [];
        }
      })
      await selectDictionary(data2).then(res=>{
        if(res.success){
          this.industryOptions = res.data;
        }else{
          this.industryOptions = [];
        }
      })
    },
    // 打开地图选址
    chooseAddress(){
      this.open=true;
      this.initBaiduMap();
    },
    // 获取商户列表
    async getManagers(){
      await getUserList({roleId:4}).then(res=>{
        if(res.success){
          this.managerOptions=res.data;
        }
      })
    },
    // 初始化百度地图
    initBaiduMap() {
      let Gps = this.form.gps ? this.form.gps.split(',') : this.userGps;
      this.$nextTick(() => {
        /* 初始化地图 start */
        var map = new BMapGL.Map("Map-Container") // 创建地图实例
        var point = new BMapGL.Point(Gps[0], Gps[1]); // 设置中心点坐标
        map.centerAndZoom(point, 18); // 地图初始化，同时设置地图展示级别
        map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
        /* 初始化地图 end */

        /** 点击地图创建坐标事件Start */
        // 提交地图点击事件
        map.addEventListener("click", (e) => {
          map.clearOverlays(); // 移除地图上的标注
          this.$nextTick(()=>{
            var click = e.latlng; // 点击的坐标
            var Point = new BMapGL.Point(click.lng,click.lat);
            var Marker = new BMapGL.Marker(Point); // 创建标注
            map.addOverlay(Marker); // 将标注提交到地图中
            this.geocAddress(Point);
          })
        })
        /** 点击地图创建坐标事件End */

        /** 搜索地址Start */
        // 建立一个自动完成的对象
        var ac = new BMapGL.Autocomplete({
          input: "searchAddressInput",
          location: map,
          onSearchComplete:(e)=>{}
        });
        // 鼠标点击下拉列表后的事件
        ac.addEventListener("onconfirm", (e) => {
          map.clearOverlays();
          var local = new BMapGL.LocalSearch(map, {
            // 智能搜索
            onSearchComplete: (res) => {
              let poi = res.getPoi(0); // 获取第一个智能搜索的结果
              var searchpt = poi.point; // 获取坐标
              map.centerAndZoom(searchpt, 16);
              map.addOverlay(new BMapGL.Marker(searchpt));
              this.geocAddress(searchpt);
            }
          });
          // 搜索词
          var searchValue = e.item.value;
          local.search(
            searchValue.province +
            searchValue.city +
            searchValue.district +
            searchValue.street +
            searchValue.business
          )
        });
        /** 搜索地址End */
      })
    },
    // 逆向解析地址
    geocAddress(point) {
        var geoc = new BMapGL.Geocoder();
        geoc.getLocation(point,  (geocInfo) => {
          // 设置基本信息
          var addressInfo = geocInfo.addressComponents;
          this.addressInfo.longitude = point.lng;
          this.addressInfo.latitude = point.lat;
          this.addressInfo.province = addressInfo.province;
          this.addressInfo.city = addressInfo.city;
          this.addressInfo.district = addressInfo.district;
          let address = addressInfo.street + addressInfo.streetNumber;
          if (geocInfo.surroundingPois.length > 0) {
            address = address + geocInfo.surroundingPois[0].title;
          }
          this.addressInfo.address = address;
        });
    },
    /** 确认选择 */
    confirm() {
      this.$emit("confirmMapAddress", this.addressInfo);
      this.open = false;
      this.form.address = this.addressInfo.province + this.addressInfo.city + this.addressInfo.district + this.addressInfo.address;
      this.form.gps = this.addressInfo.longitude + ',' + this.addressInfo.latitude;
    },
    // 取消选择
    cancel() {
      this.open = false;
    },
    close(){
       _this.$emit('close');
    },
    handleSuccess(e){
      this.form.doorHeaderImg = getImgUrl() + e.data;
    },
    handleSiteSuccess(e){
      this.form.siteImg = getImgUrl() + e.data;
    },
    // 编辑
    async edit(){
      this.form.brand = this.form.brand == '其他' ? this.form.brandName : this.form.brand;
      this.form.industry = this.form.industry == '其他' ? this.form.industryName : this.form.industry;
      await editSiteInfo(this.form).then(res=>{
        if (res.success) {
          _this.$message({
            message: "提交成功",
            type: "success",
          });
          setTimeout(() => {
            this.form={};
            _this.$emit("success");
            _this.$emit('close');
            _this.init();
          }, 1000);
        }else{
          _this.$message({
            message: "提交失败，请重试",
            type: "fail",
          });
        }
      })
    },
    // 新增
    async add(){
      this.form.brand = this.form.brand == '其他' ? this.form.brandName : this.form.brand;
      this.form.industry = this.form.industry == '其他' ? this.form.industryName : this.form.industry;
      await addSiteInfo(_this.form).then((res) => {
        if (res.success) {
          _this.$message({
            message: "提交成功",
            type: "success",
          });
          setTimeout(() => {
            this.form={};
            _this.$emit("success");
            _this.$emit('close');
            _this.init();
          }, 1000);
        }else{
          _this.$message({
            message: "提交失败，请重试",
            type: "fail",
          });
        }
      })
    },
     //  提交
    submit(form) {
       _this.$refs["form"].validate(valid => {
          if (valid) {
            this.$confirm('确认提交吗？').then(res=>{
              this.form.passengerFlow = Number(this.form.passengerFlow)
              if(this.row){
                this.edit();
              }else{
                this.add();
              }
            })
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
.map{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 80px;
   }
   /deep/ .el-form-item__content{
    display: flex;
   }
 .el-drawer{
   overflow:auto !important;
 }
 .el-select{
   width: 100%;
 }
//  el-select-dropdown__wrap el-scrollbar__wrap
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  img{
    width: 178px;
    height: 178px;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
