<template>
    <section >
      <div class="add">
        <el-button type="primary" @click="openDrawer(null)">新增</el-button>
      </div>
      <div class="container">
        <el-table
          :data="tableList"
          style="width: 100%" 
          class="table"
          border
          stripe
          :header-cell-style="{background:'#fafafa'}"
          fit
        >
        <!-- 数据展示区 -->
          <el-table-column
            label="所属用户"
            prop="takingName"
            align='center'>
          </el-table-column> 
          <el-table-column
            label="账户类型"
            prop="takingNumTypeStr"
            align='center'>
          </el-table-column>
          <el-table-column
            label="账号"
            prop="bankCardNumber"
            align='center'>
            <template slot-scope="scope">
              <p>银行卡号：{{scope.row.bankCardNumber ? scope.row.bankCardNumber : '--'}}</p>
              <p>开户行：{{scope.row.bank ? scope.row.bank : '--'}}</p>
              <p>户主：{{scope.row.householder ? scope.row.householder : '--'}}</p>
            </template>
          </el-table-column>
           <el-table-column
            label="微信账号"
            prop="weChatAccount"
            align='center'>
          </el-table-column>
          <el-table-column
            label="支付宝账号"
            prop="aliAccount"
            align='center'>
          </el-table-column>
          <el-table-column
           label="操作"
           fixed="right"
           align='center'
           width="200">
            <template slot-scope="scope">
                <el-button size="mini" @click="openDrawer(scope.row)">编辑</el-button>
                <el-button size="mini" @click="handelDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <el-col :span="24" class="toolbar" >
          <el-pagination
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            :current-page="searchInfo.pageIndex"
            :page-size="10"
            :total="total"
            background
            style="float:right;"
          ></el-pagination>
        </el-col>
        <!-- 新增 -->
        <el-drawer
         :title="row ? '编辑' : '新增'"
         :visible.sync="addDrawer"
         :direction="direction"
         :before-close="handleClose"
         size="40%">
          <add v-if="addDrawer" @close='handleClose'  ref='newForm' :row="row"></add>
        </el-drawer>
      </div>
    </section>
  </template>
  
  <script>
  import {getTakingNumInfo,delTakingNumInfo} from '@/api/operateCenter.js';
  import { selectDictionary } from '@/api/public.js';
  import add from './add.vue';
  export default {
    data() {
      return {
        tableList:[], // 订单列表信息
        total:0, // 数据总条数
        searchInfo:{ // 查询条件
          pageIndex:1,
          pageSize:10,
        },
        row:null, // 传递给弹窗子组件的数据
        direction:'rtl', // 弹窗弹出方向
        addDrawer:false, // 控制弹框是否显示
        takingNumTypeOptions:[], // 账户类型列表
      }
    },
  
    components: {add},
  
    computed: {},
  
    mounted() {
      this.getDictionary();
    },
  
    methods: {
      // 获取订单列表信息
      async init(){
        await getTakingNumInfo(this.searchInfo).then(res=>{
          if(res.success){
            this.tableList=res.data.data;
            this.tableList.forEach(element => {
              for(let i=0;i<this.takingNumTypeOptions.length;i++){
                if(element.takingNumType == this.takingNumTypeOptions[i].code){
                  element.takingNumTypeStr = this.takingNumTypeOptions[i].name;
                  break;
                }
              }
              for(let key in element){
                element[key] = (element[key] || element[key] == 0) && element[key] != '' ? element[key] : '--';
              }
            });
            this.total=res.data.totalCount;
          }
        })
      },
      // 获取账户类型所有字典值
      async getDictionary(){
        await selectDictionary({sign:'TakingNumType'}).then(res=>{
          if(res.success){
            this.takingNumTypeOptions = res.data;
          }else{
            this.takingNumTypeOptions = [];
          }
        })
        this.$nextTick(()=>{
          this.init();
        })
      },
      // 删除
      handelDel(row){
        this.$confirm('确认删除吗？').then( async () => {
          await delTakingNumInfo({Id:row.id}).then(res=>{
            if(res.success){
              this.$message({
                message:'操作成功',
                type:'success'
              })
              this.init();
            }else{
              this.$message({
                message:'操作失败，请重试',
                type:'fail'
              })
            }
          })
        })
      },
      // 打开弹框
      openDrawer(row){
        this.row = row;
        this.addDrawer=true;
      },
      // 关闭
      handleClose(){
        this.addDrawer=false;
        this.init();
      },
      // 分页
      handleCurrentChange(val){
        this.searchInfo.pageIndex=val;
        this.init();
      },
    },
  };
  </script>
  <style scoped lang='scss'>
  // 新增
  .add{
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
    // 搜索栏
   .search{
    border-bottom: 2px solid rgba(240, 242, 245, 1);
    background: #fff;
    padding: 20px;
      .inputs{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .each{
            width:calc((100% - 40px ) / 4) ;
            height: 50px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            .el-select{
             width: 100% !important;
            }
            span{
                width: 100px;
            }
        }
        .el-button{
            margin-left: 15px;
        }
      }
    }
  
    .container{
        padding: 20px;
    }
    // 按钮
    .btns{
      width: 100%;
      background-color: white;
      height: 60px;
      width: 100%;
      padding: 10px 50px;
      position: relative;
      button{
        position: absolute;
        right:20px;
        top:10px;
      }
    }
  
  .el-table >>> .cell {
    white-space: pre-line;
  }
  .el-drawer.rtl{
    overflow-y: auto !important;
  }
  .el-col-24{
      height: 52px;
     margin-top: 20px;
  }
  
  .equipName{
    margin-bottom: 10px;
  }
  .equipNumber{
    color: #40a9ff;
  }
  .number{
    display: inline-block;
    height: 20px;
    width: 20px;
    padding: 0 2px;
    border: solid 1px #91d5ff;
    color:#40a9ff;
    background: #e6f7ff;
    border-radius: 3px;
    margin-left: 10px;
    text-align: center;
    font-size: 14px;
  }
  .el-tabs__content{
    overflow:auto !important;
  }
  .orderStatus{
    background:#40a9ff;
    color:#fff;
    display: inline-block;
    padding: 3px 5px;
    border-radius: 5px;
  }
  
  .stopWay{
    color: #40a9ff;
    margin-top: 10px;
  }
  </style>
  