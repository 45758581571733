<template>
    <div style="padding: 20px;">
       <!-- 搜索 -->
      <div class="search">
        <div class="inputs">
          <el-input class="wxName" v-model="searchInfo.data.code" placeholder="请输入字典值"></el-input>
          <el-input class="wxName" v-model="searchInfo.data.name" placeholder="请输入字典描述"></el-input>
          <el-input class="wxName" v-model="searchInfo.data.sign" placeholder="请输入所属目录"></el-input>
          <div class="Btns">
           <el-button type="primary" @click="toSearch">查询</el-button>
           <el-button type="primary" @click="backAll">清空搜索条件</el-button>
           <el-button type="primary" @click="handleAdd(null,'add')">添加</el-button>
          </div>
        </div>
      </div>
      <!-- <div class="add">
        <el-button type="primary" @click="handleAdd(null,'add')">添加</el-button>
      </div> -->
      <div class="container">
        <el-table
          :data="tableList"
          style="width: 100%" 
          class="table"
          row-key="Id"
          border
          stripe
          :header-cell-style="{background:'#fafafa'}"
          fit
        >
          <!-- 数据展示区 -->
          <el-table-column
            label="ID"
            prop="id"
            align='center'
            :show-overflow-tooltip='showoverflow'
            width='60px'>
          </el-table-column>
          <el-table-column
            label="描述"
            prop="name"
            :show-overflow-tooltip='showoverflow'
            align='center'>
          </el-table-column>
          <el-table-column
            label="字典值"
            prop="code"
            :show-overflow-tooltip='showoverflow'
            align='center'>
          </el-table-column>
          <el-table-column
            label="字典备注"
            prop="remarks"
            :show-overflow-tooltip='showoverflow'
            align='center'>
          </el-table-column>
          <el-table-column
            label="所属目录"
            prop="sign"
            :show-overflow-tooltip='showoverflow'
            align='center'>
          </el-table-column>
          <el-table-column
            label="扩展信息"
            prop="explain"
            :show-overflow-tooltip='showoverflow'
            align='center'>
          </el-table-column>
          <el-table-column
            label="是否目录"
            prop="isDirectory"
            :show-overflow-tooltip='showoverflow'
            align='center'>
            <template slot-scope="scope">
              <p>{{ scope.row.isDirectory === 1 ? '目录' : (scope.row.isDirectory === 0 ? '内容' : '--') }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" align='center'>
            <template slot-scope="scope">
              <el-button size="mini" @click="handleAdd(scope.row,'edit')">编辑</el-button>
              <el-button size="mini" @click="handleDel(scope.row)">删除</el-button>
            </template>
        </el-table-column>
        </el-table>
        <!--分页-->
        <el-col :span="24" class="toolbar" >
          <el-pagination
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            :current-page="searchInfo.pageIndex"
            :page-size="10"
            :total="total"
            style="float:right;"
          ></el-pagination>
        </el-col>
        <!-- 新增菜单 -->
        <el-drawer
          :title="row ? '编辑' : '新增'"
          :visible.sync="addDrawer"
          :direction="direction"
          :before-close="handleClose"
          size='50%'
          style="overflow:auto">
          <add v-if="addDrawer" @close='handleClose' ref='newForm' :row="row"></add>
        </el-drawer>
      </div>
    </div>
  </template>
  
  <script>
  import { getDictionaryList,delDictionary,selectDictionary } from '@/api/public.js';
  import add from './add.vue';
  export default {
    data() {
      return {
          searchInfo:{
              pageIndex:1,
              pageSize:10,
              data:{
                code:"",
                name:"",
                sign:"",
              },
          },
          total:0,
          tableList:[],
          addDrawer:false,
          row:null,
          direction:'rtl',
          typeOptions:[],
          showoverflow:true
      }
    },
  
    components: {add},
  
    computed: {},
  
    mounted() {
      this.init();
    },
  
    methods: {
      toSearch(){
        this.searchInfo.pageIndex = 1;
        this.init();
      },
      backAll(){
        this.searchInfo = {
            pageIndex:1,
            pageSize:10,
            data:{
              code:"",
              name:"",
              sign:"",
            },
        };
        this.init();
      },
      init(){
          getDictionaryList(this.searchInfo).then(res=>{
              if(res.success && res.data.data){
                  this.tableList = res.data.data;
                  this.total = res.data.totalCount;
              }else{
                  this.tableList = [];
                  this.total = 0;
              }
          })
      },
      getTypeList(){
        let data = {
          is
        }
        selectDictionary().then(res=>{
              if(res.Success){
                  this.typeOptions = res.data;
              }
          })
      },
      // 编辑
      handleAdd(row,type){
          this.row = row;
          this.addDrawer = true;
      },
      handleClose(){
          this.addDrawer = false;
          this.row = null;
      },
      // 删除
      handleDel(row){
          this.$confirm('确认删除吗？').then( async () => {
             await delDictionary({Id:row.id}).then(res=>{
              if(res.Success){
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.init();
              }
            })
          })
      },
      // 分页
      prevpage(val){
        console.log(val)
        this.searchInfo.pageIndex=val;
        this.init();
      },
      nextpage(val){
        console.log(val)
        this.searchInfo.pageIndex=val;
        this.init();
      },
      handleCurrentChange(val){
        console.log(val)
        this.searchInfo.pageIndex = val;
        this.init();
      },
    },
  };
  </script>
  <style scoped lang="scss">
    @import "@/static/default";
  /* 搜索 */
  .search{
    margin-bottom: 30px;
      .inputs{
        display: flex;
      }
      .wxName,.select,.timeSelect{
        width: 200px;
        margin-right: 20px;
      }
      .Btns{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
      }
    }
    /* 添加 */
    .add{
      width: 100%;
      background-color: white;
      height: 60px;
      width: 100%;
      padding: 10px 50px;
      position: relative;
    }
    .add button{
      position: absolute;
      right:20px;
      top:10px;
      width: 100px;
    }
  </style>
  