<template>
  <div id='rights'>
    <!-- 下拉框 -->
    <div class='select'>
      <span>角色权限：</span>
      <el-select v-model="value" placeholder="请选择" @change='choose'>
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.roleName"
          :value="item.roleName">
        </el-option>
      </el-select> 
      <el-button type="primary" @click='modefy'>保存</el-button>
    </div> 
    <!-- 树形控件 -->
    <el-tree
      :data="treeData"
      show-checkbox
      node-key="Id"
      :default-expand-all='expand'
      :props="defaultProps"
      ref='tree'
      @check-change='nodeclick'>
    </el-tree>
  </div>
</template>

<script>
import {getRolesList,getRolePower,editRolePower,getMenuList} from '@/api/public.js';
export default {
  data() {
    return {
      options: [],// 下拉框数据
      value: '',//下拉框双向绑定数据
      treeData: [],// 树形控件数据
      defaultProps: {
        children: 'children',
        label: 'MenuName',
      },
      checked:[],//选中节点数组
      check:[],
      chooseRole:localStorage.getItem('roleId'),//选中角色的id
      expand:true,//树形结构默认展开
    }
  },

  components: {},

  computed: {},

  mounted() {
      this.init(); 
  },

  methods: {
    // 数据初始化
    async init(){
      // 获取角色列表
      await getRolesList().then(res=>{
        if(res.success){
          this.options=res.data;
        }
      });
      // 获取树形结构节点数据
      await getMenuList().then(res=>{
        if(res.success){
          this.treeData=JSON.parse(res.data);
        }
      });
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
    setCheckedNodes(checked) {
      this.$refs.tree.setCheckedNodes(checked);
    },
    // 选择角色时获取到该角色的权限
    choose(e){
      let id;
      let power;
      this.options.forEach(value=>{
        if(value.roleName===e){
          id=value.id;
          this.chooseRole=value.id;
        }
      });
      this.check=[];
      this.checked=[];
      this.resetChecked();
      this.$nextTick(async() => {
        await getRolePower({data:id}).then(res=>{
          if(res.success){
            power=[...res.data];
            power.forEach(element => {
              const obj={
                Id:element.menuId,
              }
              this.check.push(obj);
              this.checked.push(obj.Id);
            });
            // 判断当前获取的权限中，如果父级下的子集并非全部选中的话，则需要让父级显示为非全选状态，即在this.checked中移除掉父级的权限ID
            this.treeData.forEach(value=>{
              if(value.children.length>0){
                for(let i=0;i<value.children.length;i++){
                  if(this.checked.indexOf(value.children[i].Id)===-1 && this.checked.indexOf(value.Id) !==-1){
                    let index=this.checked.indexOf(value.Id);
                    this.checked.splice(index,1);
                    this.check.splice(index,1);
                    console.log(this.check,444)
                    break;
                  }
                }
              }
            })
            this.setCheckedNodes(this.check);
          }
          
          
          this.checked=[...new Set(this.checked)];
        });
      });
    },

    // 修改角色权限
    async modefy(){
      let data=[];
      this.checked.forEach((ele)=>{
        data.push({RoleId:this.chooseRole,MenuId:ele});
      });
      await editRolePower(data).then(res=>{
        if(res.success){
          this.$message({
            message:'设置成功',
            type:'success'
          })
          this.value='';
          this.init();
        }else{
          this.$message({
            message:'设置失败',
            type:'error'
          })
        }
      });
    },

    // 选择节点事件
    nodeclick(e,isSelect){
      if(isSelect){//如果是选中，则往选中数组中添加该节点
        this.checked.push(e.Id)
        this.check.push({Id:e.Id})
      }else{//如果是取消选中，则从选中数组中移除该节点
        for(let i=0;i<this.checked.length;i++){
          if(e.Id===this.checked[i]){
            this.checked.splice(i,1);
            this.check.splice(i,1);
            --i;
          }
        }
      }
      setTimeout(() => {
        this.treeData.forEach(value=>{
          if(value.children.length>0){
            value.children.forEach(val=>{
              if(this.checked.indexOf(val.Id) !== -1){
                this.checked.push(value.Id)
              }
            })
          }
          this.$forceUpdate()
        })
        let arr=[];
        this.checked.forEach(v=>{
          if(arr.indexOf(v)===-1){
            arr.push(v)
          }
        })
        this.checked=[...arr]
      }, 0);
    },
  },
};
</script>
<style scoped>
#rights{
  width: 100%;
  background-color: white;
  padding: 10px 50px;
}
.el-select{
  width: 20%;
}
.select{
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.select button{
  margin-left: 40px;
}
.el-popper ::v-deep .el-scrollbar{
  overflow-y:auto;
  height: 100%;
}
</style>
