<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="账号类型" prop="takingNumType" >
          <el-select v-model="form.takingNumType" placeholder="请选择" >
            <el-option
              v-for="item in takingNumTypeOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="银行卡号" prop="bankCardNumber" v-if="form.takingNumType == 1">
          <el-input placeholder="请输入银行卡号" v-model="form.bankCardNumber"></el-input>
        </el-form-item>
        <el-form-item label="开户行" prop="bank" v-if="form.takingNumType == 1">
          <el-input placeholder="请输入开户行" v-model="form.bank"></el-input>
        </el-form-item>
        <el-form-item label="户主" prop="householder" v-if="form.takingNumType == 1">
          <el-input placeholder="请输入户主" v-model="form.householder"></el-input>
        </el-form-item>
        <el-form-item label="微信账号" prop="weChatAccount" v-if="form.takingNumType == 2">
          <el-input placeholder="请输入微信账号" v-model="form.weChatAccount"></el-input>
        </el-form-item>
        <el-form-item label="支付宝账号" prop="aliAccount" v-if="form.takingNumType == 3">
          <el-input placeholder="请输入支付宝账号" v-model="form.aliAccount"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import {addTakingNumInfo,editTakingNumInfo} from '@/api/operateCenter.js';
  import { selectDictionary } from '@/api/public.js';
  export default {
    props:{
      row:{type:Object},
    },
    data() {
      return {
        userInfo:JSON.parse(sessionStorage.getItem('userInfo')) || undefined,
        form:{ // 表单
          takingNumType:1,
          bankCardNumber:'',
          bank:'',
          householder:'',
          weChatAccount:'',
          aliAccount:'',
        },
        users:[],
        wxUsers:[], // 微信用户
        takingNumTypeOptions:[], // 账号类型选项列表
        rules: { // 表单校验
          takingNumType: [
            { required: true, message: "账号类型不得为空", trigger: "change" },
          ],
          bankCardNumber: [
            { required: true, message: "银行账号不得为空", trigger: "blur" },
          ],
          bank: [
            { required: true, message: "开户行不得为空", trigger: "blur" },
          ],
          householder:[
            { required: true, message: "户主不得为空", trigger: "blur" },
          ],
          weChatAccount:[
            { required: true, message: "微信账号不得为空", trigger: "blur" },
          ],
          aliAccount:[
            { required: true, message: "支付宝账号不得为空", trigger: "blur" },
          ]
        },
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.form = this.row ? this.row : this.form;
      this.getDictionary();
    },
  
    methods: {
      // 获取账户类型所有字典值
      async getDictionary(){
        await selectDictionary({sign:'TakingNumType'}).then(res=>{
          if(res.success){
            this.takingNumTypeOptions = res.data;
            this.takingNumTypeOptions.forEach(element => {
              element.code = Number(element.code)
            });
          }else{
            this.takingNumTypeOptions = [];
          }
        })
        console.log('leixing',this.takingNumTypeOptions)
      },
      // 关闭弹框
      close(){
        this.$emit('close');
      },
      // 新增
      async add(){
        await addTakingNumInfo(this.form).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'success'
            })
            this.$nextTick(()=>{
              this.$emit('close');
            })
          }else{
            this.$message({
              message:'提交失败，请重试',
              type:'fail'
            })
          }
        })
      },
      // 编辑
      async edit(){
        await editTakingNumInfo(this.form).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'success'
            })
            this.$nextTick(()=>{
              this.$emit('close');
            })
          }else{
            this.$message({
              message:'提交失败，请重试',
              type:'fail'
            })
          }
        })
      },
      //  提交
      submit(form) {
         this.$refs["form"].validate(valid => {
            if (valid) {
              this.$confirm('确认提交吗？').then( () => {
                if(this.form.takingNumType == 1){
                  this.form.weChatAccount = '';
                  this.form.aliAccount = '';
                }else if(this.form.takingNumType == 2){
                  this.form.aliAccount = '';
                  this.form.bankCardNumber = '';
                  this.form.bank = '';
                  this.form.householder = '';
                }else{
                  this.form.weChatAccount = '';
                  this.form.bankCardNumber = '';
                  this.form.bank = '';
                  this.form.householder = '';
                }
                if(this.row){ // 修改
                  this.edit();
                }else{ // 新增
                  this.add();
                }
              })
            } else {
              return false;
            }
        });
      },
      
    }
  };
  </script>
  <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .el-select{
     width: 100%;
   }
  //  el-select-dropdown__wrap el-scrollbar__wrap
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    /deep/ .el-form-item {
      display: flex;
    }
    /deep/ .el-form-item__label{
      width: 150px !important;
    }
    /deep/ .el-form-item__content{
      margin-left: 0 !important;
      flex: 1 !important;
    }
  </style>
  