<template>
<section>
  <div class="role">
    <el-button type="primary" @click="handleAdd">添加</el-button>
  </div>
  <div class="container">
    <el-table
    :data="roleData"
    style="width: 100%;margin-bottom: 20px;"
    row-key="id"
    default-expand-all
    class="table"
    border
    stripe
    :header-cell-style="{background:'#fafafa'}"
    fit
    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
        prop="id"
        align="center"
        width="70px"
        label="ID">
      </el-table-column>
      <el-table-column
        prop="roleName"
        align="center"
        label="角色名称">
      </el-table-column>
      <el-table-column
        align="center"
        prop="isLocked"
        label="是否冻结">
        <template slot-scope="scope">{{scope.row.isLocked?'是':'否'}}</template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="creatorName"
        label="创建人">
      </el-table-column>
      <el-table-column
        align="center"
        prop="modifierName"
        label="修改人">
      </el-table-column>
      <el-table-column
        align="center"
        prop="createTime"
        min-width="140px"
        label="创建时间">
      </el-table-column>
      <el-table-column
        align="center"
        prop="modifiyTime"
        min-width="140px"
        label="修改时间">
      </el-table-column>
      <el-table-column label="操作" fixed="right" align='center' min-width="220px">
      <template slot-scope="scope">
          <el-button size="mini" @click="handleDel(scope.$index, scope.row)" v-if="scope.row.id>4">删除</el-button>
          <el-button size="mini" @click="handleLock(scope.$index, scope.row)" v-if="scope.row.id>4">{{!scope.row.isLocked?'冻结':'解冻'}}</el-button>
          <el-button size="mini" @click="handlEdit(scope.$index, scope.row)">修改</el-button>
      </template>
    </el-table-column>
    </el-table>

    <!--分页-->
    <el-col :span="24" class="toolbar" >
      <el-pagination
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-size="10"
        :total="total"
        background
        style="float:right;"
      ></el-pagination>
    </el-col>

    <!-- 新增 -->
    <el-drawer
      title="新增"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose">
      <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
    </el-drawer>
    <!-- 修改 -->
    <el-drawer
      title="修改"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose">
      <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
    </el-drawer>
  </div>
</section>
  
</template>

<script>
  import {getRole,deleteRole,lockRole} from '@/api/public.js';
  import add from './add.vue';
  import edit from './edit.vue';

export default {
  data() {
    return {
      roleData: [],
      addDrawer:false,
      editDrawer:false,
      id:'',
      row:'',
      pageIndex:1,
      pageSize:10,
      direction:'rtl',
      total:0,
    }
  },

  components: {add,edit},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 页面初始化
    async init(){
      await getRole({pageIndex:this.pageIndex,pageSize:this.pageSize}).then(res=>{
        if(res.success){
          this.roleData=res.data.data;
          this.total=res.data.totalCount;
          this.roleData.forEach((element) => {});
        }
      });
    },
    // 打开新增菜单
    handleAdd(index,row){
      this.addDrawer=true;
    },
    handlEdit(index,row){
      this.editDrawer=true;
      this.id=index;
      this.row=row;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 新增成功
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 冻结
    handleLock(index,row){
      this.$confirm('确认冻结/解冻该角色吗？','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        lockRole({Id:row.id,IsLocked:row.isLocked?0:1}).then(res=>{
          if(res.success){
            this.$message({
              message: "冻结/解冻成功",
              type: "success",
            });
            this.init();
          }else{
            this.$message({
              type: 'info',
              message: '冻结/解冻失败，请重试'
            });
          }
        })
      })
    },
    // 删除菜单
    async handleDel(index,row){
      await this.$confirm('确认删除该角色吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteRole({Id:row.id}).then(res=>{
            if(res.success){
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.init();
            }else{
              this.$message({
                type: 'info',
                message: '删除失败，请重试'
              });
            }
          })
        }).catch(() => {});
    },
    // 分页
    prevpage(val){
      this.pageIndex=val;
      this,init();
    },
    nextpage(val){
      this.pageIndex=val;
      this,init();
    },
    handleCurrentChange(val){
      this.pageIndex =val;
      this.init();
    },
  },
};
</script>
<style scoped>
  .el-col-24{
    height: 52px;
    margin-top: 20px;
  }
  .role{
    width: 100%;
    height: 60px;
    padding: 10px 50px;
    position: relative;
    border-bottom: 2px solid rgba(240, 242, 245, 1);
    background: #fff;
    padding: 20px;
  }
  .container{
    padding: 20px;
  }
  .role button{
    position: absolute;
    right:20px;
    top:10px;
  }</style>
