<template>
  <section >
    <div class="search">
      <div class="inputs">
          <p class="each">
              <span>用户名称：</span>
              <el-input class="wxName" v-model="searchInfo.data.UserName" placeholder="请输入用户名称"></el-input>
          </p>
          <p class="each">
              <span>所属角色：</span>
              <el-input class="wxName" v-model="searchInfo.data.RoleName" placeholder="请输入角色名称"></el-input>
          </p>
         <el-button type="primary" @click="toSearch">搜索</el-button>
         <el-button type="primary" @click="backAll">清空搜索条件</el-button>
         <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>
    </div>
    <div class="container">
      <el-table
        :data="userlist"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="用户名"
          prop="userName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="登录名"
          prop="loginName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="角色"
          prop="roleName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="是否冻结"
          prop="isLocked"
          align='center'>
          <template slot-scope="scope">
              <p>{{ scope.row.isLocked == 1 ? '是' : '否' }}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="创建人"
          prop="creatorName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="修改人"
          prop="modifierName"
          align='center'>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="createTime"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
        <el-table-column
          label="修改时间"
          prop="modifiyTime"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
        <el-table-column
         label="操作"
         fixed="right"
         width="250"
         align='center'>
          <template slot-scope="scope">
              <el-button size="mini" @click="lockUser(scope.$index, scope.row)">{{scope.row.isLocked==1?'解冻':'冻结'}}</el-button>
              <el-button size="mini" @click="delUser(scope.$index, scope.row)">删除</el-button>
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page="searchInfo.pageIndex"
          :page-size="10"
          :total="total"
          background
          style="float:right;"
        ></el-pagination>
      </el-col>
      <!-- 新增菜单 -->
      <el-drawer
       title="新增用户"
       :visible.sync="addDrawer"
       :direction="direction"
       :before-close="handleClose">
        <add v-if="addDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></add>
      </el-drawer>
        <!-- 修改菜单 -->
      <el-drawer
       title="修改"
       :visible.sync="editDrawer"
       :direction="direction"
       :before-close="handleClose">
        <edit v-if="editDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></edit>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {getUserInfo,lockUser,deleteUser} from '@/api/operateCenter.js';
import add from './add.vue';
import edit from './edit.vue';
export default {
  data() {
    return {
      // 订单列表信息
      userlist:[],
      addDrawer:false,
      editDrawer:false,
      id:'',
      row:'', // 修改带过去的参数
      pageIndex:1,
      pageSize:10,
      direction:'rtl',
      // 分页样式
      total:0,
      searchInfo:{ // 查询条件
        pageIndex:1,
        pageSize:10,
        data:{
          UserName:'',
          RoleName:'',
        },
      },
      RoleName:'',
      statusOptions:[
        {value:-1,label:'请选择'},
        {value:0,label:'待支付'},
        {value:1,label:'已支付'},
        {value:2,label:'已结束'},
        {value:3,label:'退款中'},
        {value:4,label:'已退款'},
      ],
      ProcessOptions:[
        {value:0,label:'未开始'},
        {value:1,label:'充电中'},
        {value:2,label:'已完成'},
      ]
    }
  },

  components: {add,edit},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 初始化
    async init(){
      await getUserInfo(this.searchInfo).then(res=>{
        if(res.success){
          this.userlist=res.data.data;
          this.userlist.forEach(element => {
            for(let key in element){
              element[key]=element[key]?element[key]:'--'
            }
          });
          this.total=res.data.totalCount;
        }
      })
    },
    // 冻结/解冻用户
    lockUser(index,row){
        this.$confirm('确认冻结/解冻该用户吗')
         .then(async ()=>{
            await lockUser({Id:row.id,isLocked:row.isLocked==1?0:1}).then(res=>{
              if(res.success){
                this.$message({
                  showClose: true,
                  message: '操作成功',
                  type: 'success'
                });
                this.init();
              }
            })
         })
         .catch(()=>{})
    },
    // 删除用户
    delUser(index,row){
      this.$confirm('确认删除该用户吗')
       .then(async ()=>{
          await deleteUser({Id:row.id}).then(res=>{
            if(res.success){
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              });
              this.init();
            }
          })
       })
       .catch(()=>{})
    },
     // 打开新增
    handleAdd(index,row){
      this.addDrawer=true;
    },
    // 打开修改
    handleEdit(index,row){
      this.editDrawer=true;
      this.id=index;
      this.row=row;
      console.log(row,98)
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 新增、绑定、编辑成功
    handleDrawerSuccess(){
      this.addDrawer=false;
      this.editDrawer=false;
    },
    // 关闭
    handleClose(){
      this.addDrawer=false;
      this.editDrawer=false;
      this.init();
    },
    // 条件查询
    toSearch(){
      this.searchInfo.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex=1;
      this.searchInfo.data={
        UserName:"",
        RoleName:"",
      };
      this.init();
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    prevpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
    nextpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>

 .search{
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  background: #fff;
  padding: 20px;
    .inputs{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .each{
          width:calc((100% - 40px ) / 4) ;
          height: 50px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          .el-select{
           width: 100% !important;
          }
          span{
              width: 100px;
          }
      }
      .el-button{
          margin-left: 15px;
      }
    }
  }

  .container{
      padding: 20px;
  }
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }

.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}
.el-col-24{
    height: 52px;
   margin-top: 20px;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
