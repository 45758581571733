<template>
  <div>
    <div class="search">
        <div class="inputs">
            <p class="each">
                <span>抬头：</span>
                <el-input class="wxName" v-model="searchInfo.Data.LookUp" placeholder="请输入抬头"></el-input>
            </p>
            <p class="each">
                <span>税号：</span>
                <el-input class="wxName" v-model="searchInfo.Data.DutyParagraph" placeholder="请输入税号"></el-input>
            </p>
            <p class="each">
              <span>是否启用：</span>
              <el-select class='select' v-model="IsEnable" clearable placeholder="请选择">
                <el-option
                  v-for="item in enableOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </p>
           <el-button type="primary" @click="toSearch">搜索</el-button>
           <el-button type="primary" @click="backAll">清空搜索条件</el-button>
           <el-button @click="hanleAdd">新增</el-button>
        </div>
    </div>
    <div class="container">
      <el-table
       :data="tableList"
       style="width: 100%" 
       class="table"
       border
       stripe
       :header-cell-style="{background:'#fafafa'}"
       fit>
        <el-table-column
          label="抬头"
          prop="lookUp"
          align='center'>
        </el-table-column>
        <el-table-column
          label="税号"
          prop="dutyParagraph"
          align='center'>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="createTime"
          align='center'>
        </el-table-column>
        <el-table-column
          label="是否启用"
          prop="isEnable"
          align='center'>
          <template slot-scope="scope">
              {{ scope.row.isEnable == 1 ? '已启用' : '未启用' }}
          </template>
        </el-table-column>
        <el-table-column
          label="启用时间"
          prop="enableTime"
          align='center'>
        </el-table-column>
        <el-table-column
         label="操作"
         fixed="right"
         align='center'
         width="100">
          <template slot-scope="scope">
              <el-button size="mini" @click="enable(scope.row)" v-if="scope.row.isEnable == 0">启用</el-button>
              <p v-else>--</p>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page="searchInfo.pageIndex"
          :page-size="10"
          :total="total"
          background
          style="float:right;"
        ></el-pagination>
      </el-col>
      <!-- 新增 -->
      <el-drawer
       title="新增"
       :visible.sync="addDrawer"
       :direction="direction"
       :before-close="handleClose">
        <add v-if="addDrawer" @close='handleClose' ref='newForm' :row="row"></add>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {getInvoicingInfo,editInvoicingInfo} from '@/api/operateCenter.js';
import add from './add.vue'
export default {
  data() {
    return {
        tableList:[],
        searchInfo:{
            pageIndex:1,
            pageSize:10,
            Data:{
                LookUp:"",
                DutyParagraph:"",
                IsEnable:-1,
            }
        },
        total:0,
        IsEnable:"",
        enableOptions:[
            {value:0,name:"未启用"},
            {value:1,name:"已启用"},
        ],
        addDrawer:false,
        row:null,
    }
  },

  components: {add},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    init(){
        getInvoicingInfo(this.searchInfo).then(res=>{
            if(res.success){
                this.tableList = res.data.data;
                this.total = res.data.totalCount;
            }else{
                this.tableList = [];
                this.total = 0;
            }
        })
    },
    toSearch(){
        console.log(this.IsEnable,998)
        this.searchInfo.pageIndex = 1;
        this.searchInfo.Data.IsEnable = this.IsEnable === '' ? -1 : this.IsEnable;
        this.init();
    },
    backAll(){
        this.searchInfo = {
            pageIndex:1,
            pageSize:10,
            Data:{
                LookUp:"",
                DutyParagraph:"",
                IsEnable:-1,
            }
        }
        this.IsEnable = "";
        this.init();
    },
    hanleAdd(){
        this.addDrawer = true;
    },
    handleClose(){
        this.addDrawer = false;
        this.init();
    },
    enable(row){
        this.$confirm('启用后其他开票信息将更改为未启用状态，确认启用该开票信息吗？').then( async () => {
            await editInvoicingInfo({Id:row.id}).then(res=>{
                if(res.success){
                    this.$message({
                        message:'启用成功',
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:'启用失败，请重试',
                        type:'fail'
                    })
                }
                this.init();
            })
        })
    },
  },
};
</script>
<style scoped lang="scss">
 .search{
    border-bottom: 2px solid rgba(240, 242, 245, 1);
    background: #fff;
    padding: 20px;
      .inputs{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .each{
            width:calc((100% - 40px ) / 4) ;
            height: 50px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            .el-select{
             width: 100%;
            }
            span{
                width: 100px;
            }
        }
        .el-button{
            margin-left: 15px;
        }
    }
}
.container{
    padding: 20px;
}
</style>
