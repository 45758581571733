<template>
  <section >
    <div class="equipInfo" v-if="equipType">
        <i class="el-icon-back" @click="back"></i>
        <p>设备编号：{{searchInfo.data.equipmentNum}}</p>
    </div>
    <div class="search">
      <div class="inputs">
          <p class="each">
              <span>设备编号</span>
              <el-input class="wxName" v-model="searchInfo.data.equipmentNum" placeholder="请输入设备编号"></el-input>
          </p>
          <p class="each">
              <span>是否可用：</span>
              <el-select class='select' v-model="isDamage" clearable filterable placeholder="请选择">
                <el-option
                  v-for="item in isDamageOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
          </p>
         <el-button type="primary" @click="toSearch">搜索</el-button>
         <el-button type="primary" @click="backAll">清空搜索条件</el-button>
      </div>
    </div>
    <div class="container">
      <el-table
        :data="goodsList"
        style="width: 100%"
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="ID"
          prop="id"
          align='center'
          width="80">
        </el-table-column>
        <el-table-column
          label="编号"
          prop="goodsNum"
          align='center'>
        </el-table-column>
        <el-table-column
          label="电量(%)"
          prop="power"
          align='center'>
        </el-table-column>
        <el-table-column
          label="是否可用"
          prop="isDamage"
          align='center'>
          <template slot-scope="scope">
            {{scope.row.isDamage === 0 ? '可用' : "故障"}}
          </template>
        </el-table-column>
        <el-table-column
          label="所属设备"
          prop="equipmentNum"
          align='center'>
          <template slot-scope="scope">
            {{scope.row.equipmentNum && scope.row.equipmentNum != '--' ? scope.row.equipmentNum : "租出"}}
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :page-size="10"
          :total="total"
          :current-page="currentPage"
          background
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
import { getGoodsInfo } from '@/api/operateCenter.js';
export default {
  data() {
    return {
      // 充电宝列表信息
      goodsList:[],
      // 分页样式
      total:0,
      currentPage:1,
      isDamageOptions:[ // 是否可用
        {value:0,name:'可用'},
        {value:1,name:'故障'},
      ],
      isDamage:'',
      searchInfo:{ // 查询条件
        pageIndex:1,
        pageSize:10,
        data:{
          isDamage:-1, // 是否可用
          equipmentNum:'', // 设备编号
        }
      },
      equipType:false,
    }
  },

  components: {},

  computed: {},

  mounted() {
    if(this.$route.query.type){
      this.searchInfo.data.equipmentNum=this.$route.query.equipmentNum;
      this.equipType = true;
    }else{
      this.searchInfo.data.equipmentNum='';
      this.equipType = false;
    }
    this.init();
  },

  methods: {
    selectStatus(e){},
    // 初始化
    async init(){
      await getGoodsInfo(this.searchInfo).then(res=>{
        if(res.success){
          this.goodsList=res.data.data;
          this.goodsList.forEach(element => {
            for(let key in element){
              element[key]=element[key] || element[key]==0 ? element[key] : '--';
            }
          });
          this.total=res.data.totalCount;
        }
      })
    },
    back(){
        this.$router.push('/equipManage/equipList');
        this.equipType = false;
    },
    // 条件查询
    toSearch(){
      this.searchInfo.data.isDamage=this.isDamage !==''  ? this.isDamage : this.searchInfo.data.isDamage; 
      this.searchInfo.pageIndex=1;
      this.currentPage = 1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex=1;
      this.currentPage = 1;
      this.searchInfo.data={
        equipmentNum:'',
        isDamage:-1,
      }
      this.isDamage = "";
      this.init();
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.currentPage = val;
      this.init();
    },
    prevpage(val){
      this.searchInfo.pageIndex=val;
      this.currentPage = val;
      this.init();
    },
    nextpage(val){
      this.searchInfo.pageIndex=val;
      this.currentPage = val;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
.equipInfo{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 2px solid rgba(240, 242, 245, 1);
      .el-icon-back{
        font-size: 25px;
        cursor: pointer;
      }
  }
.botBtn{
  margin-top: 10px;
}
  img{
    height: 100px;
    width: 120px;
  }
 .search{
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  background: #fff;
  padding: 20px;
    .inputs{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .each{
          width:calc((100% - 40px ) / 3) ;
          height: 50px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          .el-select{
           width: 100% !important;
          }
          span{
              width: 130px;
          }
      }
      .el-button{
          margin-left: 15px;
      }
    }
  }

  .container{
      padding: 20px;
  }
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }

.el-table >>> .cell {
  white-space: pre-line;
}
 /deep/.el-drawer.rtl{
  overflow-y: auto !important;
}
.el-col-24{
    height: 52px;
   margin-top: 20px;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
