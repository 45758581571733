<template>
    <section >
      <div class="search">
        <div class="inputs">
            <p class="each">
                <span>订单编号：</span>
                <el-input class="wxName" v-model="searchInfo.data.OrderNum" placeholder="请输入订单编号"></el-input>
            </p>
            <p class="each">
                <span>所属用户：</span>
                <el-input class="wxName" v-model="searchInfo.data.ProfitName" placeholder="请输入所属用户"></el-input>
            </p>
           <el-button type="primary" @click="toSearch">搜索</el-button>
           <el-button type="primary" @click="backAll">清空搜索条件</el-button>
        </div>
      </div>
      <div class="container">
        <el-table
          :data="tableList"
          style="width: 100%" 
          class="table"
          border
          stripe
          :header-cell-style="{background:'#fafafa'}"
          fit
        >
        <!-- 数据展示区 -->
          <el-table-column
            label="订单编号"
            prop="orderNum"
            :show-overflow-tooltip='showoverflow'
            align='center'>
          </el-table-column>
          <el-table-column
            label="订单金额（元）"
            prop="orderAmount"
            :show-overflow-tooltip='showoverflow'
            align='center'>
          </el-table-column>
          <el-table-column
            label="所属用户"
            prop="profitName"
            :show-overflow-tooltip='showoverflow'
            align='center'>
          </el-table-column>
          <el-table-column
            label="分润金额（元）"
            prop="profitAmount"
            :show-overflow-tooltip='showoverflow'
            align='center'>
          </el-table-column>
          <el-table-column
            label="分润比例（%）"
            prop="profitProportion"
            width='100px'
            align='center'>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="createTime"
            :show-overflow-tooltip='showoverflow'
            align='center'>
          </el-table-column>
        </el-table>
        <!--分页-->
        <el-col :span="24" class="toolbar" >
          <el-pagination
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            :current-page="searchInfo.pageIndex"
            :page-size="10"
            :total="total"
            background
            style="float:right;"
          ></el-pagination>
        </el-col>
      </div>
    </section>
  </template>
  
  <script>
  import {getProfitDataInfo} from '@/api/operateCenter.js';
  export default {
    data() {
      return {
        tableList:[], // 数据列表
        total:0, // 数据总条数
        searchInfo:{ // 查询条件
          pageIndex:1,
          pageSize:10,
          data:{
            orderNum:"",
          },
        },
      }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.init();
    },
  
    methods: {
      // 初始化
      async init(){
        await getProfitDataInfo(this.searchInfo).then(res=>{
          if(res.success){
            this.tableList=res.data.data;
            this.total=res.data.totalCount;
          }else{
            this.tableList = [];
            this.total = 0;
          }
        })
      },
      // 条件查询
      toSearch(){
        this.searchInfo.pageIndex=1;
        this.init();
      },
      // 清除查询条件
      backAll(){
        this.searchInfo.pageIndex=1;
        this.searchInfo.data={},
        this.init();
      },
      // 分页
      handleCurrentChange(val){
        this.searchInfo.pageIndex=val;
        this.init();
      },
    },
  };
  </script>
  <style scoped lang='scss'>
  .equipInfo{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 2px solid rgba(240, 242, 245, 1);
        .el-icon-back{
          font-size: 25px;
          cursor: pointer;
        }
    }
   .search{
    border-bottom: 2px solid rgba(240, 242, 245, 1);
    background: #fff;
    padding: 20px;
      .inputs{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .each{
            width:calc((100% - 40px ) / 4) ;
            height: 50px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            .el-select{
             width: 100%;
            }
            span{
                width: 100px;
            }
        }
        .el-button{
            margin-left: 15px;
        }
      }
    }
  
    .container{
        padding: 20px;
    }
  // 按钮
    .btns{
      width: 100%;
      background-color: white;
      height: 60px;
      width: 100%;
      padding: 10px 50px;
      position: relative;
      button{
        position: absolute;
        right:20px;
        top:10px;
      }
    }
  
  .el-table >>> .cell {
    white-space: pre-line;
  }
  .el-drawer.rtl{
    overflow-y: auto !important;
  }
  .el-col-24{
      height: 52px;
     margin-top: 20px;
  }
  
  .equipName{
    margin-bottom: 10px;
  }
  .equipNumber{
    color: #40a9ff;
  }
  .number{
    display: inline-block;
    height: 20px;
    width: 20px;
    padding: 0 2px;
    border: solid 1px #91d5ff;
    color:#40a9ff;
    background: #e6f7ff;
    border-radius: 3px;
    margin-left: 10px;
    text-align: center;
    font-size: 14px;
  }
  .el-tabs__content{
    overflow:auto !important;
  }
  .orderStatus{
    background:#40a9ff;
    color:#fff;
    display: inline-block;
    padding: 3px 5px;
    border-radius: 5px;
  }
  
  .stopWay{
    color: #40a9ff;
    margin-top: 10px;
  }
  </style>
  