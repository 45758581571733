<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="用户名" prop="userName" >
        <el-input placeholder="请输入用户名" v-model="form.userName">
        </el-input>
      </el-form-item>
      <el-form-item label="登录名" prop="loginName" >
        <el-input placeholder="请输入登录名" v-model="form.loginName">
        </el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password" >
        <el-input show-password placeholder="请输入密码" v-model="form.password">
        </el-input>
      </el-form-item>
      <el-form-item label="角色" prop="roleId" >
        <el-select v-model="form.roleId" placeholder="请选择" @change='choose'>
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.roleName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属代理" prop="pId" v-if="form.roleId == 4">
        <el-select v-model="form.pId" placeholder="请选择" @change='choose'>
          <el-option
            v-for="item in agentOptions"
            :key="item.id"
            :label="item.userName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否冻结" prop="isLocked" >
         <el-radio-group v-model="form.isLocked" @change='select_name'>
           <el-radio-button label="否" @change='select_name'></el-radio-button>
           <el-radio-button label="是" @change='select_name'></el-radio-button>
         </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="add('form')" :plain='true'>立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {addUser,getRolesList} from '@/api/public.js';
import {getQueryRolesList,getUserList} from '@/api/operateCenter.js';
export default {
  data() {
    return {
      form:{},
      roleId:'',
      imageUrl:'',
      value:[],
      options:[],
      agentOptions:[],
      show:true,
      rules: {
        userName: [
          { required: true, message: "用户名不得为空", trigger: "blur" },
        ],
        loginName: [
          { required: true, message: "登录名不得为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不得为空", trigger: "blur" },
        ],
        roleId: [
          { required: true, message: "请选择角色", trigger: "change" },
        ],
        isLocked: [
          { required: true, message: "请选择是否冻结", trigger: "change" },
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    _this=this;
    _this.init();
  },

  methods: {
    init(){
      getQueryRolesList().then(res=>{
        if(res.success){
          this.options=res.data;
        }
      })
      getUserList({roleId:3}).then(res=>{
        if(res.success){
          this.agentOptions = res.data;
        }else{
          this.agentOptions = [];
        }
      })
    },
    close(){
       _this.$emit('close');
    },
    choose(e){
      console.log(this.form.roleId,888)
    },
     //  添加
    add(form) {
      if(_this.form.isLocked==='否'){
        _this.form.isLocked=0;
      }else if(_this.form.isLocked==='是'){
        _this.form.isLocked=1;
      }
      this.options.forEach(value=>{
        if(this.roleId===value.roleName){
          this.form.roleId=value.id;
        }
      })
       _this.$refs["form"].validate(valid => {
          if (valid) {
            addUser(_this.form)
              .then((res) => {
                if (res.success) {
                  _this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    this.form={};
                    _this.$emit("success");
                    _this.$emit('close');
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: "添加失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
