<template>
  <section >
    <div class="search">
      <div class="inputs">
          <p class="each">
              <span>订单编号：</span>
              <el-input class="wxName" v-model="searchInfo.data.OrderNum" placeholder="请输入订单编号"></el-input>
          </p>
          <p class="each">
              <span>退款编号：</span>
              <el-input class="wxName" v-model="searchInfo.data.RefundNum" placeholder="请输入退款编号"></el-input>
          </p>
          <p class="each">
              <span>退款状态：</span>
              <el-select class='select' v-model="RefundState" clearable placeholder="请选择" @change="selectStatus">
                <el-option
                  v-for="item in RefundStateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </p>
         <el-button type="primary" @click="toSearch">搜索</el-button>
         <el-button type="primary" @click="backAll">清空搜索条件</el-button>
      </div>
    </div>
    <div class="container">
      <el-table
        :data="refundList"
        style="width: 100%" 
        class="table"
        border
        stripe
        :header-cell-style="{background:'#fafafa'}"
        fit
      >
      <!-- 数据展示区 -->
        <el-table-column
          label="退款编号"
          prop="refundNum"
          align='center'>
        </el-table-column>
        <el-table-column
          label="订单编号"
          prop="orderNum"
          align='center'>
        </el-table-column>
        <el-table-column
          label="购买用户"
          prop="orderNum"
          align='center'>
          <template slot-scope="scope">
            <p v-if="scope.row.payPlatform == 1">{{scope.row.aliUserName}}</p>
            <p v-if="scope.row.payPlatform == 1">{{scope.row.aliPhone}}</p>
            <p v-if="scope.row.payPlatform == 2">{{scope.row.weChatName}}</p>
            <p v-if="scope.row.payPlatform == 2">{{scope.row.wxPhone}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="订单金额(元)"
          prop="orderAmount"
          width='100px'
          align='center'>
        </el-table-column>
        <el-table-column
          label="退款金额(元)"
          prop="refundAmount"
          width='100px'
          align='center'>
        </el-table-column>
        <el-table-column
          label="退款原因"
          prop="rmk"
          show-overflow-tooltip
          align='center'>
        </el-table-column>
         <el-table-column
          label="退款状态"
          prop="refundState"
          align='center'>
          <template slot-scope="scope">
            <p>{{scope.row.refundState == 0 ? '新建' : (scope.row.refundState == 1 ? '发起退款': (scope.row.refundState == 2 ? '待审核' : (scope.row.refundState == 3 ? '退款成功' : '退款失败')))}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="申请时间"
          prop="createTime"
          align='center'>
        </el-table-column>
        <el-table-column
          label="退款时间"
          prop="refundTime"
          align='center'>
          <template slot-scope="scope">{{scope.row.refundState == 3 ? scope.row.refundTime : '--'}}</template>
        </el-table-column>
        <el-table-column
         label="操作"
         fixed="right"
         align='center'
         width="160">
          <template slot-scope="scope">
              <el-button size="mini" @click="confirm(scope.$index, scope.row)" v-if="scope.row.refundState != 3">确认</el-button>
              <el-button size="mini" @click="reject(scope.$index, scope.row)" v-if="scope.row.refundState != 3">驳回</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-col :span="24" class="toolbar" >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page="searchInfo.pageIndex"
          :page-size="10"
          :total="total"
          background
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
import {getOrderRefundInfo,ReviewRefund,RejectRefund} from '@/api/operateCenter.js';
export default {
  data() {
    return {
      // 订单列表信息
      refundList:[],
      // 分页样式
      total:0,
      searchInfo:{
        pageIndex:1,
        pageSize:10,
        data:{
          RefundState:-1,
        },
      },// 查询条件
      RefundState:'',
      RefundStateOptions:[
        {value:0,label:'新建'},
        {value:1,label:'发起退款'},
        {value:2,label:'待审核'},
        {value:3,label:'退款成功'},
        {value:4,label:'退款失败'},
      ],
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    selectStatus(e){},
    // 初始化
    async init(){
      await getOrderRefundInfo(this.searchInfo).then(res=>{
        if(res.success){
          this.refundList=res.data.data;
          this.total=res.data.totalCount;
          this.refundList.forEach(element => {
            for(let key in element){
              element[key]=element[key] || element[key]==0?element[key]:'--';
            }
          });
        }
      })
    },
    // 条件查询
    toSearch(){
      this.searchInfo.data.RefundState=this.RefundState !==''  ? this.RefundState : -1; 
      this.searchInfo.pageIndex=1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageIndex=1;
      this.searchInfo.data={
        RefundState:-1,
      },
      this.RefundState='';
      this.OrderState='';
      this.init();
    },
    confirm(index,row){
      this.$confirm('确认通过该退款申请吗？').then(async ()=>{
        await ReviewRefund({Data:row.refundNum}).then(res=>{
          if(res.success){
            this.$message({
              message:'退款成功',
              type:'success'
            })
          }else{
            this.$message({
              message:'退款失败，请重试',
              type:'fail',
            })
          }
        })
        this.init();
      })
    },
    reject(index,row){
      this.$confirm('确认驳回该退款申请吗？').then(async ()=>{
        await RejectRefund({Data:row.refundNum}).then(res=>{
          if(res.success){
            this.$message({
              message:'已驳回',
              type:'success'
            })
          }else{
            this.$message({
              message:'驳回失败，请重试',
              type:'fail',
            })
          }
        })
        this.init();
      })
    },
    // 分页
    handleCurrentChange(val){
      this.searchInfo.pageIndex=val;
      this.init();
    },
    prevpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
    nextpage(e){
      this.searchInfo.pageIndex=e;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>

 .search{
  border-bottom: 2px solid rgba(240, 242, 245, 1);
  background: #fff;
  padding: 20px;
    .inputs{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .each{
          width:calc((100% - 40px ) / 4) ;
          height: 50px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          .el-select{
           width: 100% !important;
          }
          span{
              width: 100px;
          }
      }
      .el-button{
          margin-left: 15px;
      }
    }
  }

  .container{
      padding: 20px;
  }
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }

.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}
.el-col-24{
    height: 52px;
   margin-top: 20px;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
