<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="180px" style="width:95%;overflow:auto">
        <el-form-item label="模板名称" prop="templateName" >
          <el-input placeholder="请输入模板名称" v-model="form.templateName">
          </el-input>
        </el-form-item>
        <el-form-item label="免费时间（分钟）" prop="freeTime" >
          <el-input placeholder="请输入免费时间" v-model="form.freeTime">
          </el-input>
        </el-form-item>
        <el-form-item label="初始计费时间（分钟）" prop="frontTime">
          <el-input placeholder="请输入初始计费时间" v-model="form.frontTime">
          </el-input>
        </el-form-item>
        <el-form-item label="初始计费金额（元）" prop="frontAmount" >
          <el-input placeholder="请输入初始计费金额" v-model="form.frontAmount">
          </el-input>
        </el-form-item>
        <el-form-item label="初始时间最低金额（元）" prop="frontMinAmount" v-if="row || userInfo.roleId == 1">
          <el-input placeholder="请输入初始时间最低金额" v-model="form.frontMinAmount"  :disabled="row && userInfo.roleId != 1 ? true : false">
          </el-input>
        </el-form-item>
        <el-form-item label="单位计费时间（分钟）" prop="everyTime" >
          <el-input placeholder="请输入单位计费时间" v-model="form.everyTime">
          </el-input>
        </el-form-item>
        <el-form-item label="单位计费金额（元）" prop="everyAmount" >
          <el-input placeholder="请输入单位计费金额" v-model="form.everyAmount">
          </el-input>
        </el-form-item>
        <el-form-item label="单位时间最低金额（元）" prop="everyMinAmount"  v-if="row || userInfo.roleId == 1">
          <el-input placeholder="请输入单位时间最低金额" v-model="form.everyMinAmount"  :disabled="row && userInfo.roleId != 1 ? true : false">
          </el-input>
        </el-form-item>
        <el-form-item label="单日封顶金额（元）" prop="dayMaxAmount" >
          <el-input placeholder="请输入单日封顶金额" v-model="form.dayMaxAmount">
          </el-input>
        </el-form-item>
        <el-form-item label="最大封顶金额（元）" prop="maxAmount" >
          <el-input placeholder="请输入最大封顶金额" v-model="form.maxAmount">
          </el-input>
        </el-form-item>
        <el-form-item label="系统抽单" prop="samplingRatio" v-if="userInfo.roleId == 1">
          <el-input placeholder="请输入系统抽单" v-model="form.samplingRatio">
          </el-input>
        </el-form-item>
        <el-form-item label="所属代理" prop="agentId" v-if="userInfo.roleId == 1">
          <el-select v-model="form.agentId" placeholder="请选择">
            <el-option
              v-for="item in agentOptions"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {addChargingTemplateInfo,getUserList,editChargingTemplateInfo} from '@/api/operateCenter.js';
  import {checkMaxAmount} from '@/api/publicFun.js';
  export default {
    props:['row'],
    data() {
      return {
        form:{
            templateName:'',
            freeTime:'',
            frontTime:'',
            frontAmount:'',
            everyTime:'',
            everyAmount:'',
            samplingRatio:'',
        },
        agentOptions:[],
        rules: {
          templateName: [
            { required: true, message: "模板名称不得为空", trigger: "blur" },
          ],
          freeTime: [
            { required: true, message: "免费时间不得为空", trigger: "blur" },
          ],
          frontTime:[
            { required: true, message: "初始计费时间不得为空", trigger: "change" },
          ],
          frontAmount: [
            { required: true, message: "初始计费金额单价不得为空", trigger: "blur" },
          ],
          everyTime:[
            { required: true, message: "单位计费时间不得为空", trigger: "blur" },
          ],
          everyAmount:[
            { required: true, message: "单位计费金额单价不得为空", trigger: "blur" },
          ],
          dayMaxAmount:[
            { required: true, message: "单日封顶金额不得为空", trigger: "blur" },
            { validator: checkMaxAmount, trigger: 'blur' }
          ],
          maxAmount:[
            { required: true, message: "最大封顶金额不得为空", trigger: "blur" },
            { validator: checkMaxAmount, trigger: 'blur' }

          ],
        },
        userInfo:null,
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      _this=this;
      this.init();
      this.form = this.row ? this.row : this.form;
      this.userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : null;
    },
  
    methods: {
      close(){
         _this.$emit('close');
      },
      // 获取代理列表
      async init(){
        await getUserList({roleId:3}).then(res=>{
          if(res.success){
            this.agentOptions=res.data;
          }
        })
      },
      // 新增
      async add(){
        if(this.userInfo.roleId == 1){
          this.form.agentId = this.userInfo.id;
        }
        await addChargingTemplateInfo(_this.form).then((res) => {
          if (res.success) {
            _this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.form={};
              _this.$emit("success");
              _this.$emit('close');
              _this.init();
            }, 1000);
          }else{
            _this.$message({
              message: "提交失败，请重试",
              type: "fail",
            });
          }
        })
      },
      // 修改
      async edit(){
        await editChargingTemplateInfo(this.form).then(res=>{
          if (res.success) {
            _this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.form={};
              _this.$emit("success");
              _this.$emit('close');
              _this.init();
            }, 1000);
          }else{
            _this.$message({
              message: "提交失败，请重试",
              type: "fail",
            });
          }
        })
      },
       //  添加
       submit(form) {
        this.form.samplingRatio = Number(this.form.samplingRatio)
         _this.$refs["form"].validate(valid => {
            if (valid) {
              this.$confirm('确认提交吗？').then(res=>{
                if(this.row){
                  this.edit();
                }else{
                  this.add();
                }
              })
            } else {
              return false;
            }
        });
      },
      
    }
  };
  </script>
  <style lang="scss" scoped>
  
   .el-drawer{
     overflow:auto !important;
   }
   .el-select{
     width: 100%;
   }
  //  el-select-dropdown__wrap el-scrollbar__wrap
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  