<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="设备供应商" prop="deviceSupplierId">
          <el-select v-model="form.deviceSupplierId" placeholder="请选择">
            <el-option
              v-for="item in deviceSupplierOptions"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="submit('form')" :plain='true'>立即提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  let _this;
  import {getUserList,UpdateDeviceSupplierId} from '@/api/operateCenter.js';
  export default {
    props:['row'],
    data() {
      return {
        form:{
          deviceSupplierId:'',
          id:"",
        },
        deviceSupplierOptions:[],
        rules: {
          deviceSupplierId: [
            { required: true, message: "设备供应商不得为空", trigger: "blur" },
          ],
        },
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
        _this = this;
        this.getDeviceSupplier();
        this.form = this.row.deviceSupplierId ? this.row : this.form;
    },
  
    methods: {
      // 获取供应商列表
      async getDeviceSupplier(){
        await getUserList({roleId:2}).then(res=>{
          if(res.success){
            this.deviceSupplierOptions=res.data;
          }
        })
      },
      close(){
        console.log('q234')
        this.$emit('close');
      },
       //  提交
      submit(form) {
         this.$refs["form"].validate( valid => {
            if (valid) {
              this.$confirm('确认提交吗？').then(async () => {
                let data = {
                    id:this.row.id,
                    deviceSupplierId:this.form.deviceSupplierId,
                }
                await UpdateDeviceSupplierId(data).then(res=>{
                    if(res.success){
                        this.$message({
                            message:'提交成功',
                            type:'success'
                        })
                        setTimeout(()=>{
                          this.$emit('close');
                        },1000)
                    }else{
                        this.$message({
                            message:'提交失败，请重试',
                            type:'fail'
                        })
                    }
                })
              })
            } else {
              return false;
            }
        });
      },
      
    }
  };
  </script>
  <style lang="scss" scoped>
  .map{
      border: 1px solid #dcdfe6;
      background: #fff;
      border-radius: 4px;
      width: 80px;
     }
     /deep/ .el-form-item__content{
      display: flex;
     }
   .el-drawer{
     overflow:auto !important;
   }
   .el-select{
     width: 100%;
   }
  //  el-select-dropdown__wrap el-scrollbar__wrap
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    img{
      width: 178px;
      height: 178px;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  